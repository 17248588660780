import React, { useEffect, useState } from 'react';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { formatDate, ucFirst } from '../../utils/helper';
import Pagination from 'rc-pagination';
import localInfo from 'rc-pagination/es/locale/en_US';

const Referral = ({ loading, affiliateInfo, getReferrals, referrals }) => {
    const perPage = 20;
    const [page, setPage] = useState(1);

    useEffect(() => {
        getReferrals({
            affiliate_id: affiliateInfo.id,
            number: perPage,
            offset: 0,
        });
    }, [affiliateInfo]);

    const handlePageChange = function (page) {
        setPage(page);
        getReferrals({
            affiliate_id: affiliateInfo.id,
            number: perPage,
            offset: (page - 1) * perPage,
        });
    };

    return (
        <div className="WpdTabContent">
            <section className="WpdMyStatisticsSection">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">Referrals</h4>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {referrals &&
                        referrals.data &&
                        referrals.data.length ? (
                            <>
                                <div className="WpdDataTableWrapper">
                                    <table className="WpdDataTable">
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th>Amount</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {referrals.data.map(
                                                (referral, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <label>
                                                                Reference:{' '}
                                                            </label>
                                                            <p>
                                                                {
                                                                    referral.reference
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <label>
                                                                Amount:{' '}
                                                            </label>
                                                            <p>
                                                                {
                                                                    referral.amount
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <label>
                                                                Description:{' '}
                                                            </label>
                                                            <p>
                                                                {
                                                                    referral.description
                                                                }
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <label>
                                                                Status:{' '}
                                                            </label>
                                                            <p>
                                                                <span
                                                                    className={`WpdLabelButton ${
                                                                        referral.status.toString() ===
                                                                        'paid'
                                                                            ? 'WpdSuccessAlert'
                                                                            : 'WpdDangerAlert'
                                                                    }`}
                                                                >
                                                                    <span className="WpdButtonInner">
                                                                        <span className="WpdText">
                                                                            {ucFirst(
                                                                                referral.status
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <label>
                                                                Date:{' '}
                                                            </label>
                                                            <p>
                                                                {formatDate(
                                                                    referral.date,
                                                                    false,
                                                                    true
                                                                )}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="WpdPaginationWrapper">
                                    <Pagination
                                        defaultCurrent={1}
                                        pageSize={perPage}
                                        onChange={handlePageChange}
                                        total={referrals.total}
                                        prevIcon={'<'}
                                        nextIcon={'>'}
                                        locale={localInfo}
                                        current={page}
                                    />
                                </div>
                            </>
                        ) : (
                            <EmptyMessage>No referrals found</EmptyMessage>
                        )}
                    </>
                )}
            </section>
        </div>
    );
};

export default Referral;
