import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import {
	configure2fa,
	verify2fa,
	downloadBackupCodes as downloadBackupCodesAction,
	signin,
	getQrCode as getQrCodeAction,
	getBackupCodes as getBackupCodesAction,
} from '../../../actions/SigninActions';
import Configure from './Configure';
import QRCode from './QRCode';
import EmailSubmitCode from './EmailSubmitCode';
import SubmitCode from './SubmitCode';
import BackupCode from './BackupCode';
import ErrorMessage from '../../../utils/ErrorMessage';
import { toast } from 'react-toastify';

/* eslint-disable */
const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const strongPassRegex = new RegExp(
	'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
);
/* eslint-enable */

const use2FAViews = () => {
	let email, password;
	const {
		loading: signInLoader,
		authId,
		setup2fa,
		validationError,
		has2faError,

		required2fa,
		userEmail,
		hiddenEmail,
		setup2faKey,
		backupCodes,
		totpOptions,
		method,
	} = useSelector(state => {
		return {
			loading: state.auth.signInLoader,
			setup2fa: state.auth.setup2fa,
			required2fa: state.auth.required2fa,
			authId: state.auth.authId,

			validationError: state.auth.validationError,
			has2faError: state.auth.has2faError,

			queryParam: state.router.location.query,
			hiddenEmail: state.auth.hiddenEmail,
			userEmail:
				state.auth?.signin?.userInfo?.email ?? state.auth?.userEmail,
			setup2faKey: state.auth.setup2faKey,
			totpOptions: state.auth.totpOptions,
			backupCodes: state.auth?.backupCodes ?? [],
			method: state.auth.method,
		};
	});

	const dispatch = useDispatch();
	const downloadBackupCodes = () => dispatch(downloadBackupCodesAction());
	const configure2Fa = (key, method = 'email') =>
		dispatch(configure2fa(key, method));
	const verify = (user_id, code, method = 'email', mode = '') =>
		dispatch(verify2fa(user_id, code, method, mode));
	const getQrCode = () => dispatch(getQrCodeAction());
	const getBackupCodes = () => dispatch(getBackupCodesAction());
	const userSignIn = value => dispatch(signin(value));

	const [configureMail, setConfigureMail] = useState('');
	const [code, setCode] = useState('');
	const [otpType, setOTPType] = useState(method ?? 'totp');
	const [view, setView] = useState('signin');
	const [error, setError] = useState({
		email: [],
		password: [],
		code: '',
	});

	useEffect(() => {
		if (validationError.length > 0) {
			let emailErr = [];
			let passwordErr = [];
			validationError[0].map(err => {
				if (err.param === 'email') {
					emailErr.push(err.msg);
				}
				if (err.param === 'password') {
					passwordErr.push(err.msg);
				}
			});

			setError({
				...error,
				email: emailErr,
				password: passwordErr,
			});
		} else {
			setError({
				email: [],
				password: [],
			});
		}
	}, [validationError]);

	useEffect(() => {
		setOTPType(method);
	}, [method]);

	useEffect(() => {
		if (required2fa) {
			setView('2faForm');
		}
	}, [required2fa]);

	useEffect(() => {
		let _setup2fa = setup2fa ? setup2fa.toString() : undefined;

		if (totpOptions.key.length == 0 && _setup2fa === '2.1') {
			getQrCode();
		}

		if (backupCodes.length === 0 && _setup2fa === '3.1') {
			getBackupCodes();
		}

		if (_setup2fa && _setup2fa === '1') {
			setView('configure2fa');
		}
		if (_setup2fa && (_setup2fa === '2' || _setup2fa === '2.1')) {
			setView('configureTOTP');
		}
		if (_setup2fa && (_setup2fa === '3' || _setup2fa === '3.1')) {
			setView('downloadTOTPbackupCodes');
		}

		if (required2fa && _setup2fa === '1') {
			setView('2faForm');
		}
	}, [setup2fa, totpOptions, required2fa]);

	useEffect(() => {
		if (code.length > 0) {
			setError({ ...error, code: '' });
		}
	}, [code]);

	const submit2FaForm = useCallback(
		(mode = '') => {
			if (!code.length) {
				setError({ ...error, code: 'Please insert verification code' });
				return;
			} else {
				setError({ ...error, code: '' });
			}
			if (authId) {
				verify(authId, code, otpType ?? 'email', mode);
			}
		},
		[verify, authId, otpType, code, error, setError]
	);

	const validateForm = () => {
		const errors = [];

		let emailErr = [];
		let passwordErr = [];

		if (email.value === '') {
			emailErr.push("Email can't be empty");
			errors.push("Email can't be empty");
		}

		if (email.length > 0 && !validEmailRegex.test(email.value)) {
			errors.push('Email is not valid');
			emailErr.push('Email is not valid');
		}

		if (password.value === '') {
			passwordErr.push("Password can't be empty");
			errors.push("Password can't be empty");
		}

		if (errors.length > 0) {
			setError({
				email: emailErr,
				password: passwordErr,
			});
		}
		return errors;
	};

	const submitSignInForm = e => {
		e.preventDefault();
		var errors = validateForm();
		if (errors.length > 0) {
			return;
		}
		setConfigureMail(email.value);
		userSignIn({
			username: email.value,
			password: password.value,
		});
	};

	useEffect(() => {
		if (userEmail) {
			setConfigureMail(userEmail);
		}
	}, [userEmail]);

	useEffect(() => {
		if (hiddenEmail) {
			setConfigureMail(hiddenEmail);
		}
	}, [hiddenEmail]);

	useEffect(() => {
		if (has2faError) {
			toast.error(has2faError);
			setCode('');
		}
	}, [has2faError]);

	const views = useMemo(() => {
		let formViews = '';
		switch (view) {
			case 'signin':
				formViews = (
					<div className="WpdLoginForm">
						<Form onSubmit={submitSignInForm}>
							<h2>Login</h2>
							<div className="WpdFormGroup mb-4">
								<label className="WpdFormLabel">
									Email Address
								</label>
								<input
									type="email"
									id="email"
									className={`form-control ${
										error.email &&
										error.email.length &&
										'WpdHasError'
									}`}
									placeholder="Example@example.com"
									autoFocus={true}
									ref={node => {
										email = node;
									}}
								/>
								{error &&
									error.email &&
									error.email.map((error, index) => (
										<ErrorMessage error={error} />
									))}
							</div>
							<div className="WpdFormGroup mb-4">
								<label className="WpdFormLabel">Password</label>
								<input
									type="password"
									id="password"
									className={`form-control ${
										error.password &&
										error.password.length &&
										'WpdHasError'
									}`}
									placeholder="********"
									ref={node => {
										password = node;
									}}
								/>
								{error &&
									error.password &&
									error.password.map((error, index) => (
										<ErrorMessage error={error} />
									))}
							</div>
							<button
								disabled={signInLoader}
								type="submit"
								className="WpdButton WpdInfoButton WpdFilled w-100 mt-2"
							>
								<span className="WpdButtonInner">
									<span className="WpdText">
										{signInLoader ? (
											<Spinner
												animation="border"
												variant="light"
												style={{
													height: 26,
													width: 26,
												}}
											/>
										) : (
											'Login'
										)}
									</span>
								</span>
							</button>
							<div className="d-flex mt-4">
								<Link
									to="/auth/forget-pass"
									className="WpdFont500"
								>
									Forgot Password?
								</Link>
							</div>
						</Form>
					</div>
				);
				break;
			case 'configure2fa':
				formViews = (
					<Configure
						setView={setView}
						isLoading={signInLoader}
						userEmail={userEmail}
						setup2fakey={setup2faKey}
						configure2Fa={configure2Fa}
						onSubmit={type => setOTPType(type)}
					/>
				);
				break;
			case 'configureTOTP':
				formViews =
					otpType === 'totp' ? (
						<QRCode
							setView={setView}
							isLoading={signInLoader}
							authId={authId}
							options={totpOptions}
							submit={submit2FaForm}
							code={code}
							setCode={setCode}
						/>
					) : (
						<EmailSubmitCode
							userEmail={userEmail}
							setView={setView}
							isLoading={signInLoader}
							authId={authId}
							code={code}
							setCode={setCode}
							submit={submit2FaForm}
						/>
					);
				break;
			case '2faForm':
				formViews =
					method === 'totp' ? (
						<SubmitCode
							setView={setView}
							error={error}
							isLoading={signInLoader}
							authId={authId}
							setup2fakey={setup2faKey}
							configure2Fa={configure2Fa}
							onSubmit={type => setOTPType(type)}
							code={code}
							setCode={setCode}
							submit={submit2FaForm}
						/>
					) : (
						<EmailSubmitCode
							setView={setView}
							userEmail={userEmail}
							error={error}
							isLoading={signInLoader}
							authId={authId}
							setup2fakey={setup2faKey}
							configure2Fa={configure2Fa}
							onSubmit={type => setOTPType(type)}
							code={code}
							setCode={setCode}
							submit={submit2FaForm}
						/>
					);
				break;
			case 'downloadTOTPbackupCodes':
				formViews = (
					<BackupCode
						setView={setView}
						error={error}
						isLoading={signInLoader}
						authId={authId}
						codes={backupCodes}
						downloadBackupCodes={downloadBackupCodes}
						submit={submit2FaForm}
					/>
				);
				break;
		}
		return formViews;
	}, [
		setView,
		view,
		totpOptions,
		authId,
		signInLoader,
		submit2FaForm,
		setup2fa,
		required2fa,
		configure2Fa,
	]);

	return { views, setView };
};

export default use2FAViews;
