export const GET_LICENSE_DATA = 'GET_LICENSE_DATA';
export const GET_LICENSE_DATA_SUCCESS = 'GET_LICENSE_DATA_SUCCESS';
export const GET_LICENSE_DATA_FAILED = 'GET_LICENSE_DATA_FAILED';

export function getLicenses(paymentId) {
    return {
        type: GET_LICENSE_DATA,
        paymentId,
    };
}
