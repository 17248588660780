import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    GET_CREATIVES,
    GET_CREATIVES_FAILED,
    GET_CREATIVES_SUCCESS,
    GET_PAYOUTS,
    GET_PAYOUTS_FAILED,
    GET_PAYOUTS_SUCCESS,
    GET_REFERRALS,
    GET_REFERRALS_FAILED,
    GET_REFERRALS_SUCCESS,
    GET_VISITS,
    GET_VISITS_FAILED,
    GET_VISITS_SUCCESS,
    REGISTER_NEW_USER,
    REGISTER_NEW_USER_FAILED,
    REGISTER_NEW_USER_SUCCESS,
    RESET_AFFILIATE_DATA,
} from '../actions/AffiliateActions';
import { getErrorMessage } from '../utils/helper';

const initialState = {
    loading: false,
    visits: undefined,
    referrals: undefined,
    payouts: undefined,
    registrationSuccess: false,
    registrationErrors: undefined,
    creatives: undefined,
};

export default function affiliateReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VISITS:
            return {
                ...state,
                loading: true,
            };
        case GET_VISITS_SUCCESS:
            return {
                ...state,
                visits: action.response,
                loading: false,
            };
        case GET_VISITS_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Unable to get visits, please try again'
            );
            return {
                ...state,
                loading: false,
            };
        case GET_REFERRALS:
            return {
                ...state,
                loading: true,
            };
        case GET_REFERRALS_SUCCESS:
            return {
                ...state,
                referrals: action.response,
                loading: false,
            };
        case GET_REFERRALS_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Unable to get referrals, please try again'
            );
            return {
                ...state,
                loading: false,
            };
        case GET_PAYOUTS:
            return {
                ...state,
                loading: true,
            };
        case GET_PAYOUTS_SUCCESS:
            return {
                ...state,
                payouts: action.response,
                loading: false,
            };
        case GET_PAYOUTS_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Unable to get payouts, please try again'
            );
            return {
                ...state,
                loading: false,
            };
        case GET_CREATIVES:
            return {
                ...state,
                loading: true,
            };
        case GET_CREATIVES_SUCCESS:
            return {
                ...state,
                creatives: action.response,
                loading: false,
            };
        case GET_CREATIVES_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Unable to get creatives, please try again'
            );
            return {
                ...state,
                loading: false,
            };
        case REGISTER_NEW_USER:
            return {
                ...state,
                registrationSuccess: false,
                registrationErrors: undefined,
                loading: true,
            };
        case REGISTER_NEW_USER_SUCCESS:
            toast.success('Successfully registered, please check your email.');
            return {
                ...state,
                registrationErrors: undefined,
                registrationSuccess: true,
                loading: false,
            };
        case REGISTER_NEW_USER_FAILED:
            const errorData = action.error.data;
            if (
                errorData.hasOwnProperty('code') &&
                errorData.code.toString() === 'validation_errors'
            ) {
                toast.error('Validation error, please check inputs');
                return {
                    ...state,
                    registrationErrors: errorData.message,
                    registrationSuccess: false,
                    loading: false,
                };
            } else {
                toast.error(getErrorMessage(action.error));
                return {
                    ...state,
                    registrationSuccess: false,
                    loading: false,
                };
            }

        case RESET_AFFILIATE_DATA:
            return {
                ...initialState,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
