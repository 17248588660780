import React, { useEffect } from 'react';

import UserLayout from './layouts/UserLayout';
import AuthLayout from './layouts/AuthLayout';

import { Route, Redirect, Switch } from 'react-router-dom';
import CheckoutContainer from './containers/CheckoutContainer';
import PaymentSuccessContainer from './containers/PaymentSuccessContainer';
import PaymentFailedContainer from './containers/PaymentFailedContainer';
import CommonDownloadContainer from './containers/CommonDownloadContainer';
import Maintenance from './Maintenance';

function App() {
    return (
        <div>
            <Switch>
                <Route
                    path="/auth"
                    render={props => <AuthLayout {...props} />}
                />
                <Route
                    path="/user"
                    render={props => <UserLayout {...props} />}
                />
                <Route
                    path="/checkout"
                    component={CheckoutContainer}
                />
                <Route
                    path="/payment-success"
                    component={PaymentSuccessContainer}
                />
                <Route
                    path="/payment-failed"
                    component={PaymentFailedContainer}
                />
                <Route
                    path="/download"
                    component={CommonDownloadContainer}
                />
                <Redirect
                    from="/"
                    to="/auth/sign-in"
                />
            </Switch>
        </div>
    );
}

export default App;
