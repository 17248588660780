import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import BackButton from './BackButton';

const QRCode = ({ options, submit, code, setCode, setView }) => {
	const inputRef = useRef(null);
	useEffect(() => {
		inputRef.current.focus();
	}, []);

	const [copied, setCopied] = useState(false);

	useEffect(() => {
		copied && toast.success('Copied.');
		setCopied(false);
	}, [copied]);

	return (
		<div className="Wpd2FAForm">
			<BackButton
				goto="configure2fa"
				setView={setView}
			/>
			<div className="Wpd2FAHeadingWrapper">
				<div className="Wpd2FAHeadingIcon">
					<i className="wpd-icon wpd-key-2"></i>
				</div>
				<h3 className="Wpd2FAHeadingTitle">
					Two-Factor Authentication
				</h3>
				<p className="Wpd2FAHeadingInfo">
					Finish enabling two factor authentication.
				</p>
			</div>

			<div className="Wpd2FAFormBody">
				<div className="Wpd2FAQrCodeWrapper">
					<div
						className="Wpd2FAQrCode"
						style={{ position: 'relative' }}
					>
						{options.qr.length !== 0 && (
							<img
								src={options.qr}
								alt="qrCode"
							/>
						)}

						{options.qr.length === 0 && (
							<Spinner
								animation="border"
								variant="dark"
								style={{
									height: 26,
									width: 26,
									position: 'absolute',
									left: '50%',
									top: '50%',
									translate: '-50% -50%',
								}}
							/>
						)}
					</div>
				</div>
				<p>Scan the QR Code with an Authenticator app</p>
				{options.key && (
					<>
						<div
							className="Wpd2FAFormDivider WpdWithText"
							data-text="OR"
						/>
						<p style={{ marginBottom: '15px' }}>
							Place this Setup Key below inside your Authenticator
							app
						</p>
						<div className="Wpd2FASingleSetupKeyWrapper">
							<p className="Wpd2FASingleSetupKeyLabel">
								Setup Key:
							</p>
							<div className="Wpd2FASingleSetupKeyInner">
								<p className="Wpd2FASingleSetupKey">
									{options.key}
								</p>
								<CopyToClipboard
									text={options.key}
									onCopy={() => setCopied(true)}
								>
									<button className="Wpd2FASingleSetupKeyCopyButton">
										<i className="wpd-icon wpd-copy-2"></i>
									</button>
								</CopyToClipboard>
							</div>
						</div>
					</>
				)}

				<div className="Wpd2FAFormInputFieldWrapper mt-4.5">
					<label className="Wpd2FAFormInputFieldLabel">
						Enter the 6 Digit Verification code
					</label>
					<Form
						onSubmit={e => {
							e.preventDefault();
							submit();
						}}
					>
						<div
							className={`Wpd2FAFormInputFieldInner ${
								false ? 'WpdHasError' : ''
							}`}
						>
							<input
								ref={inputRef}
								value={code}
								type="text"
								className="Wpd2FAFormInputField"
								placeholder="Type your code here..."
								onChange={e => setCode(e.target.value)}
							/>
							<button
								type="submit"
								className="Wpd2FAFormInputFieldButton"
								disabled={!code.length}
							>
								Confirm
							</button>
						</div>

						{/* <p className="Wpd2FAFormInputFieldError">
                    Expired code or wrong one
                  </p> */}
					</Form>
				</div>
			</div>
		</div>
	);
};

export default QRCode;
