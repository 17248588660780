import React, { useEffect } from 'react';
import EB from '../../Markup/assets/img/essential-blocks-icon.png';
import NX from '../../Markup/assets/img/NotificationX.svg';
import Templately from '../../Markup/assets/img/Templately.svg';
import EA from '../../Markup/assets/img/EA-Logo.png';
import FLEXIA from '../../Markup/assets/img/flexia-logo.svg';
import BD from '../../Markup/assets/img/Betterdocs-icon.png';
import EM from '../../Markup/assets/img/embedpress-icon.svg';
import BL from '../../Markup/assets/img/betterlinks-icon.png';
import SP from '../../Markup/assets/img/wp-scheduled-posts-logo.png';
import EJ from '../../Markup/assets/img/easyjobs-icon-blue.svg';
import DC from '../../Markup/assets/img/disable-comments-icon-256x256.png';
import BP from '../../Markup/assets/img/better-payment.png';
import FreeDownloads from '../Common/Download/FreeDownloads';
import DownloadItem from '../Common/Download/MyDownloadItem';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';

const freeDownloads = [
    {
        name: 'Essential Addons For Elementor',
        image: EA,
        slug: 'essential-addons-for-elementor-lite',
    },
    { name: 'NotificationX', image: NX, slug: 'notificationx' },
    {
        name: 'Essential Blocks for Gutenberg',
        image: EB,
        slug: 'essential-blocks',
    },
    { name: 'Templately', image: Templately, slug: 'templately' },
    { name: 'Disable comments', image: DC, slug: 'disable-comments' },
    { name: 'BetterDocs', image: BD, slug: 'betterdocs' },
    { name: 'EmbedPress', image: EM, slug: 'embedPress' },
    { name: 'Betterlinks', image: BL, slug: 'betterlinks' },
    { name: 'Flexia Theme', slug: 'flexia', isTheme: true, image: FLEXIA },
    { name: 'Better Payment', image: BP, slug: 'better-payment' },
    { name: 'SchedulePress', image: SP, slug: 'wp-scheduled-posts' },
    { name: 'Easyjobs', image: EJ, slug: 'easyjobs' },
];

const Download = ({
    downloads,
    loading,
    getDownloads,
    downloadProduct,
    downloadItem,
    downloading,
}) => {
    useEffect(() => {
        getDownloads();
    }, []);
    return (
        <>
            <section className="WpdMyProductSection">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">PRO Downloads</h4>
                </div>
                {loading ? (
                    <Loader />
                ) : downloads && downloads.data?.length > 0 ? (
                    <div className="WpdDownloadItemWrapper">
                        {downloads.data.map((download, index) => (
                            <DownloadItem
                                key={index}
                                {...download}
                                downloadProduct={downloadProduct}
                                downloadItem={downloadItem}
                                downloading={downloading}
                            />
                        ))}
                    </div>
                ) : (
                    <EmptyMessage>
                        There have no download product available.
                    </EmptyMessage>
                )}
            </section>
            <section className="WpdFreeDownloadSection WpdSectionGap">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">Free Downloads</h4>
                </div>
                <div className="WpdDownloadItemWrapper">
                    {freeDownloads.map((downloadItem, index) => (
                        <FreeDownloads
                            key={index}
                            {...downloadItem}
                        />
                    ))}
                </div>
            </section>
        </>
    );
};

export default Download;
