import React, { useEffect, useState } from 'react';
import {
    agencyLifetime,
    agencyYearly,
    upsellProducts,
} from '../../utils/UpsellProducts';

const Upsell = ({
    addToCartUpsell,
    cartData,
    coupon,
    licenseKey,
    setLicenseKey,
    cartLoading,
}) => {
    const [checked, setChecked] = useState({
        downloadId: '',
        priceId: '',
    });
    const [removeLicenseKey, setRemoveLicenseKey] = useState(false);
    const [upsells, setUpsells] = useState(undefined);
    const getOldCart = () => {
        const oldCart = localStorage.getItem('old_cart');
        if (oldCart && oldCart.length) {
            return JSON.parse(oldCart);
        }
        return null;
    };
    useEffect(() => {
        if (cartData && cartData.details && cartData.details.length) {
            if (cartData.details.length === 1 && !upsells) {
                let product = cartData.details[0];
                setChecked({
                    downloadId: parseInt(product.id),
                    priceId: parseInt(product?.item_number?.options?.price_id),
                });
                const oldCart = getOldCart();
                if (oldCart) {
                    product = oldCart.items[0];
                }
                const [filtered] = upsellProducts.filter(
                    each =>
                        each.downloadId === parseInt(product.id) ||
                        each.downloadId === parseInt(product.download_id)
                );

                if (filtered && filtered.upsells.length) {
                    const [filteredUpsells] = filtered.upsells.filter(each => {
                        return (
                            each.priceId ===
                                parseInt(
                                    product?.item_number?.options?.price_id
                                ) ||
                            each.priceId === parseInt(product?.price_id)
                        );
                    });
                    filteredUpsells &&
                        filteredUpsells.products.length &&
                        setUpsells(filteredUpsells.products);
                }
            } else {
                if (!upsells) {
                    setUpsells([agencyYearly, agencyLifetime]);
                }
            }
        }
    }, [cartData]);

    useEffect(() => {
        if (cartLoading && licenseKey.length) {
            setRemoveLicenseKey(true);
        } else {
            if (removeLicenseKey) {
                setLicenseKey('');
            }
        }
    }, [cartLoading]);

    const handleChange = (e, upsellProduct) => {
        if (e.target.checked) {
            setChecked({
                downloadId: upsellProduct.downloadId,
                priceId: upsellProduct.priceId,
            });
            let oldCart = getOldCart();
            if (!oldCart) {
                oldCart = {};
                oldCart.items = cartData.details.map((item, index) => {
                    return {
                        download_id: item.id,
                        price_id: item.item_number?.options?.price_id,
                    };
                });
                if (coupon && coupon.code) {
                    oldCart.coupon = coupon.code;
                }
                if (licenseKey) {
                    oldCart.licenseKey = licenseKey;
                }
                localStorage.setItem('old_cart', JSON.stringify(oldCart));
            }
            addToCartUpsell(
                [
                    {
                        download_id: upsellProduct.downloadId,
                        price_id: upsellProduct.priceId,
                    },
                ],
                upsellProduct.coupon
            );
        } else {
            setChecked({
                downloadId: '',
                priceId: '',
            });
            const oldCartData = localStorage.getItem('old_cart');
            if (oldCartData && oldCartData.length) {
                const cart = JSON.parse(oldCartData);
                if (cart.coupon) {
                    addToCartUpsell(cart.items, cart.coupon);
                } else {
                    addToCartUpsell(cart.items);
                }

                if (cart.licenseKey) {
                    setLicenseKey(cart.licenseKey);
                }

                localStorage.removeItem('old_cart');
            }
        }
    };

    const isChecked = upsellItem =>
        checked &&
        checked.priceId === upsellItem.priceId &&
        checked.downloadId === upsellItem.downloadId;

    const isAgencyLifetime = item =>
        item.downloadId === 474227 && item.priceId === 2;
    return (
        (upsells && !cartLoading && (
            <section className="WpdUpSellSection WpdSectionGap">
                {upsells.map((upsellItem, i) => (
                    <label
                        className={`WpdUpSell`}
                        key={i}
                    >
                        <input
                            className="WpdUpSellInput"
                            type="checkbox"
                            onChange={e => handleChange(e, upsellItem)}
                            checked={isChecked(upsellItem)}
                        />
                        <div className="WpdUpSellDetails">
                            <h1 className="WpdUpSellTitle">
                                {upsellItem.name}
                            </h1>
                            <p
                                className="WpdUpSellText"
                                dangerouslySetInnerHTML={{
                                    __html: upsellItem.details(
                                        isChecked(upsellItem)
                                            ? 0
                                            : cartData.total,
                                        cartData
                                    ),
                                }}
                            ></p>
                            {upsellItem.savedValue && (
                                <span className={`WpdUpSellRibbon`}>
                                    ${upsellItem.savedValue} Value
                                </span>
                            )}
                        </div>
                    </label>
                ))}
            </section>
        )) ||
        ''
    );
};
export default Upsell;
