import React, { useEffect } from 'react';
import HistoryItem from './HistoryItem';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';

function PurchaseHistory({
    purchaseHistory,
    getPurchaseHistory,
    loading,
    history,
    download,
    downloading,
    downloadItem,
}) {
    useEffect(() => {
        getPurchaseHistory();
    }, [getPurchaseHistory]);
    return (
        <section>
            <div className="WpdSectionTitleWrap">
                <h4 className="WpdSectionTitle mr-2">Purchase History</h4>
            </div>
            {loading ? (
                <Loader />
            ) : purchaseHistory && purchaseHistory.length > 0 ? (
                <div className="WpdDataTableWrapper">
                    <table className="WpdDataTable">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Invoice</th>
                                <th>License Key</th>
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseHistory.map((historyItem, index) => {
                                return (
                                    <HistoryItem
                                        key={index}
                                        {...historyItem}
                                        history={history}
                                        download={download}
                                        downloading={downloading}
                                        downloadItem={downloadItem}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyMessage>There have no available Purchase.</EmptyMessage>
            )}
        </section>
    );
}

export default PurchaseHistory;
