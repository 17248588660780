import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { signOut } from '../../actions/index';
import '../../Markup/assets/css/Wpd-Icon/style.css';
import WpiSmallLogo from '../../Markup/assets/img/WpdLogoOnly.svg';
import WpiLogo from '../../Markup/assets/img/WpdLogoWithText.svg';
import routes from '../../routes';
import { clearUserLocalStorage } from '../../utils/helper';
import { ReactComponent as EmptyDataIcon } from '../../Markup/assets/img/wpd.svg';

const getFilteredRoutes = (routes, accountType, signIn) => {
    let filteredRoutes = [];
    switch (accountType) {
        case 'SUPER_ADMIN':
            filteredRoutes = routes.filter(
                route =>
                    (route.sidebar &&
                        route.is_admin_route &&
                        !route.verified) ||
                    (route.sidebar && route.is_admin_route) ||
                    (route.is_manager_route && route.sidebar)
            );
            break;
        case 'ADMIN':
            filteredRoutes = routes.filter(route => route.sidebar);
            break;
        case 'SHOP_MANAGER':
            filteredRoutes = routes.filter(route => {
                if (route.is_manager_route && route.sidebar) {
                    return true;
                }
                if (route.sidebar && !route.is_admin_route) {
                    if (
                        signIn &&
                        signIn.hasOwnProperty('is_customer') &&
                        !signIn.is_customer
                    ) {
                        return !route.customer_only;
                    }
                    return true;
                }
                return false;
            });
            break;
        case 'CUSTOMER':
            filteredRoutes = routes.filter(route => {
                if (
                    !route.sidebar ||
                    route.is_manager_route ||
                    route.is_admin_route
                ) {
                    return false;
                }

                if (route.sidebar && !route.is_admin_route) {
                    if (
                        signIn &&
                        signIn.hasOwnProperty('is_customer') &&
                        !signIn.is_customer
                    ) {
                        return !route.customer_only;
                    }
                    return true;
                }
                return false;
            });
            break;
        default:
            filteredRoutes = routes.filter(route => {
                if (
                    (route.sidebar === true &&
                        route.is_admin_route === false &&
                        !route.verified) ||
                    (route.sidebar === true && route.is_admin_route === false)
                ) {
                    if (
                        signIn &&
                        signIn.hasOwnProperty('is_customer') &&
                        !signIn.is_customer
                    ) {
                        return !route.customer_only;
                    }
                    return true;
                }
                return false;
            });
            break;
    }

    return filteredRoutes;
};

class SideNav extends React.Component {
    constructor(props) {
        super(props);
        // const user = JSON.parse(localStorage.getItem('user_info'));
        const accountType = props.accountType;
        const signIn = props.signIn;
        this.state = {
            activePath: props.location.pathname,
            items: getFilteredRoutes(routes, accountType, signIn),
            secendaySideBarPath: '',
            toggoleSecendaySidebar: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.accountType !== this.props.accountType) {
            this.setState({
                items: getFilteredRoutes(
                    routes,
                    this.props.accountType,
                    this.props.signIn
                ),
            });
        }
    }

    onItemClick = path => {
        this.setState({ activePath: path });
    };

    secondarySideBarClick = path => {
        if (this.state.toggoleSecendaySidebar) {
            this.setState({
                secendaySideBarPath: path,
                toggoleSecendaySidebar: false,
            });
        } else {
            this.setState({
                secendaySideBarPath: path,
                toggoleSecendaySidebar: true,
            });
        }
    };

    signOut = () => {
        clearUserLocalStorage();
        this.props.userSignOut();
        this.props.history.push('/auth/sign-in');
    };

    render() {
        const { items } = this.state;
        const { toggleSideBar } = this.props;
        return (
            <section
                className={`WpdSidebar ${toggleSideBar === true ? 'Toggled' : ''}`}
            >
                <div className="WpdSidebarContent">
                    <div className="WpdSidebarContentTop">
                        <Link
                            to={'/user/dashboard'}
                            className="WpdSiteLogo"
                        >
                            <img
                                src={WpiLogo}
                                alt="WPDeveloper"
                            />
                        </Link>
                        <Link
                            to={'/user/dashboard'}
                            className="WpdSiteLogoCollapsed"
                        >
                            <img
                                src={WpiSmallLogo}
                                alt="WPD"
                            />
                        </Link>
                    </div>
                    <nav className="WpdSidebarContentMiddle">
                        <ul className="WpdSidebarNav">
                            {items.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {item.views.length === 0 && (
                                            <NavItem
                                                path={item.path}
                                                layout={item.layout}
                                                name={item.name}
                                                onItemClick={this.onItemClick}
                                                active={
                                                    item.layout + item.path ===
                                                    this.props.location.pathname
                                                }
                                                icon={item.icon}
                                                mapkey={index}
                                                key={index}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </ul>
                    </nav>
                    <div className="WpdSidebarContentBottom">
                        <div className="WpdFbCommunity">
                            <EmptyDataIcon className="WpdFbCommunity-image" />
                            <h3 className="WpdFbCommunity-title">
                                WPDeveloper Community
                            </h3>
                            <p className="WpdFbCommunity-info">
                                Join with 6 million+ happy users
                            </p>
                            <a
                                href="https://www.facebook.com/groups/432798227512253"
                                target="_blank"
                                rel="noreferrer"
                                className="WpdFbCommunity-link"
                            >
                                <span className="WpdIcon">
                                    <i className="wpd-icon wpd-facebook" />
                                </span>
                                <span className="WpdText">Visit Group</span>
                            </a>
                        </div>
                        {/* <div className="WpdShareButtonWrapper">
              <button className="WpdShareButton">
                <i className="wpd-icon wpd-share" />
              </button>
            </div> */}
                        <ul className="WpdSocialNav justify-content-center">
                            <li className="WpdSocialNavItem">
                                <a
                                    href="https://www.facebook.com/WPDeveloperNet/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="WpdSocialNavItemLink facebook"
                                >
                                    <div className="WpdSocialNavItemLinkIcon">
                                        <i className="wpd-icon wpd-facebook" />
                                    </div>
                                </a>
                            </li>
                            <li className="WpdSocialNavItem">
                                <a
                                    href="https://x.com/wpdevteam"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="WpdSocialNavItemLink twitter"
                                >
                                    <div className="WpdSocialNavItemLinkIcon">
                                        <i className="wpd-icon wpd-twitter-x" />
                                    </div>
                                </a>
                            </li>
                            <li className="WpdSocialNavItem">
                                <a
                                    href="https://www.linkedin.com/company/wpdev"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="WpdSocialNavItemLink linkedin"
                                >
                                    <div className="WpdSocialNavItemLinkIcon">
                                        <i className="wpd-icon wpd-linkedin" />
                                    </div>
                                </a>
                            </li>
                            <li className="WpdSocialNavItem">
                                <a
                                    href="https://www.instagram.com/wpdevelopernet/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="WpdSocialNavItemLink instagram"
                                >
                                    <div className="WpdSocialNavItemLinkIcon">
                                        <i className="wpd-icon wpd-instagram" />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

const RouterSideNav = withRouter(props => <SideNav {...props} />);

class NavItem extends React.Component {
    handleClick = () => {
        const { path, onItemClick } = this.props;
        onItemClick(path);
    };

    render() {
        const { active, icon, mapkey } = this.props;
        return (
            <li
                className="WpdSidebarNavItem"
                key={mapkey}
            >
                <Link
                    to={this.props.layout + this.props.path}
                    className={`WpdSidebarNavItemLink ${active && 'Active'}`}
                >
                    <div className="WpdSidebarNavItemLinkIcon">
                        <i className={icon} />
                    </div>
                    <div className="WpdSidebarNavItemLinkText">
                        {this.props.name}
                    </div>
                </Link>
            </li>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userSignOut: () => dispatch(signOut()),
    };
};

const mapStateToProps = state => {
    return {
        accountType:
            state.auth && state.auth.signin && state.auth.signin.user_type,
        signIn: state.auth && state.auth.signin && state.auth.signin,
    };
};

const LeftSideBar = props => <RouterSideNav {...props} />;

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
