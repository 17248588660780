import { connect } from 'react-redux';
import SignInForm from '../components/Auth/SignInForm';
import {
    configure2fa,
    downloadBackupCodes,
    getQrCode,
    signin,
} from '../actions/SigninActions';
import { verify2fa } from '../actions/SigninActions';

const mapStateToProps = state => {
    return {
        signin: state.auth.signin,
        signInLoader: state.auth.signInLoader,
        validationError: state.auth.validationError,
        queryParam: state.router.location.query,
        authId: state.auth.authId,
        required2fa: state.auth.required2fa,
        hiddenEmail: state.auth.hiddenEmail,
        setup2fa: state.auth.setup2fa,
        userEmail: state.auth.userEmail,
        setup2faKey: state.auth.setup2faKey,
        totpOptions: state.auth.totpOptions,
        backupCodes: state.auth?.backupCodes ?? [],
        method: state.auth.method,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userSignIn: value => dispatch(signin(value)),
        verify2fa: (user_id, code, method = 'email', mode = '') =>
            dispatch(verify2fa(user_id, code, method, mode)),
        configure2Fa: (key, method = 'email') =>
            dispatch(configure2fa(key, method)),
        downloadBackupCodes: (key, method = 'email') =>
            dispatch(downloadBackupCodes()),
        getQrCode: () => dispatch(getQrCode()),
    };
};

const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInForm);

export default SignIn;
