import { connect } from 'react-redux';
import ManageSites from '../components/ManageSites/ManageSites';
import { getProductSiteData, manageSite } from '../actions/ManageSiteActions';

const mapStateToProps = state => {
    return {
        siteData: state.manageSite.siteData,
        loading: state.manageSite.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProductSiteData: value => dispatch(getProductSiteData(value)),
        manageSite: value => dispatch(manageSite(value)),
    };
};

const ManageSiteContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageSites);

export default ManageSiteContainer;
