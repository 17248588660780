import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from '../utils/axiosApi';
import {
    SEND_AFFILIATE_INFO,
    SEND_AFFILIATE_INFO_FAILED,
    SEND_AFFILIATE_INFO_SUCCESS,
    SEND_PAYMENT_INFO,
    SEND_PAYMENT_INFO_FAILED,
    SEND_PAYMENT_INFO_SUCCESS,
} from '../actions/PaymentSuccessActions';
import { sleep } from '../utils/helper';

export function* sendPaymentInfo({ data }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/confirmation`;

    const { response, error } = yield defaultApi(
        endpoint,
        'POST',
        data,
        true,
        false,
        {},
        true
    );
    if (response) {
        yield put({
            type: SEND_PAYMENT_INFO_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: SEND_PAYMENT_INFO_FAILED,
            error,
        });
    }
}

export function* paymentInfo() {
    yield takeLatest(SEND_PAYMENT_INFO, sendPaymentInfo);
}

export function* sendAffiliateInfo({ info }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/affiliate`;

    const { response, error } = yield defaultApi(
        endpoint,
        'POST',
        info,
        true,
        false,
        {},
        true
    );
    if (response) {
        yield put({
            type: SEND_AFFILIATE_INFO_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: SEND_AFFILIATE_INFO_FAILED,
            error,
        });
    }
}

export function* affiliateInfo() {
    yield takeLatest(SEND_AFFILIATE_INFO, sendAffiliateInfo);
}

export default function* rootSaga() {
    yield all([paymentInfo(), affiliateInfo()]);
}
