import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    UPDATE_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION_FAILED,
    UPDATE_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_DATA,
    GET_SUBSCRIPTION_DATA_FAILED,
    GET_SUBSCRIPTION_DATA_SUCCESS,
} from '../actions/SubscriptionAction';
import { getErrorMessage } from '../utils/helper';

const initialState = {
    subscriptions: undefined,
    loading: false,
    updating: false,
};

export default function subscriptionReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SUBSCRIPTION_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_SUBSCRIPTION_DATA_SUCCESS:
            return {
                ...state,
                subscriptions: action.response,
                loading: false,
            };
        case GET_SUBSCRIPTION_DATA_FAILED:
            toast.error('Something went wrong!');
            return {
                ...state,
                subscriptions: undefined,
                loading: false,
            };
        case UPDATE_SUBSCRIPTION:
            return {
                ...state,
                updating: true,
            };
        case UPDATE_SUBSCRIPTION_SUCCESS:
            if (action.updateType === 'cancel') {
                toast.success('Subscription canceled successfully');
            }
            if (action.updateType === 'reactivate') {
                toast.success('Subscription reactivated successfully');
            }

            return {
                ...state,
                updating: false,
                subscriptions: action.response,
            };
        case UPDATE_SUBSCRIPTION_FAILED:
            if (action.updateType === 'cancel') {
                toast.error(
                    getErrorMessage(
                        action.error,
                        'Failed to cancel subscription'
                    )
                );
            }
            if (action.updateType === 'reactivate') {
                toast.error(
                    getErrorMessage(
                        action.error,
                        'Failed to reactive subscription'
                    )
                );
            }

            return {
                ...state,
                updating: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
