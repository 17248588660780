import React from 'react';
import '../Markup/assets/css/loader-styles.css';
import LoaderGif from '../Markup/assets/img/Loader.gif';

export default function Loader({ children, component }) {
    return component && component == 'checkout_product' ? (
        <div
            className="loaderDiv flex-column"
            style={{ minHeight: 120 }}
        >
            <img
                src={LoaderGif}
                alt="Loading..."
            />
            {children ? <p className="py-2">{children}</p> : ''}
        </div>
    ) : (
        <div
            className="loaderDiv flex-column"
            style={{ height: '200px' }}
        >
            <img
                src={LoaderGif}
                alt="Loading..."
            />
            {children ? <p className="py-2">{children}</p> : ''}
        </div>
    );
}
