import React from 'react';

const SiteItem = ({ url, deactivateSite }) => (
    <tr>
        <td>
            <a
                href={`//${url}`}
                target="_blank"
                className="WpdFont18"
            >
                {url}
            </a>
        </td>
        <td className="WpdDeactivateButton">
            <p>
                <button
                    onClick={() => deactivateSite(url)}
                    className="WpdSmallButton WpdDangerButton"
                >
                    <span className="WpdButtonInner">
                        <span className="WpdText">Deactivate Site</span>
                    </span>
                </button>
            </p>
        </td>
    </tr>
);

export default SiteItem;
