import React from 'react';
import GraphImage from '../../Markup/assets/img/Graph.svg';

function Graphs() {
    return (
        <div className="WpdTabContent">
            <section className="WpdMyAffiliateSection">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">Referral Graphs</h4>
                </div>
                <img
                    src={GraphImage}
                    alt=""
                    className="img-fluid w-100"
                />
            </section>
        </div>
    );
}

export default Graphs;
