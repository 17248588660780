import React from 'react';
import { Dropdown } from 'antd';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch } from 'react-redux';
import { disable2fa, downloadBackupCodes } from '../../actions/SigninActions';
import { toast } from 'react-toastify';

const menuOnClick = (menuName, otpType, dispatch) => {
	let action = () => {};

	if (menuName === 'disable') {
		action = () => dispatch(disable2fa(otpType));
	}

	if (menuName === 'recoveryDownload' && otpType === 'totp') {
		action = () => {
			dispatch(downloadBackupCodes());
			toast.info(
				'Your recovery code will download once it is generated.'
			);
		};
	}

	return evt => {
		action();
	};
};

const dropDownItems = (otpType = 'totp', isConfigured = false, dispatch) => {
	let items = [];

	if (!isConfigured) {
		let label = 'Configure';
		let type = otpType;
		if (otpType === 'email') {
			label = 'Disable Authenticator & Configure';
			type = 'totp';
		} else if (otpType === 'totp') {
			label = 'Disable Email & Configure';
			type = 'email';
		}

		items.push({
			label: <span>{label}</span>,
			key: '0',
			onClick: menuOnClick('disable', type, dispatch),
		});
	}

	if (isConfigured && otpType === 'totp') {
		items.push({
			label: <span>Generate New Recovery Codes</span>,
			key: '1',
			onClick: menuOnClick('recoveryDownload', otpType, dispatch),
		});
	}

	if (isConfigured) {
		items.push({
			label: 'Disable',
			key: '3',
			onClick: menuOnClick('disable', otpType, dispatch),
		});
	}

	return items;
};

const Badge = ({ isConfigured }) => {
	if (!isConfigured) {
		return null;
	}
	return <span className="Wpd2FAMethodBadge Success">Configured</span>;
};

const TwoFASettings = ({ profile }) => {
	const dispatch = useDispatch();

	return (
		<section className="Wpd2FAMethodSection">
			<h3 className="Wpd2FAMethodHeading">
				Two-Factor Authentication Methods
			</h3>
			{profile == undefined && (
				<Spinner
					animation="border"
					variant="dark"
					style={{ height: 20, width: 20 }}
				/>
			)}
			{profile && (
				<div className="Wpd2FAMethodWrapper">
					<div className="Wpd2FAMethod">
						<div className="Wpd2FAMethodIcon">
							<i className="wpd-icon wpd-tablet"></i>
						</div>
						<div className="Wpd2FAMethodContent">
							<div className="Wpd2FAMethodTitleWrapper">
								<h3 className="Wpd2FAMethodTitle">
									Authenticator App
								</h3>
								<Badge isConfigured={profile?.twoFA?.totp} />
							</div>
							<div className="Wpd2FAMethodInfo">
								{!profile?.twoFA?.totp && (
									<p>
										When signing in, you will be asked to
										enter an OTP generated by the
										Authenticator app you have configured.
									</p>
								)}
								{profile?.twoFA?.totp && (
									<p>
										Your two-factor authentication setup is
										complete. You can now use this extra
										layer of security to protect your
										account.
									</p>
								)}
							</div>
						</div>
						<Dropdown
							menu={{
								items: dropDownItems(
									'totp',
									profile?.twoFA?.totp,
									dispatch
								),
							}}
							trigger={['click']}
							placement="bottomRight"
						>
							<button
								className="Wpd2FAMethodDropdownButton"
								onClick={e => e.preventDefault()}
							>
								<i className="wpd-icon wpd-ellipsis"></i>
							</button>
						</Dropdown>
					</div>
					<div className="Wpd2FAMethod">
						<div className="Wpd2FAMethodIcon">
							<i className="wpd-icon wpd-envelop"></i>
						</div>
						<div className="Wpd2FAMethodContent">
							<div className="Wpd2FAMethodTitleWrapper">
								<h3 className="Wpd2FAMethodTitle">Mail</h3>
								<Badge isConfigured={profile?.twoFA?.email} />
							</div>
							<div className="Wpd2FAMethodInfo">
								{!profile?.twoFA?.email && (
									<p>
										When signing in, you will be asked to
										enter the Login Verification code which
										has been sent over to your email address
										associated with the WPDeveloper account.
									</p>
								)}
								{profile?.twoFA?.email && (
									<p>
										Your two-factor authentication setup is
										complete. You can now use this extra
										layer of security to protect your
										account.
									</p>
								)}
							</div>
						</div>
						<Dropdown
							menu={{
								items: dropDownItems(
									'email',
									profile?.twoFA?.email,
									dispatch
								),
							}}
							trigger={['click']}
							placement="bottomRight"
						>
							<button
								className="Wpd2FAMethodDropdownButton"
								onClick={e => e.preventDefault()}
							>
								<i className="wpd-icon wpd-ellipsis"></i>
							</button>
						</Dropdown>
					</div>
				</div>
			)}
		</section>
	);
};

export default TwoFASettings;
