import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../utils/ErrorMessage';

import WpiLogo from '../../Markup/assets/img/WpdLogoWithText.svg';
import Banner from '../../Markup/assets/img/login.svg';
import { maskEmail } from '../../utils/helper';
import use2FAViews from './2FA/use2FAViews';

/* eslint-disable */
const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const strongPassRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
);
/* eslint-enable */

const SignInForm = ({
    userSignIn,
    signin,
    history,
    signInLoader,
    validationError,
    queryParam,
    authId,
    required2fa,
    hiddenEmail,
    verify2fa,
    setup2fa,
    configure2Fa,
    userEmail,
    setup2faKey,
    totpOptions,
    backupCodes,
    downloadBackupCodes,
    method,
    getQrCode,
}) => {
    let email;
    let password;

    /* eslint-disable */
    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token && signin && signin.redirect) {
            //email.value = '';
            //password.value = '';
            if (queryParam && queryParam.redirect) {
                history.push(`/${queryParam.redirect}`);
            } else if (signin.accountType === 'SUPER_ADMIN') {
                history.push('/user/admin_user/dashboard');
            } else if (signin.accountType !== 'SUPER_ADMIN') {
                history.push('/user/dashboard');
            }
        }

        if (token && signin && !signin.redirect) {
            email.value = '';
            password.value = '';
            signin.accountType !== 'SUPER_ADMIN' &&
                signin.redirect_to === 'company-create-form' &&
                history.push('/auth/create-company-form');
        }
    }, [signin, history]);

    /* eslint-enable */

    const { views: formViews } = use2FAViews();

    return (
        <div className="WpdPageBody WpdLoginBody">
            <div className="WpdLoginHeader">
                <Link
                    to={'/auth/sign-in'}
                    className="WpdSiteLogo"
                >
                    <img
                        src={WpiLogo}
                        alt=""
                    />
                </Link>
            </div>
            <div className="WpdLoginWrapper">
                <div className="WpdLoginContent">{formViews}</div>
                <div className="WpdLoginImage">
                    <img
                        src={Banner}
                        alt=""
                        className="w-100 img-fluid"
                    />
                </div>
            </div>
        </div>
    );
};

SignInForm.prototypes = {
    signin: PropTypes.shape({
        redirect: PropTypes.bool,
        token: PropTypes.string,
    }),
};

export default SignInForm;
