import {
    SIGNED_OUT,
    LOADING_START,
    LOADING_STOP,
    SERVER_ERROR,
} from '../actions/index';
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
};

export default function util(state = initialState, action) {
    switch (action.type) {
        case LOADING_START:
            return {
                ...state,
                loading: true,
            };
        case LOADING_STOP:
            return {
                ...state,
                loading: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        case SERVER_ERROR:
            toast.error('Something went wrong, please try again later.');
            return { ...initialState };
        default:
            return state;
    }
}
