import React from 'react';
import {
    formatDate,
    handleCopyToClipBoard,
    isLifetime,
    ucFirst,
} from '../../utils/helper';
import { Link } from 'react-router-dom';
import TooltipComponent from '../../utils/TooltipComponent';

const LicenseItem = ({
    payment_id,
    name,
    status,
    limit,
    expiration,
    license_key,
    sites,
    history,
    setSiteData,
    license_id,
    activation_count,
    has_upgrades,
    renewal_link,
}) => {
    const handleClick = e => {
        e.preventDefault();
        setSiteData(payment_id, { license_key, name, sites, license_id });
        history.push(`/user/manage-site/${payment_id}`);
    };
    return (
        <tr>
            <td>
                <label>Name: </label>
                <p>{name}</p>
            </td>
            <td>
                <label>License key: </label>
                <p>
                    <TooltipComponent tooltipText={'Click to copy license key'}>
                        <button
                            className="WpdIconButton WpdOnlyIcon WpdYellowButton"
                            onClick={e =>
                                handleCopyToClipBoard(
                                    `license-${license_id}`,
                                    'License key copied'
                                )
                            }
                        >
                            <span className="wpd-icon wpd-key"></span>
                            <span
                                className="d-none"
                                id={`license-${license_id}`}
                            >
                                {license_key}
                            </span>
                        </button>
                    </TooltipComponent>
                </p>
            </td>
            <td className="WpdDeactivateButton">
                <label>Status: </label>
                <p>
                    <span
                        className={`WpdLabelButton ${
                            status.toString() === 'success'
                                ? 'WpdSuccessAlert'
                                : 'WpdDangerAlert'
                        }`}
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">{ucFirst(status)}</span>
                        </span>
                    </span>
                </p>
            </td>
            <td>
                <label>Activations: </label>
                <p>
                    {activation_count}/{limit}
                </p>
            </td>
            <td>
                <label>Expiration: </label>
                <p>
                    {formatDate(expiration, true).toLowerCase() !==
                    'invalid date'
                        ? formatDate(expiration, true)
                        : ucFirst(expiration)}
                </p>
            </td>
            <td className="WpdDeactivateButton">
                <label>Sites: </label>
                <p>
                    <button
                        onClick={handleClick}
                        className="WpdSmallButton WpdInfoButton"
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">Manage Sites</span>
                        </span>
                    </button>
                </p>
            </td>
            <td className="WpdDeactivateButton">
                {has_upgrades ? (
                    status.trim().toString() === 'expired' ? (
                        <>
                            <label>Renew: </label>
                            <p style={{ minWidth: 120 }}>
                                <Link
                                    to={renewal_link}
                                    className="WpdSmallButton WpdDangerButton text-center"
                                >
                                    <span className="WpdButtonInner">
                                        <span className="WpdText">
                                            Renew license
                                        </span>
                                    </span>
                                </Link>
                            </p>
                        </>
                    ) : (
                        <>
                            <label>Upgrades: </label>
                            <p style={{ minWidth: 120 }}>
                                <Link
                                    to={`/user/upgrade-license?license_id=${license_id}`}
                                    className="WpdSmallButton WpdDangerButton text-center"
                                >
                                    <span className="WpdButtonInner">
                                        <span className="WpdText">
                                            View Upgrade
                                        </span>
                                    </span>
                                </Link>
                            </p>
                        </>
                    )
                ) : (
                    'No upgrades'
                )}
            </td>
        </tr>
    );
};

export default LicenseItem;
