import React, { useEffect, useState } from 'react';
import Doc from '../../Markup/assets/img/animated-image/Doc.svg';
import WelcomeContent from './Welcome';
import MyProducts from '../MyProduct/MyProduct';
import LatestNews from './LatestNews';

const Dashboard = ({
    products,
    productsLoading,
    getProducts,
    latestNews,
    getLatestNews,
    setSiteData,
    signin,
    history,
    downloadProduct,
    downloadItem,
    downloading,
    newsLoading,
}) => {
    const [showProducts, setShowProducts] = useState(true);
    useEffect(() => {
        if (
            signin &&
            signin.hasOwnProperty('is_customer') &&
            !signin.is_customer
        ) {
            setShowProducts(false);
        }
    }, [signin]);
    return (
        <>
            <WelcomeContent
                userInfo={signin && signin.userInfo ? signin.userInfo : {}}
            />
            {showProducts && (
                <MyProducts
                    products={products}
                    productsLoading={productsLoading}
                    getProducts={getProducts}
                    setSiteData={setSiteData}
                    history={history}
                    currentPage="dashboard"
                    downloadProduct={downloadProduct}
                    downloadItem={downloadItem}
                    downloading={downloading}
                />
            )}

            <section className="WpdSectionPanel WpdMessageSection WpdKnowledgebaseMessage WpdSectionGap">
                <div className="WpdMessageSectionLeft">
                    <h4 className="WpdSectionTitle">Knowledgebase</h4>
                    <p className="WpdMessageSectionMessage">
                        Getting stuck? Browse through our helpful documentation
                        and knowledge base articles, or watch our quick video
                        tutorials to learn more about WPDeveloper and our
                        products.
                    </p>
                    <div className="WpdButtonGroup">
                        <a
                            href={`${process.env.REACT_APP_SITE_URL}/docs`}
                            className="WpdButton WpdInfoButton WpdFilled"
                            target="_blank"
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">Documentation</span>
                            </span>
                        </a>
                        <a
                            href="https://www.youtube.com/c/WPDeveloperNet"
                            className="WpdButton WpdDangerButton"
                            target="_blank"
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdIcon">
                                    <i className="wpd-icon wpd-play-button" />
                                </span>
                                <span className="WpdText">Youtube Channel</span>
                            </span>
                        </a>
                    </div>
                </div>
                <div className="WpdMessageSectionRight">
                    <object
                        type="image/svg+xml"
                        data={Doc}
                        aria-label="Knowledgebase image"
                    />
                </div>
            </section>
            <LatestNews
                latestNews={latestNews}
                getLatestNews={getLatestNews}
                newsLoading={newsLoading}
            />
        </>
    );
};

export default Dashboard;
