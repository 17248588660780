import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    SEND_PAYMENT_INFO,
    SEND_PAYMENT_INFO_FAILED,
    SEND_PAYMENT_INFO_SUCCESS,
} from '../actions/PaymentSuccessActions';
import { getErrorMessage } from '../utils/helper';

const initialState = {
    paymentInfo: undefined,
    loading: false,
    messageShowed: false,
    requestAgainCount: 0,
};

export default function paymentSuccessReducer(state = initialState, action) {
    switch (action.type) {
        case SEND_PAYMENT_INFO:
            return {
                ...state,
                paymentInfo: undefined,
                loading: true,
            };
        case SEND_PAYMENT_INFO_SUCCESS:
            return {
                ...state,
                paymentInfo: action.response,
                messageShowed: false,
                loading: false,
            };
        case SEND_PAYMENT_INFO_FAILED:
            if (
                action.error.data.code.toString() ===
                    'payment_confirmation_failed' &&
                state.requestAgainCount < 5
            ) {
                if (!state.messageShowed) {
                    toast.warn(getErrorMessage(action.error));
                }
                return {
                    ...state,
                    paymentInfo: {
                        requestAgain: true,
                    },
                    messageShowed: true,
                    loading: false,
                    requestAgainCount: state.requestAgainCount + 1,
                };
            }
            toast.error(
                getErrorMessage(
                    action.error,
                    'Failed to get payment confirmation data, please contact support.'
                )
            );
            return {
                ...state,
                messageShowed: false,
                paymentInfo: undefined,
                loading: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
