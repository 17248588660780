import { connect } from 'react-redux';
import { getProducts } from '../actions/ProductAction';
import MyProduct from '../components/MyProduct/MyProduct';
import { setSiteData } from '../actions/ManageSiteActions';
import { downloadProduct } from '../actions/DownloadActions';

const mapStateToProps = state => {
    return {
        products: state.product.products,
        productsLoading: state.product.loading,
        downloadItem: state.downloads.downloadItem,
        downloading: state.downloads.downloading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProducts: params => dispatch(getProducts(params)),
        setSiteData: (paymentId, data) =>
            dispatch(setSiteData(paymentId, data)),
        downloadProduct: data => dispatch(downloadProduct(data)),
    };
};

const MyProductContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyProduct);

export default MyProductContainer;
