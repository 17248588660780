import { connect } from 'react-redux';
import ResetPassword from '../components/Auth/ResetPassword';
import { userResetPass } from '../actions/ResetPasswordActions';

const mapStateToProps = state => {
    return {
        resetPass: state.resetPass.resetPass,
        validationError: state.resetPass.validationError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userResetPass: value => dispatch(userResetPass(value)),
    };
};

const ResetPasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);

export default ResetPasswordContainer;
