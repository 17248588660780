import { connect } from 'react-redux';
import { getUpgrades } from '../actions/UpgradeLicenseActions';
import UpgradeLicense from '../components/UpgradeLicense/UpgradeLicense';

const mapStateToProps = state => {
    return {
        loading: state.upgradeLicense.loading,
        upgrades: state.upgradeLicense.upgrades,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getUpgrades: licenseId => dispatch(getUpgrades(licenseId)),
    };
};

const UpgradeLicenseContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UpgradeLicense);

export default UpgradeLicenseContainer;
