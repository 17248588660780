import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    CREATE_TICKET,
    CREATE_TICKET_FAILED,
    CREATE_TICKET_SUCCESS,
    GET_TICKETS,
    GET_TICKETS_FAILED,
    GET_TICKETS_SUCCESS,
} from '../actions/MyTicketsActions';

const initialState = {
    loading: false,
    tickets: undefined,
    creating: false,
    created: undefined,
};

export default function myTicketsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_TICKETS:
            return {
                ...state,
                loading: true,
                creating: false,
                tickets: undefined,
                created: undefined,
            };
        case GET_TICKETS_SUCCESS:
            return {
                ...state,
                tickets: action.response,
                loading: false,
            };
        case GET_TICKETS_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Unable to get tickets, please try again'
            );
            return {
                ...state,
                tickets: undefined,
                loading: false,
            };
        case CREATE_TICKET:
            return {
                ...state,
                created: undefined,
                creating: true,
            };
        case CREATE_TICKET_SUCCESS:
            return {
                ...state,
                created: true,
                creating: false,
            };
        case CREATE_TICKET_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Failed to create ticket'
            );
            return {
                ...state,
                created: false,
                creating: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
