import { all, put, takeLatest } from 'redux-saga/effects';
import {
    GET_PROFILE,
    SAVE_PROFILE,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILED,
    SAVE_PROFILE_SUCCESS,
    SAVE_PROFILE_FAILED,
    ADD_CARD_SUCCESS,
    ADD_CARD_FAILED,
    ADD_CARD,
    SET_DEFAULT_CARD_SUCCESS,
    SET_DEFAULT_CARD_FAILED,
    SET_DEFAULT_CARD,
    REMOVE_CARD,
    REMOVE_CARD_SUCCESS,
    REMOVE_CARD_FAILED,
} from '../actions/ProfileAction';
import { defaultApi } from '../utils/axiosApi';

export function* loadProfileData() {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/profile`;

    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        undefined,
        true
    );
    if (response) {
        yield put({
            type: GET_PROFILE_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_PROFILE_FAILED,
            error,
        });
    }
}

export function* loadProfile() {
    yield takeLatest(GET_PROFILE, loadProfileData);
}
export function* saveProfileData({ data }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/profile`;

    const { response, error } = yield defaultApi(endpoint, 'POST', data, true);
    if (response) {
        yield put({
            type: SAVE_PROFILE_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: SAVE_PROFILE_FAILED,
            error,
        });
    }
}
export function* saveProfile() {
    yield takeLatest(SAVE_PROFILE, saveProfileData);
}

export function* addNewCard() {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/profile/add-card`;

    const { response, error } = yield defaultApi(endpoint, 'POST', {}, true);
    if (response) {
        yield put({
            type: ADD_CARD_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: ADD_CARD_FAILED,
            error,
        });
    }
}
export function* addCard() {
    yield takeLatest(ADD_CARD, addNewCard);
}

export function* setDefaultCard({ payment_method }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/profile/set-card-default`;

    const { response, error } = yield defaultApi(
        endpoint,
        'POST',
        { payment_method },
        true
    );
    if (response) {
        yield put({
            type: SET_DEFAULT_CARD_SUCCESS,
            response,
            payment_method,
        });
    }
    if (error) {
        yield put({
            type: SET_DEFAULT_CARD_FAILED,
            error,
        });
    }
}
export function* setDefault() {
    yield takeLatest(SET_DEFAULT_CARD, setDefaultCard);
}

export function* removeUserCard({ payment_method }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/profile/remove-card`;

    const { response, error } = yield defaultApi(
        endpoint,
        'POST',
        { payment_method },
        true
    );
    if (response) {
        yield put({
            type: REMOVE_CARD_SUCCESS,
            response,
            payment_method,
        });
    }
    if (error) {
        yield put({
            type: REMOVE_CARD_FAILED,
            error,
        });
    }
}
export function* removeCard() {
    yield takeLatest(REMOVE_CARD, removeUserCard);
}

export default function* rootSaga() {
    yield all([
        loadProfile(),
        saveProfile(),
        addCard(),
        setDefault(),
        removeCard(),
    ]);
}
