import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../../Markup/assets/img/WP Developer Icon With Text 1.svg';
import { Link } from 'react-router-dom';
import Loader from '../../utils/Loader';
import {
    formatDate,
    handleCopyToClipBoard,
    isProductionMode,
    twoDecimalFraction,
    ucFirst,
} from '../../utils/helper';
import CheckedCircle from '../../Images/CheckedCircle';
import { decode } from 'html-entities';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const PaymentSuccess = ({
    sendPaymentInfo,
    paymentInfo,
    loading,
    signin,
    getProfile,
    profile,
    setAutoLoggedInUser,
    sendAffiliateInfo,
}) => {
    const query = useQuery();
    const sessionId = query.get('session_id');
    const [customLoading, setCustomLoading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            if (!signin) {
                getProfile();
            }
        }
        setCustomLoading(true);
        setTimeout(function () {
            getPaymentInfo();
        }, 3000);
    }, []);

    useEffect(() => {
        if (profile) {
            setAutoLoggedInUser(profile);
        }
    }, [profile]);

    useEffect(() => {
        if (paymentInfo) {
            setCustomLoading(false);
            if (
                paymentInfo.requestAgain ||
                paymentInfo.status.toLowerCase() !== 'completed'
            ) {
                getPaymentInfo();
            }
            if (
                paymentInfo.subscriptions &&
                paymentInfo.subscriptions.length &&
                paymentInfo.subscriptions[0].status.toLowerCase() !== 'active'
            ) {
                getPaymentInfo();
            }
            if (
                paymentInfo.id &&
                paymentInfo.status.toLowerCase() === 'completed'
            ) {
                const affiliateInfo = { ...getAffiliateCookies() };
                if (Object.keys(affiliateInfo).length) {
                    sendAffiliateInfo({
                        payment_id: paymentInfo.id,
                        ...affiliateInfo,
                    });
                }

                /******** Marketing sales tracking  *********/
                if (isProductionMode()) {
                    window._pq = window._pq || [];
                    window._pq.push(['track', 'perfect']);
                    window._pa = window._pa || {};
                    window._pa.orderId = paymentInfo.payment; // OPTIONAL: attach unique conversion identifier to conversions
                    window._pa.revenue = twoDecimalFraction(paymentInfo.total); // OPTIONAL: attach dynamic purchase values to conversions
                    window._pa.productId = paymentInfo.products[0].sku; // OPTIONAL: Include product ID for use with dynamic ads
                    koiPa();
                }
            }
        }
    }, [paymentInfo]);

    const koiPa = () => {
        var _ss = _ss || [];
        _ss.push([
            '_setDomain',
            'https://koi-3QNPBGLO5W.marketingautomation.services/net',
        ]);
        _ss.push(['_setAccount', 'KOI-4EMI8S9602']);
        _ss.push(['_trackPageView']);
        var ss = document.createElement('script');
        ss.type = 'text/javascript';
        ss.async = true;
        ss.src =
            ('https:' == document.location.protocol ? 'https://' : 'http://') +
            'koi-3QNPBGLO5W.marketingautomation.services/client/ss.js?ver=2.4.0';
        var scr = document.getElementsByTagName('script')[0];
        scr.parentNode.insertBefore(ss, scr);
    };

    const getAffiliateCookies = () => {
        const values = {};
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        const affiliateCookies = [
            'affwp_ref_visit_id',
            'affwp_campaign',
            'affwp_ref',
            'affwp_visit_id',
            'affwp_affiliate_id',
        ];
        ca.forEach(c => {
            const [cname, cvalue] = c.split('=');
            if (affiliateCookies.includes(cname.trim())) {
                values[cname.trim()] = cvalue;
            }
        });
        return values;
    };

    const getPaymentInfo = () => {
        const gateway = query.get('edd_confirm');
        const data = {};
        switch (gateway) {
            case 'paypal_express':
                data.token = query.get('token');
                data.payerId = query.get('PayerID');
                data.paymentId = query.get('payment_id');
                break;
            case 'manual':
                data.paymentId = query.get('payment_id');
                break;
            default:
                data.session_id = sessionId;
        }
        if (Object.keys(data).length > 0) {
            sendPaymentInfo(data);
        }
    };

    return (
        <div className="WpdPageBody">
            <main className="WpdContentArea WpdCheckoutArea">
                <div className="container d-flex flex-column h-100">
                    <div className="WpdContentAreaBody WpdCheckoutContent mb-2">
                        <header className="WpdContentAreaHeader">
                            <div className="WpdHeaderWrapper">
                                <div className="WpdContentAreaHeaderLeft w-100 d-flex pt-3">
                                    <img
                                        src={Logo}
                                        alt="WPDeveloper"
                                    />
                                </div>
                            </div>
                        </header>
                        <header className="WpdCheckoutHeader">
                            <div className="WpdCheckoutHeaderWrapper pt-0 mb-2">
                                <div className="WpdCheckoutHeaderLeft d-flex flex-column align-items-center w-100 pt-2">
                                    <CheckedCircle
                                        height="75px"
                                        width="75px"
                                    />
                                    <h2 className="WpdCheckoutHeaderSemiTitle text-center mt-3">
                                        Thank you for your purchase!
                                    </h2>
                                    <div className="pt-4 d-flex justify-content-center flex-column text-center">
                                        {signin ? (
                                            ''
                                        ) : (
                                            <p className="pb-4">
                                                We have mailed login credentials
                                                to your email, please check that
                                                email for login credentials.
                                            </p>
                                        )}
                                        <div className="">
                                            <Link
                                                to="/auth/sign-in"
                                                className="WpdButton WpdInfoButton WpdFilled w-auto"
                                            >
                                                <span className="WpdButtonInner">
                                                    <span className="WpdText">
                                                        {signin
                                                            ? 'Go '
                                                            : 'Login '}
                                                        to Dashboard
                                                    </span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {((customLoading || loading) &&
                            (!paymentInfo || !paymentInfo.payment) && (
                                <Loader>
                                    {' '}
                                    Please wait, we are getting your
                                    subscription data.
                                </Loader>
                            )) ||
                            (paymentInfo && paymentInfo.payment && (
                                <>
                                    <section className="WpdMyPurchaseSection">
                                        <div className="WpdDataTableWrapper">
                                            <table className="WpdDataTable">
                                                <thead>
                                                    <tr>
                                                        <th>Payment</th>
                                                        <th>
                                                            {
                                                                paymentInfo.payment
                                                            }
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span>
                                                                Payment Status:{' '}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                <span className="WpdLabelButton WpdSuccessAlert">
                                                                    <span className="WpdButtonInner">
                                                                        <span className="WpdText">
                                                                            {paymentInfo.status
                                                                                ? paymentInfo.status
                                                                                : 'Pending'}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span>
                                                                Payment Method:{' '}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {paymentInfo.payment_method
                                                                    ? ucFirst(
                                                                          paymentInfo.payment_method
                                                                      )
                                                                    : sessionId
                                                                      ? 'Stripe'
                                                                      : 'Paypal'}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span>Date: </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {paymentInfo.completed_date
                                                                    ? paymentInfo.completed_date
                                                                    : formatDate(
                                                                          new Date()
                                                                      )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span>
                                                                Sub Total:{' '}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                $
                                                                {twoDecimalFraction(
                                                                    paymentInfo.subtotal
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span>
                                                                Total Price:{' '}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                $
                                                                {twoDecimalFraction(
                                                                    paymentInfo.total
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    {/*<tr>
                            <td>
                              <span>Invoice: </span>
                            </td>
                            <td>
                              <span>
                                <Link to={'/user/generate-invoice/765168'} className="WpdSmallButton WpdInfoButton">
                                  <span className="WpdButtonInner">
                                    <span className="WpdText">
                                      Generate Invoice
                                    </span>
                                  </span>
                                </Link>
                              </span>
                            </td>
                          </tr>*/}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    {(paymentInfo.licenses && (
                                        <section className="WpdMyPurchaseSection WpdSectionGap">
                                            <div className="WpdDataTableWrapper">
                                                <div className="WpdTableTitle">
                                                    <span className="WpdFont700">
                                                        License Keys:{' '}
                                                    </span>
                                                </div>
                                                <table className="WpdDataTable">
                                                    <tbody>
                                                        {paymentInfo.licenses.map(
                                                            (
                                                                license,
                                                                index
                                                            ) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span>
                                                                            {decode(
                                                                                license.name
                                                                            )
                                                                                .replace(
                                                                                    '&nbsp;',
                                                                                    ' '
                                                                                )
                                                                                .replace(
                                                                                    '&ndash;',
                                                                                    ' -'
                                                                                )
                                                                                .replace(
                                                                                    '&#8212;',
                                                                                    ''
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            <button
                                                                                className="WpdSmallButton WpdInfoAlert WpdProductCode"
                                                                                onClick={e =>
                                                                                    handleCopyToClipBoard(
                                                                                        `copy-license-${index}`,
                                                                                        'License key copied'
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span className="WpdButtonInner">
                                                                                    <span
                                                                                        className="WpdText"
                                                                                        id={`copy-license-${index}`}
                                                                                    >
                                                                                        {license &&
                                                                                            license.license_key}
                                                                                    </span>
                                                                                    <span className="WpdIcon">
                                                                                        <i className="wpd-icon wpd-copy" />
                                                                                    </span>
                                                                                </span>
                                                                            </button>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </section>
                                    )) ||
                                        ''}

                                    {(paymentInfo.subscriptions &&
                                        paymentInfo.subscriptions.length && (
                                            <section className="WpdMyPurchaseSection WpdSectionGap">
                                                <div className="WpdDataTableWrapper">
                                                    <div className="WpdTableTitle">
                                                        <span className="WpdFont700">
                                                            Subscription
                                                            Details:
                                                        </span>
                                                    </div>
                                                    <table className="WpdDataTable">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Subscription
                                                                </th>
                                                                <th>
                                                                    Renewal Date
                                                                </th>
                                                                <th>
                                                                    Initial
                                                                    Amount
                                                                </th>
                                                                <th>
                                                                    Times Billed
                                                                </th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {paymentInfo.subscriptions.map(
                                                                (
                                                                    subscription,
                                                                    key
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        <td>
                                                                            <span>
                                                                                {
                                                                                    subscription.name
                                                                                }{' '}
                                                                                <br />

                                                                                $
                                                                                {twoDecimalFraction(
                                                                                    subscription.amount
                                                                                )}

                                                                                /
                                                                                {
                                                                                    subscription.frequency
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {
                                                                                    subscription.renewal_date
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                $
                                                                                {twoDecimalFraction(
                                                                                    subscription.initial_amount
                                                                                )}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {
                                                                                    subscription.times_billed
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                <span
                                                                                    className={`WpdLabelButton ${
                                                                                        subscription.status ===
                                                                                        'active'
                                                                                            ? 'WpdSuccessAlert'
                                                                                            : 'WpdDangerAlert'
                                                                                    }`}
                                                                                >
                                                                                    <span className="WpdButtonInner">
                                                                                        <span className="WpdText">
                                                                                            {ucFirst(
                                                                                                subscription.status
                                                                                            )}
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </section>
                                        )) ||
                                        ''}
                                    {(paymentInfo.products && (
                                        <section className="WpdMyPurchaseSection WpdSectionGap">
                                            <div className="WpdDataTableWrapper">
                                                <div className="WpdTableTitle">
                                                    <span className="WpdFont700">
                                                        Products{' '}
                                                    </span>
                                                </div>
                                                <table className="WpdDataTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>SKU</th>
                                                            <th>Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paymentInfo.products.map(
                                                            (
                                                                product,
                                                                index
                                                            ) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <span>
                                                                            {
                                                                                product.name
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {
                                                                                product.sku
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            $
                                                                            {twoDecimalFraction(
                                                                                product.price
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </section>
                                    )) ||
                                        ''}
                                </>
                            )) ||
                            ''}
                    </div>

                    <footer className="WpdContentAreaFooter">
                        <p className="WpdColorSecondary w-100 text-center">
                            Copyright &copy; 2011 - {new Date().getFullYear()} |{' '}
                            <a
                                href="https://wpdeveloper.com"
                                className="WpdColorInfo"
                            >
                                WPDeveloper.com
                            </a>{' '}
                            | Powered by WordPress
                        </p>
                    </footer>
                </div>
            </main>
        </div>
    );
};

export default PaymentSuccess;
