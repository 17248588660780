import React from 'react';

const BackButton = ({ setView, goto }) => {
	return (
		<button
			className="Wpd2FABackButton"
			onClick={() => setView(goto)}
		>
			<i className="wpd-icon wpd-angle-left"></i>
		</button>
	);
};

export default BackButton;
