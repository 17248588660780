export const GET_DOWNLOADS = 'GET_DOWNLOADS';
export const GET_DOWNLOADS_SUCCESS = 'GET_DOWNLOADS_SUCCESS';
export const GET_DOWNLOADS_FAILED = 'GET_DOWNLOADS_FAILED';

export const DOWNLOAD_PRODUCT = 'DOWNLOAD_PRODUCT';
export const DOWNLOAD_PRODUCT_SUCCESS = 'DOWNLOAD_PRODUCT_SUCCESS';
export const DOWNLOAD_PRODUCT_FAILED = 'DOWNLOAD_PRODUCT_FAILED';

export function getDownloads() {
    return {
        type: GET_DOWNLOADS,
    };
}

export function downloadProduct(data) {
    return {
        type: DOWNLOAD_PRODUCT,
        data,
    };
}
