import React, { useEffect, useMemo, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import BackButton from './BackButton';

const Button = ({ label, type, setView, onClick = undefined }) => {
	if (onClick === undefined) {
		onClick = e => {
			e.preventDefault();
			setView(type);
		};
	}

	return (
		<a
			href="/"
			onClick={onClick}
		>
			{label}
		</a>
	);
};

const SubmitCode = ({
	submit,
	code,
	setCode,
	isLoading,
	error,
	setView: _setView,
}) => {
	const [view, setView] = useState('');

	const inputRef = useRef(null);
	useEffect(() => {
		inputRef.current.focus();
	}, []);

	const label = useMemo(() => {
		switch (view) {
			case '':
				return 'Enter your Two-factor Authentication Code';
			case 'recovery':
				return 'Enter your Two-factor Authentication Backup Code';
		}
	}, [view]);

	const helperButton = useMemo(() => {
		switch (view) {
			case '':
				return (
					<Button
						setView={setView}
						label="Use recovery code"
						type="recovery"
					/>
				);
			case 'recovery':
				return (
					<Button
						setView={setView}
						label="Use two factor code"
						type=""
					/>
				);
		}
	}, [view]);

	useEffect(() => {
		setCode('');
	}, [view]);

	return (
		<div className="Wpd2FAForm">
			<BackButton
				goto="signin"
				setView={_setView}
			/>
			<div className="Wpd2FAHeadingWrapper">
				<div className="Wpd2FAHeadingIcon">
					<i className="wpd-icon wpd-key-2"></i>
				</div>
				<h3 className="Wpd2FAHeadingTitle">
					Two-Factor Authentication
				</h3>
				<p className="Wpd2FAHeadingInfo">
					Please confirm access to your account by entering the
					authentication code provided by your authenticator
					application.
				</p>
			</div>
			<div className="Wpd2FAFormBody">
				<div className="Wpd2FAFormDivider" />
				<div className="Wpd2FAFormInputFieldWrapper">
					<label className="Wpd2FAFormInputFieldLabel">{label}</label>
					<Form
						onSubmit={e => {
							e.preventDefault();
							submit(view);
						}}
					>
						<div
							className={`Wpd2FAFormInputFieldInner ${
								error.code ? 'WpdHasError' : ''
							}`}
						>
							<input
								ref={inputRef}
								value={code}
								type="text"
								className="Wpd2FAFormInputField"
								placeholder="Type your code here..."
								onChange={e => setCode(e.target.value)}
							/>
							<button
								disabled={isLoading}
								type="submit"
								className="Wpd2FAFormInputFieldButton"
							>
								{isLoading ? 'Confirming...' : 'Confirm'}
							</button>
						</div>
						{error.code && (
							<p className="Wpd2FAFormInputFieldError">
								{error.code}
							</p>
						)}
					</Form>
				</div>
				<div className="w-100 mt-4">
					<p className="Wpd2FAFormBodyLink">{helperButton}</p>
				</div>
			</div>
		</div>
	);
};

export default SubmitCode;
