import React, { useEffect, useState } from 'react';
import ImagePlaceholder from '../../Markup/assets/img/ImagePlaceholder.svg';
import LoadingButton from '../../utils/LoadingButton';
import { trimFormFields, validateFormFields } from '../../utils/helper';

const Profile = ({ profile, saveProfile, updating }) => {
    const [profileFields, setProfileFields] = useState({
        first_name: '',
        last_name: '',
        display_name: '',
        email: '',
    });
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        profile &&
            setProfileFields({
                first_name: profile.first_name ? profile.first_name : '',
                last_name: profile.last_name ? profile.last_name : '',
                display_name: profile.display_name ? profile.display_name : '',
                email: profile.email ? profile.email : '',
            });
    }, [profile]);

    const profileSubmit = e => {
        e.preventDefault();
        const validated = validateFormFields(profileFields, {
            first_name: {
                required: {
                    value: true,
                    message: 'First name is required',
                },
                maxLength: {
                    value: 60,
                    message: 'Fist name should not be more than 60 characters',
                },
            },
            last_name: {
                required: {
                    value: true,
                    message: 'Last name is required',
                },
                maxLength: {
                    value: 60,
                    message: 'Last name should not be more than 60 characters',
                },
            },
            display_name: {
                required: {
                    value: true,
                    message: 'Display name is required',
                },
                maxLength: {
                    value: 100,
                    message:
                        'Display name should not be more than 100 characters',
                },
            },
            email: {
                required: {
                    value: true,
                    message: 'Email is required',
                },
                email: {
                    value: true,
                    message: 'Please insert a valid email',
                },
            },
        });
        if (!validated.valid) {
            setFormErrors(validated.errors);
        } else {
            saveProfile({ ...trimFormFields(profileFields), type: 'profile' });
            setFormErrors({});
        }
    };

    const setField = (name, value) => {
        setProfileFields(oldData => ({
            ...oldData,
            [name]: value,
        }));
    };
    const showError = name => {
        if (formErrors.hasOwnProperty(name) && formErrors[name].length) {
            return <p className="WpdErrorMessage">{formErrors[name]}</p>;
        }
        return '';
    };
    return (
        <div className="WpdTabContent">
            <section className="WpdMyAffiliateSection">
                <form
                    className={`WpdFormWrapper ${updating ? 'WpdFormUpdating' : ''} ${
                        !profile ? 'is-updating' : ''
                    }`}
                    onSubmit={profileSubmit}
                >
                    <div className="WpdSectionTitleWrap">
                        <h4 className="WpdSectionTitle mr-2">
                            Change your Personal info
                        </h4>
                    </div>
                    <div className="row flex-wrap-reverse">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="WpdFormGroup">
                                        <label className="WpdFormLabel">
                                            First Name
                                        </label>
                                        <input
                                            name="first_name"
                                            type="text"
                                            onChange={e =>
                                                setField(
                                                    'first_name',
                                                    e.target?.value
                                                )
                                            }
                                            value={profileFields.first_name}
                                            className="form-control"
                                            placeholder="Enter Your First Name..."
                                        />
                                        {showError('first_name')}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="WpdFormGroup">
                                        <label className="WpdFormLabel">
                                            Last Name
                                        </label>
                                        <input
                                            name="last_name"
                                            type="text"
                                            onChange={e =>
                                                setField(
                                                    'last_name',
                                                    e.target?.value
                                                )
                                            }
                                            value={profileFields.last_name}
                                            className="form-control"
                                            placeholder="Enter Your Last Name..."
                                        />
                                        {showError('last_name')}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="WpdFormGroup">
                                        <label className="WpdFormLabel">
                                            Display Name
                                        </label>
                                        <input
                                            name="display_name"
                                            type="text"
                                            className="form-control"
                                            onChange={e =>
                                                setField(
                                                    'display_name',
                                                    e.target?.value
                                                )
                                            }
                                            value={profileFields.display_name}
                                            placeholder="Enter Your Display Name..."
                                        />
                                        {showError('display_name')}
                                    </div>
                                </div>
                                <div className="col-md-12 mb-0">
                                    <div className="WpdFormGroup">
                                        <label className="WpdFormLabel">
                                            Primary Email Address
                                        </label>
                                        <input
                                            name="email"
                                            type="email"
                                            onChange={e =>
                                                setField(
                                                    'email',
                                                    e.target?.value
                                                )
                                            }
                                            value={profileFields.email}
                                            className="form-control"
                                            placeholder="something@email.com"
                                        />
                                        {showError('email')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="WpdFormGroup">
                                <label className="WpdFormLabel">
                                    Profile Picture
                                </label>
                                <div className="WpdImageSelector">
                                    <div className="WpdImgBox">
                                        <img
                                            src={
                                                (profile &&
                                                    profile.gravatar &&
                                                    profile.gravatar) ||
                                                ImagePlaceholder
                                            }
                                            alt="profile"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                                <a
                                    href="https://en.gravatar.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="WpdLink mt-3"
                                >
                                    You can change your icon in en.gravatar.com
                                </a>
                            </div>
                        </div>
                    </div>
                    <button className="WpdButton WpdInfoButton WpdFilled mt-2">
                        <span className="WpdButtonInner">
                            {updating ? (
                                <>
                                    <LoadingButton>Loading</LoadingButton>
                                </>
                            ) : (
                                <span className="WpdText">Save Changes</span>
                            )}
                        </span>
                    </button>
                </form>
            </section>
        </div>
    );
};

export default Profile;
