import React, { useState } from 'react';
import { toast } from 'react-toastify';
import LoadingButton from '../../utils/LoadingButton';
import TwoFASettings from './TwoFASettings';

const Password = ({ saveProfile, updating, profile }) => {
	const [passwords, setPasswords] = useState({
		new_password: '',
		new_password_2: '',
	});

	const updateField = (e, name) => {
		setPasswords({ ...passwords, [name]: e.target.value });
	};

	const updatePassword = e => {
		e.preventDefault();
		if (!passwords.new_password.length) {
			toast.error('Please enter password');
			return;
		}
		if (!passwords.new_password_2.length) {
			toast.error('Please confirm password');
			return;
		}
		if (passwords.new_password !== passwords.new_password_2) {
			toast.error('Confirm password does not match, please check input.');
			return;
		}
		saveProfile({ ...passwords, type: 'password' });
	};

	return (
		<div className="WpdTabContent">
			<section className="WpdMyAffiliateSection">
				<form
					className={`WpdFormWrapper ${updating ? 'WpdFormUpdating' : ''}`}
					onSubmit={updatePassword}
				>
					<div className="WpdSectionTitleWrap">
						<h4 className="WpdSectionTitle mr-2">
							Change your Password
						</h4>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="WpdFormGroup">
								<label className="WpdFormLabel">
									New Password
								</label>
								<input
									type="password"
									className="form-control"
									placeholder="********"
									value={passwords.new_password}
									onChange={e =>
										updateField(e, 'new_password')
									}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="WpdFormGroup">
								<label className="WpdFormLabel">
									Confirm Password
								</label>
								<input
									type="password"
									className="form-control"
									placeholder="********"
									value={passwords.new_password_2}
									onChange={e =>
										updateField(e, 'new_password_2')
									}
								/>
							</div>
						</div>
					</div>
					<button className="WpdButton WpdInfoButton WpdFilled mt-2">
						<span className="WpdButtonInner">
							{updating ? (
								<>
									<LoadingButton>Loading</LoadingButton>
								</>
							) : (
								<span className="WpdText">Save Changes</span>
							)}
						</span>
					</button>
				</form>
			</section>
			<TwoFASettings profile={profile} />
		</div>
	);
};

export default Password;
