import { defaultApi } from '../utils/axiosApi';
import { all, takeLatest, put } from 'redux-saga/effects';
import {
    GET_COUNTRIES,
    GET_COUNTRIES_FAILED,
    GET_COUNTRIES_SUCCESS,
    GET_STATES,
    GET_STATES_FAILED,
    GET_STATES_SUCCESS,
} from '../actions/AddressActions';

export function* getCountries() {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/countries`;
    const { response, error } = yield defaultApi(endpoint, 'GET', {}, true);
    if (response) {
        yield put({
            type: GET_COUNTRIES_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_COUNTRIES_FAILED,
            error,
        });
    }
}

export function* countries() {
    yield takeLatest(GET_COUNTRIES, getCountries);
}

export function* getStates({ country }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/states`;
    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        { country },
        true
    );
    if (response) {
        yield put({
            type: GET_STATES_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_STATES_FAILED,
            error,
        });
    }
}

export function* states() {
    yield takeLatest(GET_STATES, getStates);
}

export default function* rootSaga() {
    yield all([countries(), states()]);
}
