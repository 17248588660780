import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    GET_PURCHASE_DATA,
    GET_PURCHASE_DATA_SUCCESS,
    GET_PURCHASE_DATA_FAILED,
} from '../actions/PurchaseHistoryActions';

const initialState = {
    purchaseHistory: undefined,
    loading: false,
};

export default function purchaseHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PURCHASE_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_PURCHASE_DATA_SUCCESS:
            return {
                ...state,
                purchaseHistory: action.response,
                loading: false,
            };
        case GET_PURCHASE_DATA_FAILED:
            toast.error('Something went wrong!');
            return {
                ...state,
                purchaseHistory: undefined,
                loading: false,
            };

        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
