import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../utils/ErrorMessage';
import { validateFormFields } from '../../utils/helper';

const ExistingUserRegistration = ({
    loading,
    profile,
    registerNewUser,
    registrationSuccess,
    getProfile,
}) => {
    const [formFields, setFormFields] = useState({
        affwp_user_name: '',
        affwp_user_login: '',
        affwp_user_email: '',
        affwp_payment_email: '',
        affwp_user_url: '',
        affwp_promotion_method: '',
        privacy_policy: false,
    });
    const [errors, setErrors] = useState({
        affwp_user_name: '',
        affwp_user_login: '',
        affwp_user_email: '',
        affwp_payment_email: '',
        affwp_user_url: '',
        affwp_promotion_method: '',
        privacy_policy: '',
    });

    useEffect(() => {
        if (profile) {
            setFormFields({
                ...formFields,
                affwp_user_name: profile.display_name,
                affwp_user_login: profile.user_login,
                affwp_user_email: profile.email,
            });
        }
    }, [profile]);

    useEffect(() => {
        if (registrationSuccess) {
            setFormFields({
                affwp_user_name: '',
                affwp_user_login: '',
                affwp_user_email: '',
                affwp_payment_email: '',
                affwp_user_url: '',
                affwp_promotion_method: '',
                privacy_policy: false,
            });
            setErrors({
                affwp_user_name: '',
                affwp_user_login: '',
                affwp_user_email: '',
                affwp_payment_email: '',
                affwp_user_url: '',
                affwp_promotion_method: '',
                privacy_policy: '',
            });
            getProfile();
        }
    }, [registrationSuccess]);

    const handleSubmit = e => {
        e.preventDefault();
        const validated = validateFormFields(formFields, getValidationRules());
        if (!validated.valid) {
            setErrors(validated.errors);
            return;
        }
        registerNewUser(formFields);
    };

    const getValidationRules = () => {
        return {
            affwp_payment_email: {
                email: {
                    value: true,
                    message: 'Payment email must be a valid email',
                },
            },
            affwp_user_url: {
                required: {
                    value: true,
                    message: 'Please insert your website url',
                },
            },
            affwp_promotion_method: {
                required: {
                    value: true,
                    message: 'Please tell how you will promote us',
                },
            },
            privacy_policy: {
                required: {
                    value: true,
                    message:
                        'Please agree to Terms and conditions & Privacy policy',
                },
            },
        };
    };

    return (
        <section
            className={`WpdMyProductSection ${loading ? 'is-loading' : ''}`}
        >
            <div className="WpdSectionTitleWrap">
                <h4 className="WpdSectionTitle mr-2">
                    Register a New Affiliate Account
                </h4>
            </div>
            <form
                className="WpdFormWrapper"
                onSubmit={handleSubmit}
            >
                <div className="row flex-wrap-reverse">
                    <div className="col-lg-6 col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="WpdFormGroup">
                                    <label className="WpdFormLabel">
                                        Payment Email
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${
                                            errors.affwp_payment_email &&
                                            errors.affwp_payment_email.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_payment_email:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors.affwp_payment_email}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="WpdFormGroup">
                                    <label className="WpdFormLabel">
                                        Website URL
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            errors.affwp_user_url &&
                                            errors.affwp_user_url.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_user_url: e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors.affwp_user_url}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="WpdFormGroup">
                                    <label className="WpdFormLabel">
                                        How will you promote us?
                                    </label>
                                    <textarea
                                        className={`form-control ${
                                            errors.affwp_promotion_method &&
                                            errors.affwp_promotion_method
                                                .length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_promotion_method:
                                                    e.target.value,
                                            })
                                        }
                                    ></textarea>
                                    <ErrorMessage
                                        error={errors.affwp_promotion_method}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <section className="WpdSectionPanel WpdMessageSection WpdKnowledgebaseMessage flex-column WpdSimpleInfoWrapper">
                            <div className="WpdSectionTitleWrap">
                                <h4 className="WpdSectionSemiTitle mr-2">
                                    Personal Info :
                                </h4>
                            </div>
                            <div className="WpdSimpleInfo">
                                <label
                                    htmlFor=""
                                    className="WpdSimpleInfoLabel"
                                >
                                    Full Name :
                                </label>
                                <span>{formFields.affwp_user_name}</span>
                            </div>
                            <div className="WpdSimpleInfo">
                                <label
                                    htmlFor=""
                                    className="WpdSimpleInfoLabel"
                                >
                                    Username :
                                </label>
                                <span>{formFields.affwp_user_login}</span>
                            </div>
                            <div className="WpdSimpleInfo">
                                <label
                                    htmlFor=""
                                    className="WpdSimpleInfoLabel"
                                >
                                    Account Email :
                                </label>
                                <span>{formFields.affwp_user_email}</span>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label className="WpdCheckbox">
                            <input
                                type="checkbox"
                                onChange={e =>
                                    setFormFields({
                                        ...formFields,
                                        privacy_policy: e.target.checked,
                                    })
                                }
                            />
                            <span className="WpdText">
                                I agree with the
                                <a
                                    href={`${process.env.REACT_APP_SITE_URL}/terms-and-conditions`}
                                    target="_blank"
                                >
                                    Terms & Conditions
                                </a>
                                and
                                <a
                                    href={`${process.env.REACT_APP_SITE_URL}/privacy-policy`}
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                            </span>
                        </label>
                        <ErrorMessage error={errors.privacy_policy} />
                    </div>
                </div>
                <button
                    className="WpdButton WpdInfoButton WpdFilled ml-auto"
                    type="submit"
                >
                    <span className="WpdButtonInner">
                        <span className="WpdText">Register</span>
                    </span>
                </button>
            </form>
        </section>
    );
};

export default ExistingUserRegistration;
