import React, { useEffect, useState } from 'react';
import Loader from '../../utils/Loader';
import SubscriptionItem from './SubscriptionItem';
import { Modal } from 'react-bootstrap';
import EmptyMessage from '../../utils/EmptyMessage';

const Subscription = ({
    getSubscriptionData,
    subscription,
    loading,
    cancelSubscription,
    updating,
}) => {
    const [updateItem, setUpdateItem] = useState(undefined);
    const [showConfirmation, setShowConfirmation] = useState(false);
    useEffect(() => {
        getSubscriptionData();
    }, [getSubscriptionData]);

    const handleUpdate = (id, status) => {
        setUpdateItem({ id: id, status });
        setShowConfirmation(true);
    };

    const closeConfirmation = () => {
        setShowConfirmation(false);
    };

    const handleSubscriptionUpdate = () => {
        if (updateItem.status === 'active') {
            cancelSubscription(updateItem.id, 'cancel');
        } else {
            cancelSubscription(updateItem.id, 'reactivate');
        }

        setUpdateItem(undefined);
        setShowConfirmation(false);
    };

    return (
        <>
            <section className="WpdMyProductSection">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">Subscriptions</h4>
                </div>
                {loading ? (
                    <Loader />
                ) : subscription && subscription.length > 0 ? (
                    <div className="WpdDataTableWrapper">
                        <table className="WpdDataTable">
                            <thead>
                                <tr>
                                    <th>Subscription</th>
                                    <th>Status</th>
                                    <th>Renewal Date</th>
                                    <th>Initial Amount</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subscription.map((item, index) => (
                                    <SubscriptionItem
                                        {...item}
                                        key={index}
                                        handleUpdate={handleUpdate}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <EmptyMessage>
                        Sorry, you have no subscriptions.
                    </EmptyMessage>
                )}
            </section>
            {(updateItem && (
                <Modal
                    show={showConfirmation}
                    onHide={closeConfirmation}
                    centered
                >
                    <Modal.Header
                        closeButton
                        className="d-flex justify-content-center text-center"
                    >
                        <Modal.Title>
                            {updateItem.status === 'active'
                                ? 'Cancel Subscription'
                                : 'Reactivate Subscription'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center text-center">
                        Are you sure you want to{' '}
                        {updateItem.status === 'active'
                            ? 'cancel'
                            : 'reactivate'}{' '}
                        subscription?
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className={`WpdButton WpdWarningButton ${
                                updateItem.status === 'active' && 'WpdFilled'
                            } ml-auto`}
                            data-dismiss="modal"
                            onClick={closeConfirmation}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">No</span>
                            </span>
                        </button>
                        <button
                            className={`WpdButton WpdSuccessButton ${
                                updateItem.status !== 'active' && 'WpdFilled'
                            } ml-3 mr-auto`}
                            onClick={handleSubscriptionUpdate}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">Yes</span>
                            </span>
                        </button>
                    </Modal.Footer>
                </Modal>
            )) ||
                ''}
        </>
    );
};

export default Subscription;
