import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
	SIGN_IN,
	SIGN_IN_SUCCESS,
	SIGN_IN_FAILURE,
	SIGN_IN_VALIDATION_FAILED,
	ACTIVE_COMPANY_NOT_FOUND,
	VERIFY_2FA,
	CONFIGURE_2FA,
	CONFIGURE_2FA_FAILED,
	CONFIGURE_2FA_SUCCESS,
	REQUIRED_2FA,
	VERIFY_2FA_FAILED,
	SETUP_2FA,
	DOWNLOAD_2FA_CODES,
	DOWNLOAD_2FA_CODES_GET,
	GET_SETUP_2FA,
	GET_DOWNLOAD_2FA_CODES,
	GET_DOWNLOAD_2FA_CODES_SUCCESS,
	DISABLE_2FA_METHOD,
} from '../actions/SigninActions';
import { defaultApi } from '../utils/axiosApi';
import { downloadItem } from '../utils/helper';
import { toast } from 'react-toastify';

export function* signinAttempt({ credential }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/signin`;

	const { response, error } = yield defaultApi(
		endpoint,
		'POST',
		{ ...credential, from_app: true },
		true
	);
	if (response) {
		if (response.hasOwnProperty('setup_2fa') && response.setup_2fa) {
			yield put({
				type: SETUP_2FA,
				data: response,
			});
		} else {
			if (
				response.hasOwnProperty('required_2fa') &&
				response.required_2fa
			) {
				yield put({
					type: REQUIRED_2FA,
					data: response,
				});
			} else {
				yield put({
					type: SIGN_IN_SUCCESS,
					credentials: response,
				});
			}
		}
	} else if (error) {
		const data = error.data;
		if (data.status === 'validation_error') {
			yield put({
				type: SIGN_IN_VALIDATION_FAILED,
				message: data.message,
				errors: data.data,
			});
		} else if (data.status === 'company-not-found') {
			yield put({
				type: ACTIVE_COMPANY_NOT_FOUND,
				message: data.message,
				data: data.data,
			});
		} else {
			yield put({
				type: SIGN_IN_FAILURE,
				message: data.message,
			});
		}
	} else {
		yield put({
			type: SIGN_IN_FAILURE,
			message: 'Something went wrong!',
		});
	}
}

export function* signin() {
	yield takeLatest(SIGN_IN, signinAttempt);
}

export function* verfirySignIn({ user_id, code, method, mode = '' }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/verify-2fa`;

	const { response, error } = yield defaultApi(
		endpoint,
		'POST',
		{ user_id, code, method, mode },
		true
	);

	if (response) {
		if (response.setup_2fa === '3') {
			yield put({
				type: DOWNLOAD_2FA_CODES,
				response,
			});
		} else {
			yield put({
				type: SIGN_IN_SUCCESS,
				credentials: response,
			});
		}
	} else if (error) {
		const data = error.data;
		yield put({
			type: VERIFY_2FA_FAILED,
			message: data.message,
		});
	} else {
		yield put({
			type: VERIFY_2FA_FAILED,
			message: 'Something went wrong!',
		});
	}
}
export function* verify() {
	yield takeLatest(VERIFY_2FA, verfirySignIn);
}

export function* configure2Fa({ key, method }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/configure-2fa`;
	const { response, error } = yield defaultApi(
		endpoint,
		'POST',
		{ nonceKey: key, method },
		true
	);
	if (response) {
		if (response.hasOwnProperty('required_2fa') && response.required_2fa) {
			yield put({
				type: CONFIGURE_2FA_SUCCESS,
				data: { ...response },
			});
		} else {
			yield put({
				type: CONFIGURE_2FA_FAILED,
				data: response,
			});
		}
	} else {
		yield put({
			type: CONFIGURE_2FA_FAILED,
			data: { message: 'Something went wrong!' },
		});
	}
}

export function* disable2Fa({ method }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/disable-2fa`;
	const { response, error } = yield defaultApi(
		endpoint,
		'POST',
		{ method },
		true
	);

	const profile = yield select(state => {
		return state.profile.profile;
	});

	if (response) {
		yield put({
			type: SIGN_IN_SUCCESS,
			credentials: { ...profile, twoFA: { totp: false, email: false } },
		});
		yield put({
			type: SETUP_2FA,
			data: { ...response },
		});
	}
}

export function* backupCodesDownload() {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/download-backup-codes`;
	const { response, error } = yield defaultApi(endpoint, 'GET', {}, true);

	if (response) {
		downloadItem('store.wpdeveloper.com-backup-codes.txt', response.codes);
		toast.success('Your recovery code is downloaded.');
		yield put({
			type: SIGN_IN_SUCCESS,
			credentials: response.user,
		});
	}
}

export function* getBackupCodesDownload() {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get-backup-codes`;
	const { response, error } = yield defaultApi(endpoint, 'GET', {}, true);

	if (response) {
		yield put({
			type: GET_DOWNLOAD_2FA_CODES_SUCCESS,
			response,
		});
	}
}

export function* getQrHandler() {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/totp-setup-key`;
	const { response, error } = yield defaultApi(endpoint, 'GET', {}, true);

	if (response) {
		if (response.hasOwnProperty('setup_2fa') && response.setup_2fa) {
			yield put({
				type: CONFIGURE_2FA_SUCCESS,
				data: { ...response },
			});
		} else {
			if (
				response.hasOwnProperty('required_2fa') &&
				response.required_2fa
			) {
				yield put({
					type: REQUIRED_2FA,
					data: response,
				});
			}
		}
	}
}

export function* configure() {
	yield takeLatest(CONFIGURE_2FA, configure2Fa);
	yield takeLatest(DISABLE_2FA_METHOD, disable2Fa);
}
export function* backupCodes() {
	yield takeLatest(DOWNLOAD_2FA_CODES_GET, backupCodesDownload);
	yield takeLatest(GET_DOWNLOAD_2FA_CODES, getBackupCodesDownload);
}

export function* getQrCode() {
	yield takeLatest(GET_SETUP_2FA, getQrHandler);
}

export default function* rootSaga() {
	yield all([signin(), verify(), configure(), backupCodes(), getQrCode()]);
}
