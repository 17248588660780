import Logo from '../../Markup/assets/img/WpdLogoWithText.svg';
import React from 'react';

const CheckoutHeader = () => (
    <header className="WpdCheckoutHeader WpdFilled">
        <div className="WpdCheckoutHeaderWrapper container-fluid">
            <span className="WpdCompanyLogo">
                <img
                    src={Logo}
                    alt="WPDeveloper"
                />
            </span>
        </div>
    </header>
);

export default CheckoutHeader;
