import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { parse } from 'query-string';

const UpgradeLicense = ({ history, getUpgrades, loading, upgrades }) => {
    const linkHistory = useHistory();
    const goToPreviousPath = () => {
        linkHistory.goBack();
    };

    useEffect(() => {
        const data = parse(history.location.search);
        if (data.license_id) {
            getUpgrades(data.license_id);
        }
    }, []);

    return (
        <>
            <section className="WpdMyProductSection">
                <div className="WpdSectionTitleWrap">
                    <button
                        onClick={goToPreviousPath}
                        className="WpdBackButton"
                    >
                        <span className="WpdIcon">
                            <i className="wpd-icon wpd-angle-left"></i>
                        </span>
                    </button>
                    <h4 className="WpdSectionTitle mr-2">Upgrade License</h4>
                </div>
                {loading ? (
                    <Loader />
                ) : upgrades ? (
                    <div className="WpdDataTableWrapper">
                        <table className="WpdDataTable">
                            <thead>
                                <tr>
                                    <th>Download</th>
                                    <th>Upgrade Cost</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {upgrades.map((upgrade, index) => (
                                    <tr key={index}>
                                        <td>
                                            <p className="WpdFont18">
                                                {upgrade.name}
                                            </p>
                                        </td>
                                        <td>
                                            <label>Upgrade Cost: </label>
                                            <p className="WpdFont18">
                                                $ {upgrade.cost}{' '}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                <a
                                                    href={upgrade.link}
                                                    className="WpdSmallButton WpdInfoButton text-center"
                                                >
                                                    <span className="WpdButtonInner">
                                                        <span className="WpdText">
                                                            Upgrade License
                                                        </span>
                                                    </span>
                                                </a>
                                            </p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <EmptyMessage>No upgrades found</EmptyMessage>
                )}
            </section>
        </>
    );
};

export default UpgradeLicense;
