import { connect } from 'react-redux';
import Download from '../components/Download/Download';
import { downloadProduct, getDownloads } from '../actions/DownloadActions';
const mapStateToProps = state => {
    return {
        downloads: state.downloads.all,
        loading: state.downloads.loading,
        downloadItem: state.downloads.downloadItem,
        downloading: state.downloads.downloading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getDownloads: () => dispatch(getDownloads()),
        downloadProduct: data => dispatch(downloadProduct(data)),
    };
};

const DownloadContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Download);

export default DownloadContainer;
