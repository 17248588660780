import { connect } from 'react-redux';
import {
	addToCart,
	addToCartUpsell,
	getCartData,
	processCheckout,
	recaptureCart,
	recaptureTrack,
	removeFromCart,
	sendCouponData,
	sendEmailData,
} from '../actions/CheckoutActions';
import Checkout from '../components/Checkout';
import { getCountries, getStates } from '../actions/AddressActions';
import { setAutoLoggedInUser } from '../actions/SigninActions';
import { getProfile } from '../actions/ProfileAction';

const mapStateToProps = state => {
	return {
		cartData: state.checkout.cartData,
		coupon: state.checkout.coupon,
		signin: state.auth.signin,
		checkoutUrl: state.checkout.checkoutUrl,
		loading: state.checkout.loading,
		processingCheckout: state.checkout.processingCheckout,
		couponProcessing: state.checkout.couponProcessing,
		cartLoading: state.checkout.cartLoading,
		countries: state.address.countries,
		states: state.address.states,
		loadingCountries: state.address.loadingCountries,
		loadingStates: state.address.loadingStates,
		profile: state.profile.profile,
		licenseRenewing: state.checkout.licenseRenewing,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addToCart: (data, separateLoading = false, splitPayment = false) =>
			dispatch(addToCart(data, separateLoading, splitPayment)),
		getCartData: () => dispatch(getCartData()),
		addToCartUpsell: (products, coupon) =>
			dispatch(addToCartUpsell(products, coupon)),
		sendCouponData: value => dispatch(sendCouponData(value)),
		removeFromCart: value => dispatch(removeFromCart(value)),
		processCheckout: data => dispatch(processCheckout(data)),
		getCountries: () => dispatch(getCountries()),
		getStates: country => dispatch(getStates(country)),
		getProfile: () => dispatch(getProfile()),
		setAutoLoggedInUser: user => dispatch(setAutoLoggedInUser(user)),
		sendEmailData: email => dispatch(sendEmailData(email)),
		recaptureCart: data => dispatch(recaptureCart(data)),
		recaptureTrack: data => dispatch(recaptureTrack(data)),
	};
};

const CheckoutContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Checkout);

export default CheckoutContainer;
