import { connect } from 'react-redux';
import { getPurchaseHistory } from '../actions/PurchaseHistoryActions';
import PurchaseHistory from '../components/PurchaseHistory/PurchaseHistory';
import { downloadProduct } from '../actions/DownloadActions';

const mapStateToProps = state => {
    return {
        purchaseHistory: state.purchaseHistory.purchaseHistory,
        loading: state.purchaseHistory.loading,
        downloading: state.downloads.downloading,
        downloadItem: state.downloads.downloadItem,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPurchaseHistory: () => dispatch(getPurchaseHistory()),
        download: data => dispatch(downloadProduct(data)),
    };
};

const PurchaseHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseHistory);

export default PurchaseHistoryContainer;
