import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import WpiLogo from '../../Markup/assets/img/WpdLogoWithText.svg';
import Banner from '../../Markup/assets/img/login.svg';
import { validateFormFields } from '../../utils/helper';
import ErrorMessage from '../../utils/ErrorMessage';

const SendForgetPassLink = ({
    userForgetPass,
    forgetPassLink,
    loading,
    resetForgetPassword,
}) => {
    const linkHistory = useHistory();
    const goToPreviousPath = () => {
        linkHistory.goBack();
    };
    const [err, setErr] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    let email;

    useEffect(() => {
        if (forgetPassLink) {
            setErr({});
            setShowMessage(true);
            email.value = '';
            resetForgetPassword();
        }
    }, [forgetPassLink]);

    const submitSendForgetPassLinkForm = e => {
        e.preventDefault();
        setShowMessage(false);
        const validated = validateFormFields(
            {
                email: email.value,
            },
            {
                email: {
                    required: {
                        value: true,
                        message: 'Email is required',
                    },
                    email: {
                        value: true,
                        message: 'Please insert a valid email',
                    },
                },
            }
        );
        if (!validated.valid) {
            setErr(validated.errors);
            return;
        }
        setErr({});
        userForgetPass(email.value);
    };

    return (
        <div className={`WpdPageBody WpdLoginBody`}>
            <div className="WpdLoginHeader">
                <Link
                    to={'/auth/sign-in'}
                    className={`WpdSiteLogo`}
                >
                    <img
                        src={WpiLogo}
                        alt=""
                    />
                </Link>
            </div>
            <div className="WpdLoginWrapper">
                <div className="WpdLoginContent">
                    <div
                        className={`WpdLoginForm ${loading ? 'is-loading' : ''}`}
                    >
                        {/*<form onSubmit={submitSendForgetPassLinkForm}>*/}
                        <button
                            onClick={goToPreviousPath}
                            className="WpdBackButton mb-4"
                        >
                            <div className="WpdIcon">
                                <i className="wpd-icon wpd-angle-left"></i>
                            </div>
                            <div className="WpdText">Back to Login</div>
                        </button>
                        <h2>Forgot Password?</h2>
                        {showMessage && (
                            <div className="alert alert-success">
                                A reset password link have been sent to your
                                email, please check your email.
                            </div>
                        )}
                        <div className="WpdFormGroup mb-4">
                            <label className="WpdFormLabel">
                                Email Address
                            </label>
                            <input
                                type="email"
                                className={`form-control ${err.email ? 'WpdHasError' : ''}`}
                                placeholder="Example@example.com"
                                id="email"
                                autoFocus={true}
                                ref={node => {
                                    email = node;
                                }}
                            />
                            {err.email && <ErrorMessage error={err.email} />}
                        </div>
                        <button
                            type="button"
                            className="WpdButton WpdInfoButton WpdFilled w-100 mt-2"
                            onClick={submitSendForgetPassLinkForm}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">
                                    {loading
                                        ? 'Sending Password Reset Link...'
                                        : 'Send Password Reset Link'}
                                </span>
                            </span>
                        </button>
                        {/*</form>*/}
                    </div>
                </div>
                <div className="WpdLoginImage">
                    <img
                        src={Banner}
                        alt=""
                        className="w-100 img-fluid"
                    />
                </div>
            </div>
        </div>
    );
};

SendForgetPassLink.prototypes = {
    forgetPassLink: PropTypes.shape({
        redirect: PropTypes.bool,
    }),
};

export default SendForgetPassLink;
