import React, { useEffect, useState } from 'react';
import LoadingButton from '../../utils/LoadingButton';
import Select from 'react-select';
import BillingMethod from './BillingMethod';
import { trimFormFields, validateFormFields } from '../../utils/helper';
import { removeCard } from '../../actions/ProfileAction';

const Billing = ({
    saveProfile,
    profile,
    updating,
    getCountries,
    loadingCountries,
    countries,
    getStates,
    loadingStates,
    states,
    addCard,
    removeCard,
    setDefaultCard,
    addCardLoading,
    addCardUrl,
    cardLoading,
    newDefault,
    removedCard,
}) => {
    const [billingInfo, setBillingInfo] = useState({
        line1: '',
        line2: '',
        country: '',
        zip: '',
        state: '',
        city: '',
    });
    const [countryOptions, setCountryOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [disabledState, setDisabledState] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [hasSavedCountry, setHasSavedCountry] = useState(undefined);
    const [hasSavedState, setHasSavedState] = useState(undefined);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (profile && profile.address) {
            const { country, state } = profile.address;
            if (country) {
                loadCountries();
                setHasSavedCountry(country);
                if (state) {
                    getStates(country);
                    setHasSavedState(state);
                }
            }
            setBillingInfo({
                ...billingInfo,
                ...profile.address,
                country,
                state,
            });
        }
    }, [profile]);

    useEffect(() => {
        if (countries && hasSavedCountry) {
            const saved = countries.filter(
                c => c.value.toString() === hasSavedCountry
            );
            setSelectedCountry(saved);
        }
    }, [hasSavedCountry]);

    useEffect(() => {
        if (countries) {
            setCountryOptions(countries);
            if (hasSavedCountry) {
                const saved = countries.filter(
                    c => c.value.toString() === hasSavedCountry
                );
                setSelectedCountry(saved);
            }
        }
    }, [countries]);

    useEffect(() => {
        if (states && states.length) {
            setDisabledState(false);
            setStateOptions(states);
            if (hasSavedState) {
                const saved = states.filter(
                    s => s.value.toString() === hasSavedState
                );
                setSelectedState(saved);
            }
        }
    }, [states]);

    const loadCountries = () => {
        !countries && getCountries();
    };

    const updateField = (e, name) => {
        setBillingInfo({ ...billingInfo, [name]: e.target.value });
    };
    const saveBillingInfo = e => {
        e.preventDefault();
        const validated = validateFormFields(billingInfo, getValidationRules());
        if (!validated.valid) {
            setFormErrors(validated.errors);
            return;
        }
        saveProfile({ ...trimFormFields(billingInfo), type: 'address' });
        setFormErrors({});
    };

    const getValidationRules = () => {
        const rules = {
            country: {
                required: true,
            },
            line1: {
                required: {
                    value: true,
                    message: 'Please insert billing address',
                },
                maxLength: {
                    value: 255,
                    message:
                        'Billing address should not be more than 255 characters',
                },
            },
            line2: {
                maxLength: {
                    value: 255,
                    message:
                        'Billing address line 2 should not be more than 255 characters',
                },
            },
            city: {
                required: {
                    value: true,
                    message: 'Please insert city',
                },
                maxLength: {
                    value: 60,
                    message: 'City should not be more than 60 characters',
                },
            },
            zip: {
                required: {
                    value: true,
                    message: 'Please insert zip/postal code',
                },
                maxLength: {
                    value: 24,
                    message:
                        'Zip/postal code should not be more than 24 characters',
                },
            },
        };
        if (stateOptions.length) {
            rules.state = {
                required: true,
                message: 'Please select state',
            };
        }
        return rules;
    };
    const handleCountryChange = selected => {
        if (selected && selected.value?.length) {
            setSelectedState({});
            setDisabledState(true);
            getStates(selected.value);
            setSelectedCountry(selected);
            setBillingInfo({
                ...billingInfo,
                country: selected.value,
                state: '',
            });
        } else {
            setBillingInfo({
                ...billingInfo,
                country: '',
                state: '',
            });
            setSelectedCountry(undefined);
        }
        setFormErrors({ ...formErrors, state: '' });
        setDisabledState(true);
        setStateOptions([]);
    };

    const handleStateChange = selected => {
        if (selected && selected.value?.length) {
            setSelectedState(selected);
            setBillingInfo({ ...billingInfo, state: selected.value });
        } else {
            setBillingInfo({ ...billingInfo, state: '' });
            setSelectedState(undefined);
        }
    };

    const showError = name => {
        if (formErrors.hasOwnProperty(name) && formErrors[name].length) {
            return <p className="WpdErrorMessage">{formErrors[name]}</p>;
        }
        return '';
    };

    return (
        <div className="WpdTabContent">
            <section className="WpdMyAffiliateSection">
                <form
                    className={`WpdFormWrapper ${updating ? 'WpdFormUpdating' : ''} ${
                        !profile ? 'is-updating' : ''
                    }`}
                    onSubmit={saveBillingInfo}
                >
                    <div className="WpdSectionTitleWrap">
                        <h4 className="WpdSectionTitle mr-2">
                            Change your Billing Address
                        </h4>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="WpdFormGroup">
                                <label className="WpdFormLabel">Line 1</label>
                                <input
                                    type="text"
                                    name="line1"
                                    className="form-control"
                                    value={billingInfo.line1}
                                    onChange={e => updateField(e, 'line1')}
                                />
                                {showError('line1')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="WpdFormGroup">
                                <label className="WpdFormLabel">Line 2</label>
                                <input
                                    type="text"
                                    name="line2"
                                    className="form-control"
                                    value={billingInfo.line2}
                                    onChange={e => updateField(e, 'line2')}
                                />
                                {showError('line2')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="WpdFormGroup">
                                <label className="WpdFormLabel">
                                    Zip / Postal Code
                                </label>
                                <input
                                    type="text"
                                    name="zip"
                                    onChange={e => updateField(e, 'zip')}
                                    className="form-control"
                                    value={billingInfo.zip}
                                />
                                {showError('zip')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="WpdFormGroup">
                                <label className="WpdFormLabel">Country</label>
                                <Select
                                    options={countryOptions}
                                    onMenuOpen={loadCountries}
                                    isLoading={loadingCountries}
                                    onChange={handleCountryChange}
                                    isSearchable={true}
                                    isClearable={true}
                                    classNamePrefix="WpdSelect"
                                    value={selectedCountry}
                                />
                                {showError('country')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="WpdFormGroup">
                                <label className="WpdFormLabel">
                                    State / Province
                                </label>
                                <Select
                                    options={stateOptions}
                                    isLoading={loadingStates}
                                    onChange={handleStateChange}
                                    value={selectedState}
                                    isSearchable={true}
                                    isClearable={true}
                                    isDisabled={disabledState}
                                    classNamePrefix="WpdSelect"
                                />
                                {showError('state')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="WpdFormGroup">
                                <label className="WpdFormLabel">City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="state"
                                    value={billingInfo.city}
                                    onChange={e => updateField(e, 'city')}
                                />
                                {showError('city')}
                            </div>
                        </div>
                    </div>
                    <button className="WpdButton WpdInfoButton WpdFilled mt-2">
                        <span className="WpdButtonInner">
                            {updating ? (
                                <>
                                    <LoadingButton>Loading</LoadingButton>
                                </>
                            ) : (
                                <span className="WpdText">Save Changes</span>
                            )}
                        </span>
                    </button>
                </form>
            </section>
            <BillingMethod
                profile={profile}
                addCard={addCard}
                removeCard={removeCard}
                setDefaultCard={setDefaultCard}
                loading={cardLoading}
                addCardLoading={addCardLoading}
                addCardUrl={addCardUrl}
                newDefault={newDefault}
                removedCard={removedCard}
            />
        </div>
    );
};

export default Billing;
