import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from '../utils/axiosApi';
import {
    GET_LICENSE_DATA,
    GET_LICENSE_DATA_FAILED,
    GET_LICENSE_DATA_SUCCESS,
} from '../actions/ManageLicenseActions';

export function* getLicenseData({ paymentId }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/history`;

    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        { payment_id: paymentId },
        true
    );
    if (response) {
        yield put({
            type: GET_LICENSE_DATA_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_LICENSE_DATA_FAILED,
            error,
        });
    }
}

export function* getLicenses() {
    yield takeLatest(GET_LICENSE_DATA, getLicenseData);
}

export default function* rootSaga() {
    yield all([getLicenses()]);
}
