import { defaultApi } from '../utils/axiosApi';
import { all, takeLatest, put } from 'redux-saga/effects';
import {
    CREATE_TICKET,
    CREATE_TICKET_FAILED,
    CREATE_TICKET_SUCCESS,
    GET_TICKETS,
    GET_TICKETS_FAILED,
    GET_TICKETS_SUCCESS,
} from '../actions/MyTicketsActions';

export function* getTickets() {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/tickets`;
    const { response, error } = yield defaultApi(endpoint, 'GET', {}, true);
    if (response) {
        yield put({
            type: GET_TICKETS_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_TICKETS_FAILED,
            error,
        });
    }
}

export function* tickets() {
    yield takeLatest(GET_TICKETS, getTickets);
}

export function* createTicket({ data }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/tickets`;
    const { response, error } = yield defaultApi(
        endpoint,
        'POST',
        data,
        true,
        true
    );

    if (response) {
        yield put({
            type: CREATE_TICKET_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: CREATE_TICKET_FAILED,
            error,
        });
    }
}

export function* create() {
    yield takeLatest(CREATE_TICKET, createTicket);
}

export default function* rootSaga() {
    yield all([tickets(), create()]);
}
