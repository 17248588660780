import { connect } from 'react-redux';
import Registration from '../components/Affiliate/Registration';
import { registerNewUser } from '../actions/AffiliateActions';
const mapStateToProps = state => {
    return {
        loading: state.affiliate.loading,
        registrationSuccess: state.affiliate.registrationSuccess,
        registrationErrors: state.affiliate.registrationErrors,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        registerNewUser: data => dispatch(registerNewUser(data)),
    };
};

const AffiliateRegistrationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Registration);

export default AffiliateRegistrationContainer;
