import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from '../utils/axiosApi';
import {
    DOWNLOAD_PRODUCT,
    DOWNLOAD_PRODUCT_FAILED,
    DOWNLOAD_PRODUCT_SUCCESS,
    GET_DOWNLOADS,
    GET_DOWNLOADS_FAILED,
    GET_DOWNLOADS_SUCCESS,
} from '../actions/DownloadActions';
import { downloadItem, ucFirst } from '../utils/helper';

export function* getDownloads() {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/downloads`;

    const { response, error } = yield defaultApi(endpoint, 'GET', {}, true);
    if (response) {
        yield put({
            type: GET_DOWNLOADS_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_DOWNLOADS_FAILED,
            error,
        });
    }
}

export function* downloads() {
    yield takeLatest(GET_DOWNLOADS, getDownloads);
}

export function* downloadProduct({ data }) {
    const { response, error } = yield defaultApi(
        `${process.env.REACT_APP_API_BASE_URL}/downloads`,
        'POST',
        data,
        true,
        false,
        {
            responseType: 'blob',
        },
        true
    );
    if (response) {
        downloadItem(data.download_name, response);
        yield put({
            type: DOWNLOAD_PRODUCT_SUCCESS,
        });
    }
    if (error) {
        yield put({
            type: DOWNLOAD_PRODUCT_FAILED,
            error,
        });
    }
}

export function* download() {
    yield takeLatest(DOWNLOAD_PRODUCT, downloadProduct);
}

export default function* rootSaga() {
    yield all([downloads(), download()]);
}
