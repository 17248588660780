import React from 'react';
import Form from 'react-bootstrap/Form';
import { maskEmail } from '../../../utils/helper';
import BackButton from './BackButton';
import { useSelector } from 'react-redux';

const Configure = ({
	userEmail,
	configure2Fa,
	setup2fakey,
	onSubmit,
	setView,
}) => {
	const isLoggedIn = useSelector(state => state.profile?.profile);

	return (
		<div className="Wpd2FAForm">
			{!isLoggedIn && (
				<BackButton
					goto="signin"
					setView={setView}
				/>
			)}

			<div className="Wpd2FAHeadingWrapper">
				<div className="Wpd2FAHeadingIcon">
					<i className="wpd-icon wpd-key-2"></i>
				</div>
				<h3 className="Wpd2FAHeadingTitle">
					Setup Two-Factor Authentication
				</h3>
				<h5 className="Wpd2FAHeadingSubTitle">
					Configure the Authenticator app to generate codes
				</h5>
				<p className="Wpd2FAHeadingInfo">
					Authenticator app can generate one-time verification codes
					for sites and apps that support Authenticator app 2-Step
					Verification
				</p>
			</div>
			<div className="Wpd2FAFormBody">
				<Form
					onSubmit={e => {
						e.preventDefault();
						onSubmit('totp');
						configure2Fa(setup2fakey, 'totp');
					}}
				>
					<button
						type="submit"
						className="WpdButton WpdInfoButton WpdFilled mt-4.5"
					>
						<span className="WpdButtonInner">
							<span className="WpdText">
								Configure Authenticator
							</span>
						</span>
					</button>
				</Form>
				<div
					className="Wpd2FAFormDivider WpdWithText"
					data-text="OR"
				/>
				<div className="Wpd2FAFormBodyLink">
					<Form
						onSubmit={e => {
							e.preventDefault();
							onSubmit('email');
							configure2Fa(setup2fakey);
						}}
					>
						<button type="submit">
							Configure 2FA with your{' '}
							<strong>{maskEmail(userEmail)}</strong>
						</button>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default Configure;
