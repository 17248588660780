import React, { useEffect } from 'react';
import { handleCopyToClipBoard } from '../../utils/helper';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import TooltipComponent from '../../utils/TooltipComponent';

const Creatives = ({ loading, affiliateInfo, getCreatives, creatives }) => {
    useEffect(() => {
        if (affiliateInfo && affiliateInfo.id) {
            getCreatives(affiliateInfo.id);
        }
    }, []);

    const ReferralSection = ({ id, image, name, text, url }) => (
        <div className={'WpdAffiliateCreativeWrapper col-12 col-md-6'}>
            <div className="WpdAffiliateCreativeItem">
                <div className="WpdAffiliateTitle">
                    <h4>{text}</h4>
                </div>
                <div className="d-block w-100">
                    <div className="WpdAffiliateCreativesThumbnail">
                        <img
                            src={image}
                            alt={name}
                        />
                    </div>
                </div>
                <div className="d-block w-100">
                    <div className="WpdFormGroup">
                        <label className="WpdFormLabel mt-2">
                            Copy and paste the following code:
                        </label>
                        <div className="WpdPreText">
                            <span id={`creative-link-${id}`}>
                                {generateCode({ name, text, image, url })}
                            </span>
                            <TooltipComponent tooltipText="Click to copy code">
                                <i
                                    className="wpd-icon wpd-copy"
                                    onClick={e =>
                                        handleCopyToClipBoard(
                                            `creative-link-${id}`,
                                            'Code copied'
                                        )
                                    }
                                ></i>
                            </TooltipComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    const generateCode = ({ text, name, image, url }) =>
        `<a href="${url}?via=${affiliateInfo.id}" title="${text}" target="_blank"><img src="${image}" alt="${name}"/></a>`;

    return (
        <div className="WpdTabContent">
            <section className="WpdMyAffiliateSection">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">Creatives</h4>
                </div>
                {loading ? (
                    <Loader />
                ) : creatives && creatives.data && creatives.data.length ? (
                    <div className={'row WpdHasColGap'}>
                        {creatives.data.map((item, index) => (
                            <ReferralSection
                                key={index}
                                {...item}
                            />
                        ))}
                    </div>
                ) : (
                    <EmptyMessage>No creatives found</EmptyMessage>
                )}
            </section>
        </div>
    );
};

export default Creatives;
