import React, { useEffect, useState } from 'react';
import AmexIcon from '../../Markup/assets/img/payment-methods/amex.png';
import VisaIcon from '../../Markup/assets/img/payment-methods/visa.png';
import MasterIcon from '../../Markup/assets/img/payment-methods/mastercard.png';
import DinersIcon from '../../Markup/assets/img/payment-methods/diners.png';
import DiscoverIcon from '../../Markup/assets/img/payment-methods/discover.png';
import JcbIcon from '../../Markup/assets/img/payment-methods/jcb.png';
import UnionpayIcon from '../../Markup/assets/img/payment-methods/unionpay.png';
import DefaultIcon from '../../Markup/assets/img/payment-methods/card.png';
import TooltipComponent from '../../utils/TooltipComponent';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const BillingMethod = ({
    profile,
    addCard,
    setDefaultCard,
    removeCard,
    loading,
    addCardUrl,
    addCardLoading,
    newDefault,
    removedCard,
}) => {
    const [cards, setCards] = useState([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [cardToRemove, setCardToRemove] = useState(undefined);

    const [showDefaultModal, setShowDefaultModal] = useState(false);
    const [cardToDefault, setCardToDefault] = useState(undefined);

    useEffect(() => {
        if (profile && profile.cards && profile.cards.length) {
            const all = profile.cards.filter(card => card.default);
            setCards([...all, ...profile.cards.filter(card => !card.default)]);
        }
    }, [profile]);

    useEffect(() => {
        if (addCardUrl) {
            window.location = addCardUrl;
        }
    }, [addCardUrl]);

    useEffect(() => {
        if (newDefault) {
            cards.length &&
                setCards(
                    cards.map(card => {
                        card.source.id === newDefault
                            ? (card.default = true)
                            : (card.default = false);
                        return card;
                    })
                );
            setShowDefaultModal(false);
            setCardToDefault(undefined);
        }
    }, [newDefault]);

    useEffect(() => {
        if (removedCard) {
            cards.length &&
                setCards(cards.filter(card => card.source.id !== removedCard));
            setShowRemoveModal(false);
        }
    }, [removedCard]);

    const setAsDefault = e => {
        e.preventDefault();
        if (!cardToDefault.default) {
            setDefaultCard(cardToDefault.source.id);
        }
    };
    const cardRemove = (e, card) => {
        e.preventDefault();
        if (card.default) {
            toast.warn(
                'You can not remove a default card. Please set another card as default to continue billing.'
            );
            return;
        }
        if (cards.length > 1) {
            setShowRemoveModal(true);
            setCardToRemove(card);
        }
    };

    const getCardIcon = brand => {
        switch (brand.toString().toLowerCase()) {
            case 'amex':
                return AmexIcon;
            case 'mastercard':
                return MasterIcon;
            case 'visa':
                return VisaIcon;
            case 'diners':
                return DinersIcon;
            case 'discover':
                return DiscoverIcon;
            case 'jcb':
                return JcbIcon;
            case 'unionpay':
                return UnionpayIcon;
            default:
                return DefaultIcon;
        }
    };

    const closeConfirmation = () => {
        setShowRemoveModal(false);
        setCardToRemove(undefined);
    };

    const handleRemoveCard = e => {
        e.preventDefault();
        removeCard(cardToRemove.source.id);
    };
    const cardDefault = (e, card) => {
        e.preventDefault();
        setShowDefaultModal(true);
        setCardToDefault(card);
    };
    const closeDefaultConfirmation = () => {
        setShowDefaultModal(false);
        setCardToDefault(undefined);
    };

    return (
        <section className="WpdMyAffiliateSection">
            <div className={`WpdFormWrapper`}>
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">
                        Change your Payment Method
                    </h4>
                </div>
                <div
                    className={`WpdBillingMethodWrapper ${loading ? 'is-loading' : ''}`}
                >
                    {cards.map((card, index) => (
                        <div
                            className="WpdBillingMethod"
                            key={index}
                        >
                            <div className="WpdController">
                                <TooltipComponent
                                    tooltipText={
                                        card.default
                                            ? 'Current default card for billing'
                                            : 'Set as default'
                                    }
                                >
                                    <label className="WpdCardChecker">
                                        <input
                                            type="checkbox"
                                            checked={card.default}
                                            onChange={e => cardDefault(e, card)}
                                        />
                                        <span className="WpdText"></span>
                                    </label>
                                </TooltipComponent>
                                {!card.default && (
                                    <TooltipComponent
                                        tooltipText={'Remove card'}
                                    >
                                        <button
                                            className={`WpdIconButton WpdOnlyIcon WpdColorBorder WpdHoverColorDanger`}
                                            onClick={e => cardRemove(e, card)}
                                        >
                                            <span
                                                className={`wpd-icon wpd-delete`}
                                            />
                                        </button>
                                    </TooltipComponent>
                                )}
                            </div>
                            <img
                                src={getCardIcon(card.source.brand)}
                                alt="image"
                                className="WpdIcon"
                            />
                            <span className="WpdLabel">Card Number</span>
                            <span className="WpdNumberWrapper">
                                <span className="WpdDotts"></span>
                                <span className="WpdDotts"></span>
                                <span className="WpdDotts"></span>
                                <span className="WpdNumber">
                                    {card.source.last4}
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
                <button
                    className={`WpdAddBillingMethod ${
                        addCardLoading ? 'is-loading' : ''
                    }`}
                    onClick={addCard}
                >
                    <div className="WpdIcon">
                        <i className="wpd-icon wpd-plus"></i>
                    </div>
                    <div className="WpdText">Add New Card</div>
                </button>
            </div>
            <Modal
                show={showRemoveModal}
                onHide={closeConfirmation}
                centered
            >
                <div className={loading ? 'is-loading' : ''}>
                    <Modal.Header
                        closeButton
                        className="d-flex justify-content-center text-center"
                    >
                        <Modal.Title>Confirm Card Removal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center text-center">
                        Are you sure you want to remove this card?
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className={`WpdButton WpdWarningButton ml-auto`}
                            data-dismiss="modal"
                            onClick={closeConfirmation}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">No</span>
                            </span>
                        </button>
                        <button
                            className={`WpdButton WpdSuccessButton ml-3 mr-auto`}
                            onClick={handleRemoveCard}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">Yes</span>
                            </span>
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>

            <Modal
                show={showDefaultModal}
                onHide={closeDefaultConfirmation}
                centered
            >
                <div className={loading ? 'is-loading' : ''}>
                    <Modal.Header
                        closeButton
                        className="d-flex justify-content-center text-center"
                    >
                        <Modal.Title>Set as Default Payment Method</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center text-center">
                        <span>
                            <strong>Note: </strong> This default card will
                            replace all your existing payment methods for your
                            subscriptions.
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className={`WpdButton WpdWarningButton ml-auto`}
                            data-dismiss="modal"
                            onClick={closeDefaultConfirmation}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">No</span>
                            </span>
                        </button>
                        <button
                            className={`WpdButton WpdSuccessButton ml-3 mr-auto`}
                            onClick={setAsDefault}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">Yes</span>
                            </span>
                        </button>
                    </Modal.Footer>
                </div>
            </Modal>
        </section>
    );
};

export default BillingMethod;
