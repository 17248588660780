import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Markup/assets/img/WpdLogoWithText.svg';
import CartErrorIllustration from '../../Markup/assets/img/payment error.svg';

const PaymentFailed = () => {
    return (
        <div className="WpdPageBody WpdLoginBody">
            <div className="WpdLoginHeader">
                <Link
                    to="/user/dashboard"
                    className="WpdSiteLogo"
                >
                    <img
                        src={Logo}
                        alt="WpDeveloper"
                    />
                </Link>
            </div>
            <div className="WpdLoginWrapper">
                <div className="WpdLoginContent">
                    <div className="WpdAuthContent">
                        <h2 className="WpdAuthTitle WpdColorWhite">
                            Payment Failed
                        </h2>
                        <p className="WpdAuthText">
                            Your transaction could not be processed. Please
                            check your payment information and try again.
                        </p>
                        <div className="WpdButtonGroup">
                            <a
                                href={`${process.env.REACT_APP_SITE_URL}/plugins`}
                                className="WpdButton WpdInfoButton WpdFilled"
                            >
                                <span className="WpdButtonInner">
                                    <span className="WpdText">
                                        Browse Products
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="WpdLoginImage">
                    <img
                        src={CartErrorIllustration}
                        alt=""
                        className="w-100 img-fluid"
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentFailed;
