import { parse } from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import EmptyCart from './EmptyCart';
import CheckoutLoader from './CheckoutLoader';
import {
    getErrorMessage,
    isLoggedIn,
    validateFormFields,
} from '../../utils/helper';
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import { decode } from 'html-entities';
import { toast } from 'react-toastify';

const Checkout = ({
    cartData,
    coupon,
    addToCart,
    removeFromCart,
    getCartData,
    history,
    sendCouponData,
    signin,
    processCheckout,
    checkoutUrl,
    loading,
    processingCheckout,
    couponProcessing,
    cartLoading,
    getCountries,
    loadingCountries,
    countries,
    getStates,
    loadingStates,
    states,
    setAutoLoggedInUser,
    getProfile,
    profile,
    addToCartUpsell,
    licenseRenewing,
    recaptureCart,
    recaptureTrack,
}) => {
    const [routerProductDetails, setRouterProductDetails] = useState(null);

    const [billingInfo, setBillingInfo] = useState({
        edd_first: '',
        edd_last: '',
        edd_email: '',
        edd_acquisition_method: '',
        edd_agree_to_terms: false,
        edd_agree_to_privacy_policy: false,
        edd_gateway: 'stripe',
        need_new_user: true,
        need_user_login: true,
        payment_mode: 'stripe',
        card_name: '',
        card_address: '',
        card_address_2: '',
        card_city: '',
        card_zip: '',
        billing_country: '',
        card_state: '',
        mailchimp: true,
    });

    const [billingInfoErrors, setBillingInfoErrors] = useState({
        edd_first: '',
        edd_last: '',
        edd_email: '',
        edd_acquisition_method: '',
        edd_agree_to_terms: '',
        edd_gateway: '',
        need_new_user: '',
        need_user_login: '',
        payment_mode: '',
        card_name: '',
        card_address: '',
        card_address_2: '',
        card_city: '',
        card_zip: '',
        billing_country: '',
        card_state: '',
    });

    const [isEmptyCart, setIsEmptyCart] = useState(false);

    const [renewalDiscount, setRenewalDiscount] = useState(undefined);
    const [total, setTotal] = useState(undefined);
    const [couponCode, setCouponCode] = useState('');
    const [showCoupon, setShowCoupon] = useState(false);
    const [couponError, setCouponError] = useState(undefined);
    const [savedCountry, setSavedCountry] = useState(undefined);
    const [savedState, setSavedState] = useState(undefined);
    const [showUpsell, setShowUpsell] = useState(true);
    const couponRef = useRef(null);
    const [countryOptions, setCountryOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [hidePaymentOptions, setHidePaymentOptions] = useState(false);
    const [requiredAddress, setRequiredAddress] = useState(true);
    const [selectedState, setSelectedState] = useState(undefined);
    const [disabledState, setDisabledState] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(undefined);
    const [disablePaymentBtn, setDisablePaymentBtn] = useState(false);

    useEffect(() => {
        !countries && getCountries();
    }, []);

    useEffect(() => {
        if (isLoggedIn()) {
            if (!signin) {
                getProfile();
            } else {
                setBillingInfo({
                    ...billingInfo,
                    edd_first: signin.userInfo.first_name,
                    edd_last: signin.userInfo.last_name,
                    edd_email: signin.userInfo.email,
                    edd_acquisition_method: 'other',
                });

                if (!profile) {
                    getProfile();
                }
            }
        }
        if (!routerProductDetails) {
            const data = parse(history.location.search);
            if (
                !(data.contents && data.racart) &&
                (!data.download_id || !data.price_id)
            ) {
                getCartData();
            } else {
                setRouterProductDetails(data);
                history.push('/checkout');
            }
        }
    }, [getCartData, history.location.search, routerProductDetails]);

    useEffect(() => {
        if (profile) {
            const info = {
                edd_first: profile.first_name,
                edd_last: profile.last_name,
                edd_email: profile.email,
                edd_acquisition_method: 'other',
            };
            setAutoLoggedInUser(profile);
            if (profile.address) {
                info.card_address =
                    profile.address.line1 ?? billingInfo.card_address;
                info.card_address_2 =
                    profile.address.line2 ?? billingInfo.card_address_2;
                info.card_city = profile.address.city ?? billingInfo.card_city;
                info.card_zip = profile.address.zip ?? billingInfo.card_zip;
                info.billing_country =
                    profile.address.country ?? billingInfo.billing_country;
                info.card_state =
                    profile.address.state ?? billingInfo.card_state;

                if (
                    profile.address.country &&
                    profile.address.country.trim().length
                ) {
                    getCountries();
                    setSavedCountry(profile.address.country);
                    if (
                        profile.address.state &&
                        profile.address.state.trim().length
                    ) {
                        getStates(profile.address.country);
                        setSavedState(profile.address.state);
                    }
                }
                setBillingInfo({ ...billingInfo, ...info });
            }
        }
    }, [profile]);

    useEffect(() => {
        if (routerProductDetails) {
            if (routerProductDetails.contents && routerProductDetails.racart) {
                recaptureCart(routerProductDetails);
            } else {
                addToCart(routerProductDetails);
            }
        }
    }, [routerProductDetails, addToCart, recaptureCart]);

    useEffect(() => {
        if (checkoutUrl) {
            window.location.href = checkoutUrl;
        } else {
            if (!processingCheckout) {
                setDisablePaymentBtn(false);
            }
        }
    }, [checkoutUrl, processCheckout]);

    useEffect(() => {
        if (!loading) {
            if (cartData) {
                if (!cartData.details || !cartData.details.length) {
                    setIsEmptyCart(true);
                }
            } else {
                setIsEmptyCart(true);
            }
        } else {
            setIsEmptyCart(false);
        }
    }, [loading, cartData]);

    useEffect(() => {
        if (
            cartData &&
            cartData.has_discounts &&
            parseInt(cartData.total) === 0
        ) {
            setBillingInfo({ ...billingInfo, edd_gateway: 'manual' });
            setHidePaymentOptions(true);
        } else {
            setBillingInfo({ ...billingInfo, edd_gateway: 'stripe' });
            setHidePaymentOptions(false);
        }

        if (cartData && cartData.details && cartData.details.length) {
            setTotal(cartData.total);

            const discount = cartData.details.reduce(
                (total, item) =>
                    item.item_number.options.is_renewal
                        ? total + item.discount
                        : total,
                0
            );

            discount > 0
                ? setRenewalDiscount(discount)
                : setRenewalDiscount(undefined);

            /*const filtered = cartData.details.filter(
        (item) =>
          +item.id === upsellProduct.download_id &&
          +item.item_number?.options?.price_id === upsellProduct.price_id
      );

      if (filtered.length) {
        if (localStorage.getItem('old_cart')) {
          setShowUpsell(true);
        } else {
          setShowUpsell(false);
        }
      } else {
        setShowUpsell(true);
      }*/
        }
    }, [cartData]);

    useEffect(() => {
        if (coupon && coupon.is_valid) {
            setCouponCode(coupon.code);
        }
    }, [coupon]);

    useEffect(() => {
        if (countries && countries.length) {
            setCountryOptions(
                countries
                    .filter(c => c.value.length)
                    .map(c => ({ label: decode(c.label), value: c.value }))
            );
            if (savedCountry) {
                setSelectedCountry(
                    countries.filter(c => c.value === savedCountry)
                );
            }
        }
    }, [countries]);

    useEffect(() => {
        if (states && states.length) {
            setDisabledState(false);
            setStateOptions(
                states
                    .filter(s => s.value.length)
                    .map(c => ({ label: decode(c.label), value: c.value }))
            );
            if (savedState) {
                setSelectedState(states.filter(s => s.value === savedState));
            }
        }
    }, [states]);

    /**
     * Handle checkout form submit
     * Initialize the checkout process
     */
    const handleSubmit = e => {
        e.preventDefault();
        if (!checkValidation(billingInfo)) {
            return;
        }
        setDisablePaymentBtn(true);
        const hasAccount = !localStorage.getItem('access_token');

        const formFields = {
            ...billingInfo,
            'edd-gateway': billingInfo.edd_gateway,
            'payment-mode': billingInfo.edd_gateway,
            card_name: billingInfo.edd_first + ' ' + billingInfo.edd_last,
            need_new_user: hasAccount,
            need_user_login: hasAccount,
            edd_agree_to_terms: billingInfo.edd_agree_to_terms ? 1 : 0,
            edd_agree_to_privacy_policy: billingInfo.edd_agree_to_terms ? 1 : 0,
        };
        if (localStorage.getItem('old_cart')) {
            localStorage.removeItem('old_cart');
        }
        if (billingInfo.payment_mode === 'paypalexpress') {
            delete formFields['card_address'];
            delete formFields['card_address_2'];
            delete formFields['card_city'];
            delete formFields['card_state'];
            delete formFields['card_zip'];
        }
        processCheckout(formFields);
    };

    const getValidationRules = () => {
        const rules = {
            edd_first: {
                required: {
                    value: true,
                    message: 'First name is required',
                },
                maxLength: {
                    value: 60,
                    message: 'Fist name should not be more than 60 characters',
                },
            },
            edd_last: {
                required: {
                    value: true,
                    message: 'Last name is required',
                },
                maxLength: {
                    value: 60,
                    message: 'Last name should not be more than 60 characters',
                },
            },
            edd_email: {
                required: {
                    value: true,
                    message: 'Email is required',
                },
                email: {
                    value: true,
                    message: 'Please insert a valid email',
                },
            },
            edd_acquisition_method: {
                required: {
                    value: true,
                    message: 'Please select how did you find us',
                },
            },
            edd_gateway: {
                required: {
                    value: true,
                    message: 'Please select payment method',
                },
            },
            edd_agree_to_terms: {
                required: {
                    value: true,
                    message:
                        'Please agree to Privacy policy and terms & conditions',
                },
            },
        };
        if (requiredAddress) {
            const addressFields = {
                billing_country: {
                    required: {
                        value: true,
                        message: 'Please select country',
                    },
                },
                card_address: {
                    required: {
                        value: true,
                        message: 'Please insert billing address',
                    },
                    maxLength: {
                        value: 255,
                        message:
                            'Billing address should not be more than 255 characters',
                    },
                },
                card_address2: {
                    maxLength: {
                        value: 255,
                        message:
                            'Billing address line 2 should not be more than 255 characters',
                    },
                },
                card_city: {
                    required: {
                        value: true,
                        message: 'Please insert city',
                    },
                    maxLength: {
                        value: 60,
                        message: 'City should not be more than 60 characters',
                    },
                },
                card_zip: {
                    required: {
                        value: true,
                        message: 'Please insert zip/postal code',
                    },
                    maxLength: {
                        value: 24,
                        message:
                            'Zip/postal code should not be more than 24 characters',
                    },
                },
            };
            if (stateOptions.length) {
                addressFields.card_state = {
                    required: {
                        value: true,
                        message: 'Please select state',
                    },
                };
            }
            return { ...rules, ...addressFields };
        }
        return rules;
    };

    const getFieldRules = field => {
        return getValidationRules()[field];
    };

    const checkValidation = (fields, showToast = true) => {
        const validated = validateFormFields(fields, getValidationRules());
        if (!validated.valid) {
            setBillingInfoErrors({ ...billingInfoErrors, ...validated.errors });
            showToast &&
                toast.error(
                    'Please fill the information required for checkout'
                );
            return false;
        } else {
            const errorFields = { ...fields };
            Object.keys(errorFields).map(val => (errorFields[val] = ''));
            setBillingInfoErrors({ ...billingInfoErrors, ...errorFields });
        }
        return true;
    };

    const applyCoupon = apply => {
        if (!couponCode || !couponCode.trim().length) {
            setCouponError('Please insert coupon code');
            return;
        }
        sendCouponData({
            code: couponCode,
            type: apply ? 'apply' : 'remove',
            form: '',
        });
        if (!apply) {
            setShowCoupon(false);
            if (billingInfo.edd_gateway === 'manual') {
                setBillingInfo({ ...billingInfo, edd_gateway: 'stripe' });
                setRequiredAddress(true);
            }
        }
    };
    const handleShowCouponClick = () => {
        setShowCoupon(!showCoupon);
        setCouponCode('');
        setCouponError(undefined);
    };

    return (
        <div className="WpdPageBody">
            <main className="WpdContentArea WpdCheckoutArea">
                {loading ? (
                    <CheckoutLoader />
                ) : !isEmptyCart ? (
                    <div className="WpdNewCheckoutContent">
                        <LeftSide
                            billingInfo={billingInfo}
                            setBillingInfo={setBillingInfo}
                            billingInfoErrors={billingInfoErrors}
                            countryOptions={countryOptions}
                            stateOptions={stateOptions}
                            hidePaymentOptions={hidePaymentOptions}
                            requiredAddress={requiredAddress}
                            setRequiredAddress={setRequiredAddress}
                            loadingCountries={loadingCountries}
                            getStates={getStates}
                            loadingStates={loadingStates}
                            setStateOptions={setStateOptions}
                            setCountryOptions={setCountryOptions}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            setDisabledState={setDisabledState}
                            disabledState={disabledState}
                            profile={profile}
                            cartData={cartData}
                            recaptureTrack={recaptureTrack}
                            checkValidation={checkValidation}
                            getFieldRules={getFieldRules}
                        />
                        <RightSide
                            cartData={cartData}
                            removeFromCart={removeFromCart}
                            coupon={coupon}
                            handleSubmit={handleSubmit}
                            total={total}
                            processingCheckout={processingCheckout}
                            couponProcessing={couponProcessing}
                            addToCart={addToCart}
                            renewalDiscount={renewalDiscount}
                            cartLoading={cartLoading}
                            applyCoupon={applyCoupon}
                            couponCode={couponCode}
                            setCouponCode={setCouponCode}
                            showCoupon={showCoupon}
                            setShowCoupon={setShowCoupon}
                            couponError={couponError}
                            setCouponError={setCouponError}
                            handleShowCouponClick={handleShowCouponClick}
                            couponRef={couponRef}
                            addToCartUpsell={addToCartUpsell}
                            showUpsell={showUpsell}
                            licenseRenewing={licenseRenewing}
                            hidePaymentOptions={hidePaymentOptions}
                            disablePaymentBtn={disablePaymentBtn}
                        />
                    </div>
                ) : (
                    <EmptyCart
                        addToCart={addToCart}
                        loading={loading}
                        cartData={cartData}
                    />
                )}
            </main>
        </div>
    );
};

export default Checkout;
