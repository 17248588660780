import { all, fork } from 'redux-saga/effects';
import checkoutSaga from './CheckoutSaga';
import dashboardSaga from './DashboardSaga';
import headerSaga from './HeaderSaga';
import manageSiteSaga from './ManageSiteSaga';
import productSaga from './ProductSaga';
import profileSaga from './ProfileSaga';
import purchaseSaga from './PurchaseSaga';
import resetPassSaga from './ResetPasswordSaga';
import sendForgetPassLinkSaga from './SendForgetPassLinkSaga';
import signInSaga from './SignInSaga';
import subscriptionSaga from './SubscriptionSaga';
import newsSaga from './NewsSaga';
import licenseSaga from './ManageLicenseSaga';
import paymentSuccessSaga from './PaymentSuccessSaga';
import upgradeLicenseSaga from './UpgradeLicenseSaga';
import addressSaga from './AddressSaga';
import myTicketsSaga from './MyTicketsSaga';
import generateInvoiceSaga from './GenerateInvoiceSaga';
import downloadsSaga from './DownloadsSaga';
import conversationsSaga from './ConversationSaga';
import affiliateSaga from './AffiliateSaga';
import shopManagerSaga from './ShopManagerSaga';

export default function* root() {
    yield all([
        fork(signInSaga),
        fork(sendForgetPassLinkSaga),
        fork(resetPassSaga),
        fork(dashboardSaga),
        fork(headerSaga),
        fork(subscriptionSaga),
        fork(checkoutSaga),
        fork(productSaga),
        fork(profileSaga),
        fork(purchaseSaga),
        fork(manageSiteSaga),
        fork(newsSaga),
        fork(licenseSaga),
        fork(paymentSuccessSaga),
        fork(upgradeLicenseSaga),
        fork(addressSaga),
        fork(myTicketsSaga),
        fork(generateInvoiceSaga),
        fork(downloadsSaga),
        fork(conversationsSaga),
        fork(affiliateSaga),
        fork(shopManagerSaga),
    ]);
}
