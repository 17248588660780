import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    ADD_TO_CART,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_FAILED,
    GET_CART_DATA,
    GET_CART_DATA_SUCCESS,
    GET_CART_DATA_FAILURE,
    SEND_COUPON_DATA,
    SEND_COUPON_DATA_SUCCESS,
    SEND_COUPON_DATA_FAILURE,
    REMOVE_FROM_CART,
    REMOVE_FROM_CART_SUCCESS,
    REMOVE_FROM_CART_FAILURE,
    PROCESS_CHECKOUT,
    PROCESS_CHECKOUT_SUCCESS,
    PROCESS_CHECKOUT_FAILED,
    ADD_TO_CART_UPSELL,
    ADD_TO_CART_UPSELL_SUCCESS,
    ADD_TO_CART_UPSELL_FAILED,
    RECAPTURE_CART,
    RECAPTURE_CART_SUCCESS,
    RECAPTURE_CART_FAILED,
} from '../actions/CheckoutActions';
import { getErrorMessage, isServerError } from '../utils/helper';
import { decode } from 'html-entities';

const initialState = {
    cartData: undefined,
    loading: false,
    couponData: undefined,
    checkoutUrl: undefined,
    processingCheckout: false,
    couponProcessing: false,
    cartLoading: false,
    licenseRenewing: false,
};

export default function checkoutReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const loadingData = {};
            action.separateLoading
                ? (loadingData.cartLoading = true)
                : (loadingData.loading = true);
            if (
                action.product.action &&
                action.product.action.toString() === 'renew'
            ) {
                loadingData.licenseRenewing = true;
            }
            return {
                ...state,
                ...loadingData,
                processingCheckout: false,
                couponProcessing: false,
            };
        case ADD_TO_CART_SUCCESS:
            return {
                ...state,
                cartData: action.response,
                coupon: action.response.coupons
                    ? action.response.coupons
                    : undefined,
                licenseRenewing: false,
                loading: false,
                cartLoading: false,
            };
        case ADD_TO_CART_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Something went wrong!'
            );
            if (action.cartAction === 'renew') {
                return {
                    ...state,
                    loading: false,
                    cartLoading: false,
                    licenseRenewing: false,
                };
            }
            return {
                ...state,
                cartData: undefined,
                loading: false,
                cartLoading: false,
            };

        case ADD_TO_CART_UPSELL:
            return {
                ...state,
                cartLoading: true,
                processingCheckout: false,
                couponProcessing: false,
            };
        case ADD_TO_CART_UPSELL_SUCCESS:
            return {
                ...state,
                cartData: action.response,
                cartLoading: false,
                coupon: action.response.coupons
                    ? action.response.coupons
                    : undefined,
            };
        case ADD_TO_CART_UPSELL_FAILED:
            toast.error(getErrorMessage(action.error, 'Something went wrong!'));
            return {
                ...state,
                loading: false,
                cartLoading: false,
            };

        case REMOVE_FROM_CART:
            return {
                ...state,
                cartLoading: true,
            };
        case REMOVE_FROM_CART_SUCCESS:
            return {
                ...state,
                cartData: action.response,
                cartLoading: false,
            };
        case REMOVE_FROM_CART_FAILURE:
            toast.error('Something went wrong!');
            return {
                ...state,
                cartData: undefined,
                loading: false,
            };
        case GET_CART_DATA:
            return {
                ...state,
                loading: true,
                processingCheckout: false,
                couponProcessing: false,
            };
        case GET_CART_DATA_SUCCESS:
            return {
                ...state,
                cartData: action.response,
                coupon: action.response.coupons
                    ? action.response.coupons
                    : undefined,
                loading: false,
            };
        case GET_CART_DATA_FAILURE:
            toast.error('Something went wrong!');
            return {
                ...state,
                cartData: undefined,
                loading: false,
            };
        case SEND_COUPON_DATA:
            return {
                ...state,
                loading: false,
                processingCheckout: false,
                couponProcessing: true,
            };
        case SEND_COUPON_DATA_SUCCESS:
            if (action.response.coupons && !action.response.coupons.is_valid) {
                toast.error(decode(action.response.coupons.msg));
            }
            return {
                ...state,
                cartData: action.response,
                coupon: action.response.coupons,
                couponProcessing: false,
            };
        case SEND_COUPON_DATA_FAILURE:
            if (isServerError(action.error)) {
                return {
                    ...state,
                    couponProcessing: false,
                };
            }
            toast.error(getErrorMessage(action.error));
            return {
                ...state,
                cartData: action.response,
                coupon: action.response.coupons,
                couponProcessing: false,
            };
        case PROCESS_CHECKOUT:
            return {
                ...state,
                processingCheckout: true,
                checkoutInfo: undefined,
                couponProcessing: false,
                checkoutUrl: undefined,
            };
        case PROCESS_CHECKOUT_SUCCESS:
            return {
                ...state,
                processingCheckout: false,
                checkoutUrl: action.response.url,
            };
        case PROCESS_CHECKOUT_FAILED:
            toast.error(
                getErrorMessage(action.error, 'Failed to process checkout')
            );
            return {
                ...state,
                processingCheckout: false,
                checkoutUrl: false,
            };
        case RECAPTURE_CART:
            return {
                ...state,
                loading: true,
                processingCheckout: false,
                couponProcessing: false,
            };
        case RECAPTURE_CART_SUCCESS:
            return {
                ...state,
                cartData: action.response,
                coupon: action.response.coupons
                    ? action.response.coupons
                    : undefined,
                licenseRenewing: false,
                loading: false,
                cartLoading: false,
            };
        case RECAPTURE_CART_FAILED:
            toast.error(
                getErrorMessage(
                    action.error,
                    'Failed to retrieve your cart information, please try again'
                )
            );
            return {
                ...state,
                cartData: undefined,
                loading: false,
                cartLoading: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
