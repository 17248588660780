import React, { useEffect, useRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import BackButton from './BackButton';
import { maskEmail } from '../../../utils/helper';

const EmailSubmitCode = ({
	userEmail,
	submit,
	code,
	setCode,
	isLoading,
	error,
	setView,
}) => {
	const inputRef = useRef(null);
	useEffect(() => {
		inputRef.current.focus();
	}, []);

	return (
		<div className="Wpd2FAForm">
			<BackButton
				goto="configure2fa"
				setView={setView}
			/>

			<div className="Wpd2FAHeadingWrapper">
				<div className="Wpd2FAHeadingIcon">
					<i className="wpd-icon wpd-key-2"></i>
				</div>
				<h3 className="Wpd2FAHeadingTitle">2FA Email Verification</h3>
				<p className="Wpd2FAHeadingInfo">
					Enter Login Verification code that has been sent over your
					email address: {userEmail && maskEmail(userEmail)}
				</p>
			</div>
			<div className="Wpd2FAFormBody">
				<div className="Wpd2FAFormDivider" />
				<Form
					style={{ width: '100%' }}
					onSubmit={e => {
						e.preventDefault();
						submit();
					}}
				>
					<div className="Wpd2FAFormInputFieldWrapper">
						<label className="Wpd2FAFormInputFieldLabel">
							Enter the Login Verification code
						</label>
						<div
							className={`Wpd2FAFormInputFieldInner ${
								false ? 'WpdHasError' : ''
							}`}
						>
							<input
								ref={inputRef}
								value={code}
								type="text"
								className="Wpd2FAFormInputField"
								placeholder="Type your code here..."
								onChange={e => setCode(e.target.value)}
							/>
							<button
								disabled={isLoading}
								className="Wpd2FAFormInputFieldButton"
							>
								{isLoading && (
									<Spinner
										animation="border"
										variant="light"
										style={{ height: 26, width: 26 }}
									/>
								)}
								{!isLoading && 'Confirm'}
							</button>
						</div>
						{error?.code && (
							<p className="Wpd2FAFormInputFieldError">
								Expired code or wrong one
							</p>
						)}
					</div>
				</Form>
				<div className="w-100 mt-4">
					<p className="Wpd2FAFormBodyLink text-left">
						<strong>Important:</strong> If you haven't received the
						email with the Login Verification code, please check
						your spam folder of your mailbox or contact{' '}
						<a
							href="https://wpdeveloper.com/support"
							target="_blank"
							className="highlight"
						>
							<strong>support</strong>
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	);
};

export default EmailSubmitCode;
