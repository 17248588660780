import {
    PASSWORD_RESET,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAILURE,
    PASSWORD_RESET_VALIDATION_FAILED,
} from '../actions/ResetPasswordActions';
import { SIGNED_OUT } from '../actions/index';
import { toast } from 'react-toastify';

const initialState = {
    resetPass: undefined,
    validationError: [],
};

export default function signIn(state = initialState, action) {
    switch (action.type) {
        case PASSWORD_RESET:
            return state;
        case PASSWORD_RESET_SUCCESS:
            toast.success('Your password reset successfully');
            const credentials = {
                redirect: true,
            };
            return { ...state, resetPass: credentials };
        case PASSWORD_RESET_FAILURE:
            toast.error(action.message);
            return state;
        case PASSWORD_RESET_VALIDATION_FAILED:
            toast.error(action.message);
            return {
                ...state,
                validationError: action.errors,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
