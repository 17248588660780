import React, { useState } from 'react';
import ErrorMessage from '../../utils/ErrorMessage';
import Select from 'react-select';
import PersonalInfo from './PersonalInfo';

const LeftSide = ({
    billingInfo,
    setBillingInfo,
    billingInfoErrors,
    countryOptions,
    stateOptions,
    hidePaymentOptions,
    requiredAddress,
    setRequiredAddress,
    loadingCountries,
    setStateOptions,
    setCountryOptions,
    getStates,
    loadingStates,
    setSelectedState,
    selectedState,
    setSelectedCountry,
    selectedCountry,
    setDisabledState,
    disabledState,
    profile,
    cartData,
    recaptureTrack,
    checkValidation,
    getFieldRules,
}) => {
    const handlePaymentOption = e => {
        setBillingInfo({
            ...billingInfo,
            edd_gateway: e.target.value,
            payment_mode: e.target.value,
        });
        if (e.target.value.toString() === 'paypalexpress') {
            setRequiredAddress(false);
        } else {
            setRequiredAddress(true);
        }
    };

    const handleCountryChange = selected => {
        if (selected && selected.value?.length) {
            setSelectedState(undefined);
            setDisabledState(true);
            setStateOptions([]);
            getStates(selected.value);
            setSelectedCountry(selected);
            /*setBillingInfo({
        ...billingInfo,
        billing_country: selected.value,
        card_state: '',
      });*/
        } else {
            setStateOptions([]);
            setSelectedCountry(undefined);
            /*setBillingInfo({
        ...billingInfo,
        billing_country: '',
        card_state: '',
      });*/
        }
        handleInputChange(selected, 'billing_country');
    };

    const handleStateChange = selected => {
        if (selected && selected.value?.length) {
            setSelectedState(selected);
        } else {
            setSelectedState(undefined);
        }
        handleInputChange(selected, 'card_state');
    };

    const handleInputChange = (e, field) => {
        const selectFields = ['billing_country', 'card_state'];
        let value = '';
        if (selectFields.includes(field)) {
            value = e && e.value.length ? e.value : '';
        } else {
            if (field === 'edd_agree_to_terms') {
                value = e.target.checked;
            } else {
                value = e.target.value;
            }
        }
        setBillingInfo({ ...billingInfo, [field]: value });
        checkValidation(
            {
                [field]: value,
            },
            false
        );
    };

    return (
        <div className="WpdCheckoutContentLeft">
            <div className="WpdSectionTitleWrap">
                <h4 className="WpdCheckoutTitle">Checkout</h4>
            </div>
            <PersonalInfo
                profile={profile}
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
                billingInfoErrors={billingInfoErrors}
                cartData={cartData}
                recaptureTrack={recaptureTrack}
                checkValidation={checkValidation}
                getFieldRules={getFieldRules}
            />
            {!hidePaymentOptions && (
                <section className="WpdPaymentMethodSection">
                    <div className="WpdPaymentMethodSelector">
                        <label
                            className="WpdRadio"
                            htmlFor="M1"
                        >
                            <input
                                type="radio"
                                name="WpdPaymentMethod"
                                defaultChecked
                                id="M1"
                                value="stripe"
                                onChange={handlePaymentOption}
                            />
                            <span className="WpdDetails">
                                <span className="WpdIcon">
                                    <i className="wpd-icon wpd-credit-card"></i>
                                </span>
                                <span className="WpdContent">
                                    <span className="WpdTitle">
                                        Credit Card
                                    </span>
                                    <span className="WpdInfo">
                                        Use a Credit or Debit card
                                    </span>
                                </span>
                            </span>
                        </label>
                        <label
                            className="WpdRadio"
                            htmlFor="M2"
                        >
                            <input
                                type="radio"
                                name="WpdPaymentMethod"
                                id="M2"
                                value="paypalexpress"
                                onChange={handlePaymentOption}
                            />
                            <span className="WpdDetails">
                                <span className="WpdIcon">
                                    <i className="wpd-icon wpd-paypal"></i>
                                </span>
                                <span className="WpdContent">
                                    <span className="WpdTitle">PayPal</span>
                                    <span className="WpdInfo">
                                        Use your PayPal account
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                </section>
            )}
            {requiredAddress && (
                <section className="WpdPaymentMethodSection">
                    <h4 className="WpdPaymentMethodSectionTitle">
                        Billing Details
                    </h4>
                    <div className="WpdFormWrapper">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="WpdFormGroup">
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            billingInfoErrors.card_address &&
                                            billingInfoErrors.card_address
                                                .length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            handleInputChange(e, 'card_address')
                                        }
                                        value={billingInfo.card_address}
                                        placeholder={'Address Line*'}
                                    />
                                </div>
                                <ErrorMessage
                                    error={billingInfoErrors.card_address}
                                />
                            </div>
                            <div className="col-sm-6">
                                <div className="WpdFormGroup">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={e =>
                                            handleInputChange(
                                                e,
                                                'card_address_2'
                                            )
                                        }
                                        value={billingInfo.card_address_2}
                                        placeholder={'Address Line 2'}
                                    />
                                </div>
                                <ErrorMessage
                                    error={billingInfoErrors.card_address_2}
                                />
                            </div>
                            <div className="col-sm-6">
                                <div className="WpdFormGroup">
                                    <Select
                                        options={countryOptions}
                                        isLoading={loadingCountries}
                                        onChange={handleCountryChange}
                                        isSearchable={true}
                                        isClearable={true}
                                        classNamePrefix="WpdCheckoutSelect"
                                        value={selectedCountry}
                                        placeholder={'Country*'}
                                    />
                                </div>
                                <ErrorMessage
                                    error={billingInfoErrors.billing_country}
                                />
                            </div>
                            <div className="col-sm-6">
                                <div className="WpdFormGroup">
                                    <Select
                                        options={stateOptions}
                                        isLoading={loadingStates}
                                        onChange={handleStateChange}
                                        value={selectedState}
                                        isSearchable={true}
                                        isClearable={true}
                                        isDisabled={disabledState}
                                        classNamePrefix="WpdCheckoutSelect"
                                        placeholder={
                                            stateOptions.length
                                                ? 'State*'
                                                : 'State'
                                        }
                                    />
                                </div>
                                <ErrorMessage
                                    error={billingInfoErrors.card_state}
                                />
                            </div>
                            <div className="col-sm-6">
                                <div className="WpdFormGroup">
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            billingInfoErrors.card_city &&
                                            billingInfoErrors.card_city
                                                .length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            handleInputChange(e, 'card_city')
                                        }
                                        value={billingInfo.card_city}
                                        placeholder={'City*'}
                                    />
                                </div>
                                <ErrorMessage
                                    error={billingInfoErrors.card_city}
                                />
                            </div>
                            <div className="col-sm-6">
                                <div className="WpdFormGroup">
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            billingInfoErrors.card_zip &&
                                            billingInfoErrors.card_zip.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            handleInputChange(e, 'card_zip')
                                        }
                                        value={billingInfo.card_zip}
                                        placeholder="Zip/Postal Code*"
                                    />
                                </div>
                                <ErrorMessage
                                    error={billingInfoErrors.card_zip}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <div className="row">
                <div className="col-sm-12">
                    <label className="WpdCheckbox mt-3">
                        <input
                            type="checkbox"
                            onChange={e =>
                                handleInputChange(e, 'edd_agree_to_terms')
                            }
                        />
                        <span className="WpdText">
                            I Agree With{' '}
                            <a href="https://wpdeveloper.com/terms-and-conditions">
                                Terms & Conditions
                            </a>{' '}
                            and{' '}
                            <a href="https://wpdeveloper.com/privacy-policy">
                                Privacy Policy
                            </a>
                        </span>
                    </label>
                    <ErrorMessage
                        error={billingInfoErrors.edd_agree_to_terms}
                    />
                </div>
                <div className="col-sm-12">
                    <label className="WpdCheckbox mt-3">
                        <input
                            type="checkbox"
                            checked={billingInfo.mailchimp}
                            onChange={e =>
                                setBillingInfo({
                                    ...billingInfo,
                                    mailchimp: e.target.checked,
                                })
                            }
                        />
                        <span className="WpdText">
                            Get free tips, tutorials and discount codes from us
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default LeftSide;
