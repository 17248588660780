import React from 'react';
class Footer extends React.Component {
    render() {
        return (
            <footer className="WpdContentAreaFooter">
                <p className="WpdColorSecondary">
                    Copyright &copy; 2011 - {new Date().getFullYear()} |{' '}
                    <a
                        href="https://wpdeveloper.com"
                        className="WpdColorInfo"
                    >
                        WPDeveloper.com
                    </a>
                </p>
            </footer>
        );
    }
}

export default Footer;
