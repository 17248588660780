import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TooltipComponent from '../../utils/TooltipComponent';

const SplitPayment = ({ cartData, addToCart }) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (cartData?.splits) {
			const isSplitSelected = cartData.splits.filter(
				each => each.price_id === cartData?.contents[0].options.price_id
			);
			isSplitSelected.length && setIsOpen(true);
		}
	}, [cartData]);

	const updateCart = useCallback(
		e => {
			addToCart(
				{
					download_id: 474227,
					price_id: e.target.value,
				},
				false,
				true
			);
		},
		[cartData]
	);

	const agencyLifetime = useMemo(() => {
		if (!cartData) {
			return null;
		}

		return {
			index: cartData?.contents?.findIndex(item => +item.id === 474227),
			product: cartData?.contents?.find(item => +item.id === 474227),
		};
	}, [cartData]);

	if (!cartData?.splits) {
		return null;
	}

	return (
		<section
			className={`WpdLicenseSection WpdSectionGap ${isOpen ? 'Expanded' : ''}`}
		>
			<h4
				className="WpdLicenseSectionTitle"
				onClick={() => setIsOpen(!isOpen)}
			>
				<span>
					<span style={{ marginRight: '0.5rem' }}>
						Want to split your payment?
					</span>
					<TooltipComponent tooltipText={'Click to Enter it'}>
						<span className="WpdLicenseSectionTitleInfo">
							<i className="wpd-icon wpd-information-solid"></i>
						</span>
					</TooltipComponent>
				</span>
				<TooltipComponent tooltipText={'Click to Enter it'}>
					<span className="WpdCollapserToggler">
						<i className="wpd-icon wpd-angle-down"></i>
					</span>
				</TooltipComponent>
			</h4>

			{isOpen && (
				<div className="WpdLicenseSectionBody splitPayment">
					{cartData?.splits.map((each, index) => (
						<label
							key={index}
							className="splitPayment-item"
						>
							<input
								id={`radio-${each.price_id}`}
								type="radio"
								name="split_option"
								value={+each.price_id}
								onChange={updateCart}
								checked={
									+agencyLifetime?.product?.options
										?.price_id === +each.price_id
								}
							/>
							<span htmlFor={`radio-${each.price_id}`}>
								{each.times} Months
							</span>
						</label>
					))}
					<label
						key={3}
						className="splitPayment-item"
					>
						<input
							id={`radio-none`}
							type="radio"
							name="split_option"
							value={2}
							onChange={updateCart}
							checked={
								+agencyLifetime?.product?.options?.price_id ===
								2
							}
						/>
						<span htmlFor={`radio-none`}>None</span>
					</label>
				</div>
			)}
		</section>
	);
};

export default SplitPayment;
