import Million from '../../Markup/assets/img/blog-placeholder.jpg';
import React, { useEffect } from 'react';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { decode } from 'html-entities';

const LatestNews = ({ latestNews, getLatestNews, newsLoading }) => {
    const extractContent = s => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };

    useEffect(() => {
        getLatestNews();
    }, []);

    const getImageUrl = news => {
        if (
            news['_embedded']['wp:featuredmedia'] &&
            news['_embedded']['wp:featuredmedia'].length
        ) {
            return news['_embedded']['wp:featuredmedia'][0].media_details.sizes
                .medium
                ? news['_embedded']['wp:featuredmedia'][0].media_details.sizes
                      .medium.source_url
                : news['_embedded']['wp:featuredmedia'][0].media_details.sizes
                      .large.source_url;
        }
        return Million;
    };

    return (
        <section className="WpdMyPostGridSection WpdSectionGap">
            <div className="WpdSectionTitleWrap">
                <h4 className="WpdSectionTitle mr-2">Latest News</h4>
                <a
                    href={`${process.env.REACT_APP_SITE_URL}/blog`}
                    target="_blank"
                    rel="noreferrer"
                    className="WpdButton WpdInfoButton WpdFilled ml-auto"
                >
                    <span className="WpdButtonInner">
                        <span className="WpdText">Browse All Posts</span>
                    </span>
                </a>
            </div>

            {newsLoading ? (
                <Loader />
            ) : latestNews ? (
                <div className="WpdPostGridWrapper">
                    {latestNews.map((news, index) => (
                        <div
                            className="WpdPostGrid"
                            key={index}
                        >
                            <a
                                className="WpdPostGridThumbnail"
                                href={news.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    backgroundImage: `url("${getImageUrl(news)}")`,
                                }}
                            ></a>
                            <div className="WpdPostGridDetails">
                                <a
                                    href={news.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <h3 className="WpdPostGridTitle">
                                        {decode(news.title.rendered)}
                                    </h3>
                                </a>
                                <p className="WpdPostGridContent">
                                    {extractContent(
                                        decode(news.excerpt.rendered)
                                    )}
                                </p>
                                <a
                                    href={news.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="WpdPostGridButton mt-auto"
                                >
                                    Read More
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <EmptyMessage>No news found</EmptyMessage>
            )}
        </section>
    );
};

export default LatestNews;
