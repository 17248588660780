import axios from 'axios';
import { store } from '../configureStore';
import {
	loadingStart,
	loadingStop,
	signOut,
	internalServerError,
} from '../actions/index';

import { require2fa, require2faSetup } from '../actions/SigninActions';

import { instanceOf } from 'prop-types';
import { isLoggedIn } from './helper';

const reqData = {
	method: 'GET',
	url: '',
	queryParams: {},
	formData: {},
	bodyParams: {},
	pathParams: [],
	data: {},
};

function makeHeaders() {
	return {
		'Content-Type': 'application/json',
	};
}

function makeHeaderWithToken(token) {
	return {
		'x-wp-nonce': token,
		'Content-Type': 'application/json',
	};
}

export function defaultApi(
	URL,
	method,
	details,
	hideLoader,
	hasFile,
	options = {},
	withoutAuth = false
) {
	let token = localStorage.getItem('access_token');
	if (!token && details && details.nonceKey) {
		token = details.nonceKey;
		details.nonceKey = '';
	}
	const headers =
		token && !withoutAuth ? makeHeaderWithToken(token) : makeHeaders();
	if (hasFile) {
		headers['Content-Type'] = 'multipart/form-data';
	}
	const api = axios.create({
		baseURL: URL,
		headers: headers,
		withCredentials: true,
		...options,
	});

	let requestDetails = { ...reqData };
	requestDetails.url = URL;
	requestDetails.method = method;
	if (method === 'GET') {
		requestDetails.params = details;
	}
	if (method === 'POST' && details) {
		const formData = new FormData();
		Object.keys(details).map(key => {
			if (
				parseInt(details[key]) === 0 ||
				(details[key] !== undefined && details[key])
			) {
				if (Array.isArray(details[key])) {
					for (let k in details[key]) {
						if (typeof details[key][k] === 'object') {
							if (details[key][k] instanceof File) {
								formData.append(`${key}[]`, details[key][k]);
							} else {
								formData.append(
									`${key}[]`,
									JSON.stringify(details[key][k])
								);
							}
						} else {
							formData.append(`${key}[]`, details[key][k]);
						}
					}
				} else {
					formData.append(key, details[key]);
				}
			}
		});
		requestDetails.data = formData;
	}
	requestDetails.withoutAuth = withoutAuth;
	return apiCall(api, requestDetails, hideLoader)
		.then(response => {
			return response;
		})
		.catch(error => error);
}

async function apiCall(api, requestDetails, hideLoader) {
	!hideLoader && store.dispatch(loadingStart());
	let apiReturn = {
		response: undefined,
		error: undefined,
	};

	try {
		const data = await api(requestDetails);
		const headers = data.headers;

		let responseData = data ? data.data : undefined;
		if (!requestDetails.withoutAuth) {
			if (headers.hasOwnProperty('x-wp-nonce')) {
				if (
					(has2faSetup(headers) || has2faRequired(headers)) &&
					!isLoggedIn()
				) {
					if (typeof responseData !== 'object') {
						responseData = {};
					} else {
						responseData.setup2faKey = headers['x-wp-nonce'];
					}
				} else {
					localStorage.setItem('access_token', headers['x-wp-nonce']);
					if (data.data && typeof data.data === 'object') {
						data.data.nonce = headers['x-wp-nonce'];
					}
				}
			}
		}
		if (hasPagination(requestDetails)) {
			if (headers.hasOwnProperty('x-wp-total')) {
				if (responseData) {
					responseData = {
						data: responseData,
						total: headers['x-wp-total'],
					};
				}
			}
		}
		if (has2faRequired(headers)) {
			responseData = {
				...responseData,
				message: '2FA Authentication Required',
				required_2fa: true,
				auth_id: headers['x-wp-2fa-auth-id'],
			};
			if (isLoggedIn()) {
				store.dispatch(
					require2fa({
						required_2fa: true,
						auth_id: headers['x-wp-2fa-auth-id'],
						setup_2fa: headers['x-wp-2fa-setup'],
						setup2fa_type: headers?.['x-wp-2fa-type'],
					})
				);
			}
		}

		if (has2faSetup(headers)) {
			if (isLoggedIn()) {
				store.dispatch(
					require2faSetup({
						...responseData,
						setup_2fa: headers['x-wp-2fa-setup'],
						auth_id: headers['x-wp-2fa-auth-id'],
						setup2fa_type: headers?.['x-wp-2fa-type'],
					})
				);
			}
			responseData = {
				...responseData,
				setup_2fa: headers['x-wp-2fa-setup'],
				auth_id: headers['x-wp-2fa-auth-id'],
			};
		}

		if (headers?.['x-wp-2fa-type']) {
			responseData.setup2fa_type = headers?.['x-wp-2fa-type'];
		}

		apiReturn = {
			...apiReturn,
			response: responseData,
			error: data && !data.data ? data.data : undefined,
		};
	} catch (error) {
		apiReturn = { ...apiReturn, error: error && error.response };
	}

	if (apiReturn && apiReturn.error) {
		if (
			apiReturn.error.status === 401 ||
			apiReturn.error?.data?.code?.toString() ===
				'rest_cookie_invalid_nonce'
		) {
			store.dispatch(signOut());
		}
		if (
			apiReturn.error.status === 500 &&
			apiReturn.error?.data?.code?.toString() === 'internal_server_error'
		) {
			store.dispatch(internalServerError());
		}
	}
	!hideLoader && store.dispatch(loadingStop());
	return apiReturn;
}

export function recaptureApi(url, details) {
	const api = axios.create({
		baseURL: url,
		headers: {
			'Content-Type': 'application/json',
			'api-key': process.env.REACT_APP_RECAPTURE_API_KEY,
		},
		withCredentials: false,
	});
	let requestDetails = { ...reqData };
	requestDetails.url = url;
	requestDetails.method = 'POST';
	requestDetails.data = details;
	requestDetails.withoutAuth = true;
	return apiCall(api, requestDetails, true);
}

function hasPagination(requestDetails) {
	if (requestDetails.params) {
		if (
			requestDetails.params.affiliate_id &&
			requestDetails.params.number
		) {
			return true;
		}
		if (requestDetails.params.page) {
			return true;
		}
	}
	return false;
}

const has2faSetup = headers => {
	return (
		headers.hasOwnProperty('x-wp-2fa-setup') &&
		(headers['x-wp-2fa-setup'].toString() === '1' ||
			headers['x-wp-2fa-setup'].toString() === '2' ||
			headers['x-wp-2fa-setup'].toString() === '3' ||
			headers['x-wp-2fa-setup'].toString() === '2.1' ||
			headers['x-wp-2fa-setup'].toString() === '3.1')
	);
};

const has2faRequired = headers =>
	headers.hasOwnProperty('x-wp-2fa-required') &&
	headers['x-wp-2fa-required'].toString() === '1';
