export const GET_UPGRADES = 'GET_UPGRADES';
export const GET_UPGRADES_SUCCESS = 'GET_UPGRADES_SUCCESS';
export const GET_UPGRADES_FAILED = 'GET_UPGRADES_FAILED';

export function getUpgrades(licenseId) {
    return {
        type: GET_UPGRADES,
        licenseId,
    };
}
