import { connect } from 'react-redux';
import MyTickets from '../components/MyTickets/MyTickets';
import { createTicket, getTickets } from '../actions/MyTicketsActions';

const mapStateToProps = state => {
    return {
        loading: state.myTickets.loading,
        tickets: state.myTickets.tickets,
        creatingTicket: state.myTickets.creating,
        created: state.myTickets.created,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTickets: () => dispatch(getTickets()),
        createTicket: data => dispatch(createTicket(data)),
    };
};

const MyTicketsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyTickets);

export default MyTicketsContainer;
