import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Product from '../Common/Product/Product';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { Modal } from 'react-bootstrap';
import DownloadItem from '../Common/Download/MyDownloadItem';
import ProductIcon from '../../utils/ProductIcon';

const MyProducts = ({
    products,
    productsLoading,
    getProducts,
    setSiteData,
    history,
    currentPage,
    downloadProduct,
    downloadItem,
    downloading,
}) => {
    const linkHistory = useHistory();
    const goToPreviousPath = () => {
        linkHistory.goBack();
    };

    const [showModal, setShowModal] = useState(false);
    const [modalProduct, setModalProduct] = useState([]);
    const [downloadBundleName, setDownloadBundleName] = useState('');
    const [innerDownloads, setInnerDownloads] = useState(undefined);

    useEffect(() => {
        let perPage = 20;
        if (currentPage && currentPage === 'dashboard') {
            perPage = 3;
        }
        getProducts({
            page: 1,
            per_page: perPage,
        });
    }, []);
    const showInnerDownloads = (e, item) => {
        e.preventDefault();
        setInnerDownloads(item);
    };
    const innerDownloadsView = () => {
        return (
            <div className="WpdInnerDownloadItemsWrapper">
                <button
                    onClick={() => setInnerDownloads(undefined)}
                    className="WpdBackButton mb-3"
                >
                    <span className="WpdIcon">
                        <i className="wpd-icon wpd-angle-left"></i>
                    </span>
                    <span>Back to downloads</span>
                </button>
                {innerDownloads.downloads.map((download, key) => (
                    <div
                        className={'mb-2'}
                        key={key}
                    >
                        <DownloadItem
                            key={key}
                            {...download}
                            downloadProduct={downloadProduct}
                            downloadItem={downloadItem}
                            downloading={downloading}
                            payment_id={modalProduct.payment_id}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const handleModalClose = () => {
        setShowModal(false);
        setInnerDownloads(undefined);
    };

    return (
        <section
            className={`WpdMyProductSection ${
                currentPage && currentPage == 'dashboard' ? 'WpdSectionGap' : ''
            }`}
        >
            <div className="WpdSectionTitleWrap">
                {!currentPage || currentPage !== 'dashboard' ? (
                    <button
                        onClick={goToPreviousPath}
                        className="WpdBackButton"
                    >
                        <span className="WpdIcon">
                            <i className="wpd-icon wpd-angle-left"></i>
                        </span>
                    </button>
                ) : (
                    ''
                )}
                <h4 className="WpdSectionTitle mr-2">My Products</h4>
                {currentPage && currentPage === 'dashboard' ? (
                    <Link
                        to="/user/my-products"
                        className="WpdButton WpdInfoButton WpdFilled ml-auto"
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">All Products</span>
                        </span>
                    </Link>
                ) : (
                    ''
                )}
            </div>
            {productsLoading ? (
                <Loader />
            ) : products && products.data?.length > 0 ? (
                <div className="WpdDataTableWrapper">
                    <table className="WpdDataTable">
                        <tbody>
                            {products.data.map(product => {
                                return product.products.map((p, i) => {
                                    if (p.license) {
                                        return (
                                            <Product
                                                key={i}
                                                {...p}
                                                id={product.ID}
                                                paymentID={product.ID}
                                                setSiteData={setSiteData}
                                                history={history}
                                                setShowModal={setShowModal}
                                                setDownloadBundleName={
                                                    setDownloadBundleName
                                                }
                                                downloadProduct={
                                                    downloadProduct
                                                }
                                                downloadItem={downloadItem}
                                                downloading={downloading}
                                                setModalProduct={
                                                    setModalProduct
                                                }
                                            />
                                        );
                                    }
                                });
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyMessage>
                    No Available Product found in your account.
                </EmptyMessage>
            )}
            <Modal
                show={showModal}
                onHide={handleModalClose}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Download items in {downloadBundleName ?? 'the bundle'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="WpdDownloadItemWrapper">
                        {modalProduct.downloads && modalProduct.downloads.length
                            ? innerDownloads
                                ? innerDownloadsView()
                                : modalProduct.downloads.map((item, key) => {
                                      if (!item.hasOwnProperty('downloads')) {
                                          return (
                                              <DownloadItem
                                                  key={key}
                                                  {...item}
                                                  downloadProduct={
                                                      downloadProduct
                                                  }
                                                  downloadItem={downloadItem}
                                                  downloading={downloading}
                                                  payment_id={
                                                      modalProduct.payment_id
                                                  }
                                              />
                                          );
                                      }
                                      return (
                                          <div
                                              className={`WpdDownloadInfoWrapper`}
                                              key={key}
                                          >
                                              <span className="WpdDownloadInfo">
                                                  <span className="WpdDownloadThumbnail">
                                                      <ProductIcon
                                                          icon={item.icon}
                                                          name={item.name}
                                                      />
                                                  </span>
                                                  <span className="WpdDownloadDetails">
                                                      <span className="WpdDownloadTitle">
                                                          {item.name}
                                                      </span>
                                                  </span>
                                              </span>
                                              <button
                                                  className={`WpdSmallButton WpdHoverInfo ml-auto`}
                                                  style={{ minWidth: 110 }}
                                                  onClick={e =>
                                                      showInnerDownloads(
                                                          e,
                                                          item
                                                      )
                                                  }
                                              >
                                                  <span className="WpdButtonInner">
                                                      <span className="WpdIcon">
                                                          <i className="wpd-icon wpd-download-2" />
                                                      </span>
                                                      <span className="WpdText">
                                                          Show Downloads{' '}
                                                      </span>
                                                  </span>
                                              </button>
                                          </div>
                                      );
                                  })
                            : 'No downloads found'}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="WpdButton WpdWarningButton ml-auto"
                        data-dismiss="modal"
                        onClick={handleModalClose}
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">Close</span>
                        </span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default MyProducts;
