export const GET_SUBSCRIPTION_DATA = 'GET_SUBSCRIPTION_DATA';
export const GET_SUBSCRIPTION_DATA_SUCCESS = 'GET_SUBSCRIPTION_DATA_SUCCESS';
export const GET_SUBSCRIPTION_DATA_FAILED = 'GET_SUBSCRIPTION_DATA_FAILED';

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILED = 'UPDATE_SUBSCRIPTION_FAILED';

export function getSubscriptionData() {
    return {
        type: GET_SUBSCRIPTION_DATA,
    };
}

export function cancelSubscription(id, updateType) {
    return {
        type: UPDATE_SUBSCRIPTION,
        id,
        updateType,
    };
}
