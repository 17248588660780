import React, { useEffect, useState } from 'react';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import { formatDate, statusTextColor, ucFirst } from '../../utils/helper';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ImageUploader from '../../utils/FileUploader/ImageUploader';
import ErrorMessage from '../../utils/ErrorMessage';

function MyTickets({
    loading,
    getTickets,
    tickets,
    createTicket,
    creatingTicket,
    created,
}) {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        subject: '',
        message: '',
        message_attachment: [],
    });
    const [errors, setErrors] = useState({
        subject: '',
        message: '',
    });

    useEffect(() => {
        getTickets();
    }, []);

    useEffect(() => {
        if (created) {
            getTickets();
            setFormData({
                subject: '',
                message: '',
                message_attachment: [],
            });
            setShowModal(false);
        }
    }, [created]);

    const handleTicketCreate = e => {
        e.preventDefault();
        if (validInputs()) {
            createTicket(formData);
        }
    };

    const validInputs = () => {
        const formErrors = {};
        let isValid = true;
        if (!formData.subject.length) {
            isValid = false;
            formErrors.subject = 'Please enter subject';
        } else {
            formErrors.subject = '';
        }
        if (!formData.message.length) {
            isValid = false;
            formErrors.message = 'Please enter message';
        } else {
            formErrors.message = '';
        }
        setErrors(formErrors);
        return isValid;
    };
    const handleModalHide = e => {
        if (loading) {
            return;
        }
        setShowModal(false);
    };

    return (
        <section className="WpdMyProductSection">
            <div className="WpdSectionTitleWrap">
                <h4 className="WpdSectionTitle mr-2">My Tickets</h4>
                <button
                    className="WpdButton WpdInfoButton WpdFilled ml-auto"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={e => setShowModal(true)}
                >
                    <span className="WpdButtonInner">
                        <span className="WpdText">Add Ticket</span>
                        <span className="WpdIcon">
                            <i className="wpd-icon wpd-plus"></i>
                        </span>
                    </span>
                </button>
            </div>
            {loading ? (
                <Loader />
            ) : tickets && tickets.data && tickets.data.length ? (
                <div className="WpdDataTableWrapper">
                    <table className="WpdDataTable">
                        <thead>
                            <tr>
                                <th>
                                    <b>Subject</b>
                                </th>
                                <th>Status</th>
                                <th>Created at</th>
                                <th>Waiting Since</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.data.map((ticket, index) => (
                                <tr key={index}>
                                    <td className="WpdActiveStatus">
                                        <label>Subject:</label>
                                        <b>{ticket.conversation.subject}</b>
                                    </td>
                                    <td className="WpdActiveStatus">
                                        <label>Status:</label>
                                        <b>
                                            <span
                                                className={`WpdLabelButton ${statusTextColor(
                                                    ticket.conversation.status
                                                )}`}
                                            >
                                                <span className="WpdButtonInner">
                                                    <span className="WpdText">
                                                        {ucFirst(
                                                            ticket.conversation
                                                                .status
                                                        )}
                                                    </span>
                                                </span>
                                            </span>
                                        </b>
                                    </td>
                                    <td>
                                        <label>Created at:</label>
                                        <p>
                                            {formatDate(
                                                ticket.conversation.createdAt
                                            )}
                                        </p>
                                    </td>
                                    <td>
                                        <label>Waiting Since:</label>
                                        <p>
                                            {' '}
                                            {
                                                ticket.conversation
                                                    .customerWaitingSince
                                                    .friendly
                                            }
                                        </p>
                                    </td>
                                    <td>
                                        <label>Action:</label>
                                        <p>
                                            <Link
                                                to={`/user/conversations/${ticket.conversation.id}`}
                                                className="WpdSmallButton WpdInfoButton text-center"
                                            >
                                                <span className="WpdButtonInner">
                                                    <span className="WpdText">
                                                        View Threads
                                                    </span>
                                                </span>
                                            </Link>
                                        </p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyMessage> No tickets found</EmptyMessage>
            )}
            <Modal
                show={showModal}
                onHide={e => setShowModal(false)}
                centered
                size="lg"
                className={creatingTicket ? 'is-loading-modal' : ''}
                backdrop={creatingTicket ? 'static' : true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`WpdFormWrapper`}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="WpdFormGroup">
                                    <label className="WpdFormLabel">
                                        Subject
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            errors.subject &&
                                            errors.subject.length &&
                                            'WpdHasError'
                                        }`}
                                        placeholder="How can I help you?"
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                subject: e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage error={errors.subject} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="WpdFormGroup">
                                    <label className="WpdFormLabel">
                                        Message
                                    </label>
                                    <textarea
                                        rows="5"
                                        className={`form-control ${
                                            errors.message &&
                                            errors.message.length &&
                                            'WpdHasError'
                                        }`}
                                        placeholder="Example: Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                message: e.target.value,
                                            })
                                        }
                                    ></textarea>
                                    <ErrorMessage error={errors.message} />
                                </div>
                            </div>
                            <div className="col-md-12 mb-0">
                                <div className="WpdFileBrowseWrapper">
                                    <label className="WpdFormLabel mr-3">
                                        Add Attachment:{' '}
                                    </label>
                                    <ImageUploader
                                        onFileChange={files =>
                                            setFormData({
                                                ...formData,
                                                message_attachment: files,
                                            })
                                        }
                                        multiple={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="WpdButton WpdWarningButton ml-auto"
                        data-dismiss="modal"
                        onClick={e => setShowModal(false)}
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">Cancel</span>
                        </span>
                    </button>
                    <button
                        className="WpdButton WpdInfoButton WpdFilled ml-2"
                        onClick={handleTicketCreate}
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">Submit</span>
                        </span>
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default MyTickets;
