export const GET_PRODUCTS_DATA = 'GET_PRODUCTS_DATA';
export const GET_PRODUCTS_DATA_SUCCESS = 'GET_PRODUCTS_DATA_SUCCESS';
export const GET_PRODUCTS_DATA_FAILED = 'GET_PRODUCTS_DATA_FAILED';

export function getProducts(params) {
    return {
        type: GET_PRODUCTS_DATA,
        params,
    };
}
