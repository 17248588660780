import { connect } from 'react-redux';
import Download from '../components/Download/Download';
import { downloadProduct, getDownloads } from '../actions/DownloadActions';
import CommonDownloads from '../components/CommonDownloads';
const mapStateToProps = state => {
    return {
        downloads: state.downloads.all,
        loading: state.downloads.downloading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        downloadProduct: data => dispatch(downloadProduct(data)),
    };
};

const CommonDownloadContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommonDownloads);

export default CommonDownloadContainer;
