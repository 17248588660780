import { all, put, takeLatest } from 'redux-saga/effects';
import { defaultApi } from '../utils/axiosApi';
import {
    GET_LATEST_NEWS_DATA,
    GET_LATEST_NEWS_DATA_FAILED,
    GET_LATEST_NEWS_DATA_SUCCESS,
} from '../actions/NewsActions';

export function* loadLatestNews() {
    const endpoint = `${process.env.REACT_APP_BLOG_BASE_URL}/posts?page=1&per_page=3&order=desc&orderby=date&_embed=true&categories=70`;

    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        {},
        true,
        false,
        {},
        true
    );
    if (response) {
        yield put({
            type: GET_LATEST_NEWS_DATA_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_LATEST_NEWS_DATA_FAILED,
            error,
        });
    }
}

export function* getLatestNewsData() {
    yield takeLatest(GET_LATEST_NEWS_DATA, loadLatestNews);
}

export default function* rootSaga() {
    yield all([getLatestNewsData()]);
}
