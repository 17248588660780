import {
    SEND_FORGETPASS_LINK_SUCCESS,
    SEND_FORGETPASS_LINK_FAILURE,
    SEND_FORGETPASS_LINK_VALIDATION_FAILED,
    SEND_FORGETPASS_LINK,
    RESET_FORGET_PASSWORD,
} from '../actions/SendForgetPassLinkAction';
import { SIGNED_OUT } from '../actions/index';
import { toast } from 'react-toastify';

const initialState = {
    forgetPassLink: undefined,
    validationError: [],
    loading: false,
};

export default function forgetPassLink(state = initialState, action) {
    switch (action.type) {
        case SEND_FORGETPASS_LINK:
            return {
                ...state,
                forgetPassLink: undefined,
                validationError: [],
                loading: true,
            };
        case SEND_FORGETPASS_LINK_SUCCESS:
            toast.success('Forget password request success');
            return {
                ...state,
                validationError: [],
                forgetPassLink: true,
                loading: false,
            };
        case SEND_FORGETPASS_LINK_FAILURE:
            toast.error(action.message);
            return {
                ...state,
                validationError: [],
                forgetPassLink: undefined,
                loading: false,
            };
        case SEND_FORGETPASS_LINK_VALIDATION_FAILED:
            return {
                ...state,
                validationError: action.errors,
            };
        case RESET_FORGET_PASSWORD:
            return {
                ...state,
                loading: false,
                forgetPassLink: undefined,
                validationError: [],
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
