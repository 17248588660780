import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import BackButton from './BackButton';

const BackupCode = ({ downloadBackupCodes, codes, setView }) => {
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = e => {
		e.preventDefault();
		setIsLoading(true);
		downloadBackupCodes();
	};

	return (
		<div className="Wpd2FAForm">
			<div className="Wpd2FAHeadingWrapper">
				<div className="Wpd2FAHeadingIcon">
					<i className="wpd-icon wpd-key-2"></i>
				</div>
				<h3 className="Wpd2FAHeadingTitle">
					Two-Factor Authentication
				</h3>
				<p className="Wpd2FAHeadingInfo">
					Finish enabling two factor authentication.
				</p>
			</div>
			<div className="Wpd2FAFormBody">
				<div className="Wpd2FAFormDivider" />
				<div className="Wpd2FAMultipleKeyWrapper">
					<h5 className="Wpd2FAMultipleKeyTitle">
						Two-factor authentication is now enabled.
					</h5>
					<p className="Wpd2FAMultipleKeyInfo">
						Store these recovery codes in a secure password manager.
						They can be used to recover access to your account if
						your two factor authentication device is lost.
					</p>

					{codes.length > 0 && (
						<div className="Wpd2FAMultipleKeyInner mt-2">
							{codes.map(code => {
								return (
									<p
										key={code}
										className="Wpd2FAMultipleKey"
									>
										{code}
									</p>
								);
							})}
							{/* <button className="Wpd2FAMultipleKeyCopyButton">
								<i className="wpd-icon wpd-copy-2"></i>
							</button> */}
						</div>
					)}
				</div>
				<Form onSubmit={onSubmit}>
					<button
						disabled={isLoading}
						type="submit"
						className="WpdButton WpdInfoButton WpdFilled mt-4.5"
					>
						{isLoading && (
							<Spinner
								animation="border"
								variant="light"
								style={{ height: 26, width: 26 }}
							/>
						)}

						{!isLoading && (
							<span className="WpdButtonInner">
								<span className="WpdText">
									Download and Continue
								</span>
							</span>
						)}
					</button>
				</Form>
			</div>
		</div>
	);
};

export default BackupCode;
