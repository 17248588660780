import React from 'react';
import { decode } from 'html-entities';

const ErrorMessage = ({ error }) =>
    (error && error.length && (
        <p className="WpdErrorMessage">{decode(error)}</p>
    )) ||
    '';

export default ErrorMessage;
