export const SEND_FAILED_PAYMENT_INFO = ' SEND_FAILED_PAYMENT_INFO';
export const SEND_FAILED_PAYMENT_INFO_SUCCESS =
    ' SEND_FAILED_PAYMENT_INFO_SUCCESS';
export const SEND_FAILED_PAYMENT_INFO_FAILED =
    ' SEND_FAILED_PAYMENT_INFO_FAILED';

export function sendFailedPaymentInfo(data) {
    return {
        type: SEND_FAILED_PAYMENT_INFO,
        data,
    };
}
