export const GENERATE_INVOICE = 'GENERATE_INVOICE';
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_FAILED = 'GENERATE_INVOICE_FAILED';

export const GET_INVOICE_DATA = 'GET_INVOICE_DATA';
export const GET_INVOICE_DATA_SUCCESS = 'GET_INVOICE_DATA_SUCCESS';
export const GET_INVOICE_DATA_FAILED = 'GET_INVOICE_DATA_FAILED';

export const RESET_GENERATED = 'RESET_GENERATED';

export function generateCustomInvoice(data) {
    return {
        type: GENERATE_INVOICE,
        data,
    };
}

export function getInvoiceData(params) {
    return {
        type: GET_INVOICE_DATA,
        params,
    };
}

export function resetGenerated() {
    return {
        type: RESET_GENERATED,
    };
}
