import { defaultApi } from '../utils/axiosApi';
import { all, takeLatest, put } from 'redux-saga/effects';
import {
    GET_CREATIVES,
    GET_CREATIVES_FAILED,
    GET_CREATIVES_SUCCESS,
    GET_PAYOUTS,
    GET_PAYOUTS_FAILED,
    GET_PAYOUTS_SUCCESS,
    GET_REFERRALS,
    GET_REFERRALS_FAILED,
    GET_REFERRALS_SUCCESS,
    GET_VISITS,
    GET_VISITS_FAILED,
    GET_VISITS_SUCCESS,
    REGISTER_NEW_USER,
    REGISTER_NEW_USER_FAILED,
    REGISTER_NEW_USER_SUCCESS,
} from '../actions/AffiliateActions';

export function* getVisits({ params }) {
    const endpoint = `${process.env.REACT_APP_AFFILIATE_BASE_URL}/visits`;
    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        { ...params, order: 'desc' },
        true
    );
    if (response) {
        yield put({
            type: GET_VISITS_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_VISITS_FAILED,
            error,
        });
    }
}

export function* visits() {
    yield takeLatest(GET_VISITS, getVisits);
}

export function* getReferrals({ params }) {
    const endpoint = `${process.env.REACT_APP_AFFILIATE_BASE_URL}/referrals`;
    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        { ...params, order: 'desc' },
        true
    );
    if (response) {
        yield put({
            type: GET_REFERRALS_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_REFERRALS_FAILED,
            error,
        });
    }
}

export function* referrals() {
    yield takeLatest(GET_REFERRALS, getReferrals);
}

export function* getPayouts({ params }) {
    const endpoint = `${process.env.REACT_APP_AFFILIATE_BASE_URL}/payouts`;
    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        { ...params, order: 'desc' },
        true
    );
    if (response) {
        yield put({
            type: GET_PAYOUTS_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_PAYOUTS_FAILED,
            error,
        });
    }
}

export function* payouts() {
    yield takeLatest(GET_PAYOUTS, getPayouts);
}

export function* newUserRegistration({ data }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/affiliate-registration`;
    const { response, error } = yield defaultApi(endpoint, 'POST', data, true);
    if (response) {
        yield put({
            type: REGISTER_NEW_USER_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: REGISTER_NEW_USER_FAILED,
            error,
        });
    }
}

export function* newRegistration() {
    yield takeLatest(REGISTER_NEW_USER, newUserRegistration);
}

export function* getCreatives({ affiliate_id }) {
    const endpoint = `${process.env.REACT_APP_AFFILIATE_BASE_URL}/creatives`;
    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        { affiliate_id, number: 100, offset: 0, status: 'active' },
        true
    );
    if (response) {
        yield put({
            type: GET_CREATIVES_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_CREATIVES_FAILED,
            error,
        });
    }
}

export function* creatives() {
    yield takeLatest(GET_CREATIVES, getCreatives);
}

export default function* rootSaga() {
    yield all([
        visits(),
        referrals(),
        payouts(),
        newRegistration(),
        creatives(),
    ]);
}
