import {
    RENDER_DASHBOARD_REPORT,
    LOAD_DASHBOARD_REPORT_FAILED,
    RENDER_DASHBOARD_REPORT_SUPER_ADMIN,
    LOAD_DASHBOARD_REPORT_FAILED_SUPER_ADMIN,
    EXPORT_DATA_SUCCESS,
    EXPORT_DATA_FAILED,
    LOAD_DASHBOARD_REPORT,
    LOAD_DASHBOARD_OVERVIEW_REPORT,
    RENDER_DASHBOARD_OVERVIEW_REPORT,
    LOAD_DASHBOARD_OVERVIEW_REPORT_FAILED,
    LOAD_DASHBOARD_REPORT_SUPER_ADMIN,
} from '../actions/DashboardActions';

import { ACCOUNT_SWITCH_SUCCESS } from '../actions/HeaderActions';

import { SIGNED_OUT, TOKEN_ERROR, ACCESS_REMOVED } from '../actions/index';
import { toast } from 'react-toastify';
import { SIGN_IN_SUCCESS } from '../actions/SigninActions';
import { clearUserLocalStorage } from '../utils/helper';

const initialState = {
    allProducts: [],
    allProductsForFilter: [],
    siteList: [],
    wpVersion: [],
    phpVersion: [],
    pluginVersion: [],
    status: [],
    language: [],
    de_active_reasons: [],
    activeSitesCount: 0,
    deactivedSitesCount: 0,
    totalUserCount: 0,
    newUserCount: 0,
    analytics: [],
    signout: false,
    mailchimpAnalytics: [],
    overviewLoader: false,
    dashboardSearchLoader: false,
    innitialCall: true,
    sitesConfig: {},
};

export default function report(state = initialState, action) {
    switch (action.type) {
        case LOAD_DASHBOARD_REPORT:
            return {
                ...state,
                // overviewLoader: true,
                dashboardSearchLoader: true,
            };
        case LOAD_DASHBOARD_REPORT_SUPER_ADMIN:
            return {
                ...state,
                // overviewLoader: true,
                dashboardSearchLoader: true,
                innitialCall: true,
            };
        case LOAD_DASHBOARD_OVERVIEW_REPORT:
            return {
                ...state,
                overviewLoader: true,
            };
        case RENDER_DASHBOARD_REPORT:
            return {
                ...state,
                allProductsForFilter: action.allProducts,
                siteList: action.siteList,
                wpVersion: action.wpVersion,
                phpVersion: action.phpVersion,
                pluginVersion: action.pluginVersion,
                status: action.status,
                language: action.language,
                de_active_reasons: action.de_active_reasons,
                activeSitesCount: action.activeSitesCount,
                deactivedSitesCount: action.deactivedSitesCount,
                analytics: action.analytics,
                mailchimpAnalytics: action.mailchimpAnalytics,
                // totalUserCount: action.totalUserCount,
                newUserCount: action.newUserCount,
                // overviewLoader: false,
                dashboardSearchLoader: false,
                // innitialCall: false,
                sitesConfig: action.sitesConfig,
            };
        case RENDER_DASHBOARD_OVERVIEW_REPORT:
            return {
                ...state,
                allProducts: action.allProducts,
                totalUserCount: action.totalUserCount,
                overviewLoader: false,
                innitialCall: false,
            };
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                innitialCall: true,
            };
        case ACCOUNT_SWITCH_SUCCESS:
            return {
                ...state,
                innitialCall: true,
            };
        case ACCESS_REMOVED:
            toast.error(action.message);
            return { ...state, overviewLoader: true, innitialCall: true };

        case RENDER_DASHBOARD_REPORT_SUPER_ADMIN:
            return {
                ...state,
                allProducts: action.allProducts,
                siteList: action.siteList,
                wpVersion: action.wpVersion,
                phpVersion: action.phpVersion,
                pluginVersion: action.pluginVersion,
                status: action.status,
                language: action.language,
                de_active_reasons: action.de_active_reasons,
                activeSitesCount: action.activeSitesCount,
                deactivedSitesCount: action.deactivedSitesCount,
                analytics: action.analytics,
                mailchimpAnalytics: action.mailchimpAnalytics,
                totalUserCount: action.totalUserCount,
                newUserCount: action.newUserCount,
                sitesConfig: action.sitesConfig,
                dashboardSearchLoader: false,
                innitialCall: false,
            };

        case LOAD_DASHBOARD_REPORT_FAILED:
            toast.error(action.message);
            return state;
        case LOAD_DASHBOARD_OVERVIEW_REPORT_FAILED:
            toast.error(action.message);
            return {
                ...state,
                overviewLoader: false,
                innitialCall: false,
            };
        case EXPORT_DATA_FAILED:
            toast.error(action.message);
            return state;
        case LOAD_DASHBOARD_REPORT_FAILED_SUPER_ADMIN:
            toast.error(action.message);
            return {
                ...state,
                dashboardSearchLoader: false,
                innitialCall: false,
            };
        case EXPORT_DATA_SUCCESS:
            toast.success('CSV exported successfully');
            let file = new Blob([action.data.file], { type: 'text/csv' });
            var csvURL = window.URL.createObjectURL(file);
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', 'export.csv');
            tempLink.click();
            return state;
        case TOKEN_ERROR:
            localStorage.getItem('access_token') && toast.error(action.message);
            clearUserLocalStorage();
            return { ...state, signout: true };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
