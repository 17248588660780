export const GET_VISITS = 'GET_VISITS';
export const GET_VISITS_SUCCESS = 'GET_VISITS_SUCCESS';
export const GET_VISITS_FAILED = 'GET_VISITS_FAILED';

export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_FAILED = 'GET_REFERRALS_FAILED';

export const GET_PAYOUTS = 'GET_PAYOUTS';
export const GET_PAYOUTS_SUCCESS = 'GET_PAYOUTS_SUCCESS';
export const GET_PAYOUTS_FAILED = 'GET_PAYOUTS_FAILED';

export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const REGISTER_NEW_USER_SUCCESS = 'REGISTER_NEW_USER_SUCCESS';
export const REGISTER_NEW_USER_FAILED = 'REGISTER_NEW_USER_FAILED';
export const RESET_AFFILIATE_DATA = 'RESET_AFFILIATE_DATA';

export const GET_CREATIVES = 'GET_CREATIVES';
export const GET_CREATIVES_SUCCESS = 'GET_CREATIVES_SUCCESS';
export const GET_CREATIVES_FAILED = 'GET_CREATIVES_FAILED';

export function getVisits(params) {
    return {
        type: GET_VISITS,
        params,
    };
}
export function getReferrals(params) {
    return {
        type: GET_REFERRALS,
        params,
    };
}

export function getPayouts(params) {
    return {
        type: GET_PAYOUTS,
        params,
    };
}

export function registerNewUser(data) {
    return {
        type: REGISTER_NEW_USER,
        data,
    };
}

export function reset(data) {
    return {
        type: RESET_AFFILIATE_DATA,
    };
}

export function getCreatives(affiliate_id) {
    return {
        type: GET_CREATIVES,
        affiliate_id,
    };
}
