import * as React from 'react';

const CheckedCircle = props => {
    return (
        <svg
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M51.914 24.577a2.812 2.812 0 010 3.978L33.046 47.423a2.813 2.813 0 01-3.978 0l-8.982-8.982a2.811 2.811 0 113.977-3.977l6.994 6.993 16.88-16.88a2.813 2.813 0 013.977 0zM72 36c0 19.899-16.104 36-36 36C16.101 72 0 55.896 0 36 0 16.101 16.104 0 36 0c19.899 0 36 16.104 36 36zm-5.625 0C66.375 19.21 52.788 5.625 36 5.625 19.21 5.625 5.625 19.212 5.625 36c0 16.79 13.587 30.375 30.375 30.375 16.79 0 30.375-13.587 30.375-30.375z"
                fill="url(#prefix__paint0_linear_741:8)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_741:8"
                    x1={36}
                    y1={72}
                    x2={36}
                    y2={0}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#09B99D" />
                    <stop
                        offset={1}
                        stopColor="#98FDAC"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default CheckedCircle;
