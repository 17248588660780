export const SIGNED_OUT = 'SIGNED_OUT';
export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';
export const TOKEN_ERROR = 'TOKEN_ERROR';

export const ACCESS_REMOVED = 'ACCESS_REMOVED';
export const RESET_ACCESS_REMOVED = 'RESET_ACCESS_REMOVED';
export const RESET_COMPANY_NOT_FOUND = 'RESET_COMPANY_NOT_FOUND';
export const SERVER_ERROR = 'SERVER_ERROR';

export function signOut() {
    return {
        type: SIGNED_OUT,
    };
}

export function loadingStart() {
    return {
        type: LOADING_START,
    };
}

export function loadingStop() {
    return {
        type: LOADING_STOP,
    };
}
export function resetAccessRemoved() {
    return {
        type: RESET_ACCESS_REMOVED,
    };
}
export function resetCompanyNotFound() {
    return {
        type: RESET_COMPANY_NOT_FOUND,
    };
}

export function internalServerError() {
    return {
        type: SERVER_ERROR,
    };
}
