import React from 'react';
import Million from '../../../Markup/assets/img/1-million 1.svg';
import Doc from '../../../Markup/assets/img/animated-image/Doc.svg';
import Welcome from '../../../Markup/assets/img/animated-image/Welcome .svg';
import EB from '../../../Markup/assets/img/Essential Blocks.svg';
import NX from '../../../Markup/assets/img/Introducing-NotificationX-Easiest-Way-To-Add-Social-Proof-Boost-Conversion-in-WordPress-1 1.svg';
import NX2 from '../../../Markup/assets/img/NotificationX.svg';
import Templately from '../../../Markup/assets/img/Templately.svg';
import ScheduledPress from '../../../Markup/assets/img/WP-Scheduled-Posts-Is-Rebranding-Changes-Name-to-SchedulePress 1.svg';
import Product from '../../Common/Product/Product';

const products = [
    {
        name: 'Templately',
        image: Templately,
        type: 'Individual',
        expires: 'April 25, 2022',
        activations: '0/1',
        status: 'Active',
        license: '7c42ccc1391db74',
    },
    {
        name: 'NotificationX',
        image: NX2,
        type: 'Individual',
        expires: 'April 25, 2022',
        activations: '0/1',
        status: 'Active',
        license: '7c42ccc1391db74',
    },
    {
        name: 'Essential Blocks',
        image: EB,
        type: 'Individual',
        expires: 'April 25, 2022',
        activations: '0/1',
        status: 'Active',
        license: '7c42ccc1391db74',
    },
];

const Dashboard = () => {
    return (
        <>
            <section className="WpdSectionPanel WpdMessageSection">
                <div className="WpdMessageSectionLeft">
                    <h1 className="WpdMessageSectionTitle">Welcome!</h1>
                    <p className="WpdMessageSectionMessage">
                        Here you'll be able to see your purchase details,
                        download the plugin, get your license key for the plugin
                        activation and manage your profile and payment methods.
                    </p>
                </div>
                <div className="WpdMessageSectionRight">
                    <object
                        type="image/svg+xml"
                        data={Welcome}
                        aria-label="Welcome image"
                    />
                </div>
            </section>
            <section className="WpdMyProductSection WpdSectionGap">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">My Products</h4>
                    <button className="WpdButton WpdInfoButton WpdFilled ml-auto">
                        <span className="WpdButtonInner">
                            <span className="WpdText">All Products</span>
                        </span>
                    </button>
                </div>
                <div className="WpdDataTableWrapper">
                    <table className="WpdDataTable">
                        <tbody>
                            {products.map((product, index) => (
                                <Product
                                    key={index}
                                    {...product}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="WpdSectionPanel WpdMessageSection WpdKnowledgebaseMessage WpdSectionGap">
                <div className="WpdMessageSectionLeft">
                    <h4 className="WpdSectionTitle">Knowledgebase</h4>
                    <p className="WpdMessageSectionMessage">
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book.
                    </p>
                    <div className="WpdButtonGroup">
                        <button className="WpdButton WpdInfoButton WpdFilled">
                            <span className="WpdButtonInner">
                                <span className="WpdText">Documentation</span>
                            </span>
                        </button>
                        <button className="WpdButton WpdDangerButton">
                            <span className="WpdButtonInner">
                                <span className="WpdIcon">
                                    <i className="wpd-icon wpd-play-button" />
                                </span>
                                <span className="WpdText">Documentation</span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="WpdMessageSectionRight">
                    <object
                        type="image/svg+xml"
                        data={Doc}
                        aria-label="Knowledgebase image"
                    />
                </div>
            </section>
            <section className="WpdMyPostGridSection WpdSectionGap">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">Latest News</h4>
                    <button className="WpdButton WpdInfoButton WpdFilled ml-auto">
                        <span className="WpdButtonInner">
                            <span className="WpdText">See All Post</span>
                        </span>
                    </button>
                </div>
                <div className="WpdPostGridWrapper">
                    <div className="WpdPostGrid">
                        <div className="WpdPostGridThumbnail">
                            <a
                                href="https://wpdeveloper.net/1-million-users-essential-addons-for-elementor/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={Million}
                                    alt=""
                                    className="img-fluid w-100"
                                />
                            </a>
                        </div>
                        <div className="WpdPostGridDetails">
                            <a
                                href="https://wpdeveloper.net/1-million-users-essential-addons-for-elementor/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h3 className="WpdPostGridTitle">
                                    0 to 1 Million Users From 180+ Countries:
                                    The Journey of Essential Addons for
                                    Elementor
                                </h3>
                            </a>
                            <p className="WpdPostGridContent">
                                Wow! We're so thrilled to announce that, with
                                all of you onboard, Essential Addons for
                                Elementor has reached the milestone we didn’t
                                even dream about when starting it
                            </p>
                            <a
                                href="https://wpdeveloper.net/1-million-users-essential-addons-for-elementor/"
                                target="_blank"
                                rel="noreferrer"
                                className="WpdPostGridButton mt-auto"
                            >
                                Read More
                            </a>
                        </div>
                    </div>
                    <div className="WpdPostGrid">
                        <div className="WpdPostGridThumbnail">
                            <a
                                href="https://wpdeveloper.net/rebranding-wp-scheduled-posts-is-schedulepress/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={ScheduledPress}
                                    alt=""
                                    className="img-fluid w-100"
                                />
                            </a>
                        </div>
                        <div className="WpdPostGridDetails">
                            <a
                                href="https://wpdeveloper.net/rebranding-wp-scheduled-posts-is-schedulepress/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h3 className="WpdPostGridTitle">
                                    Rebranding Announcement: WP Scheduled Posts
                                    Is Now SchedulePress
                                </h3>
                            </a>
                            <p className="WpdPostGridContent">
                                Increasing sales for your WooCommerce store can
                                be a challenge, especially if your WordPress
                                website is not getting enough traffic or
                                engagement.
                            </p>
                            <a
                                href="https://wpdeveloper.net/rebranding-wp-scheduled-posts-is-schedulepress/"
                                target="_blank"
                                rel="noreferrer"
                                className="WpdPostGridButton mt-auto"
                            >
                                Read More
                            </a>
                        </div>
                    </div>
                    <div className="WpdPostGrid">
                        <div className="WpdPostGridThumbnail">
                            <a
                                href="https://wpdeveloper.net/2021-top-5-best-social-proof-plugins-for-wordpress-woocommerce/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={NX}
                                    alt=""
                                    className="img-fluid w-100"
                                />
                            </a>
                        </div>
                        <div className="WpdPostGridDetails">
                            <a
                                href="https://wpdeveloper.net/2021-top-5-best-social-proof-plugins-for-wordpress-woocommerce/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <h3 className="WpdPostGridTitle">
                                    [2021] Top 5 Best Social Proof Plugins for
                                    WordPress &amp; WooCommerce
                                </h3>
                            </a>
                            <p className="WpdPostGridContent">
                                Increasing sales for your WooCommerce store can
                                be a challenge, especially if your WordPress
                                website is not getting enough traffic or
                                engagement.
                            </p>
                            <a
                                href="https://wpdeveloper.net/2021-top-5-best-social-proof-plugins-for-wordpress-woocommerce/"
                                target="_blank"
                                rel="noreferrer"
                                className="WpdPostGridButton mt-auto"
                            >
                                Read More
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Dashboard;
