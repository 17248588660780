import React from 'react';
import ProductIcon from '../../../utils/ProductIcon';

function FreeDownloads({ image, name, slug, isTheme }) {
    let downloadUrl = 'https://downloads.wordpress.org/plugin/';
    if (isTheme) {
        downloadUrl = 'https://downloads.wordpress.org/theme/';
    }
    return (
        <span className="WpdDownloadInfoWrapper">
            <span className="WpdDownloadInfo">
                <span className="WpdDownloadThumbnail">
                    <ProductIcon
                        icon={image}
                        name={name}
                    />
                </span>
                <span className="WpdDownloadDetails">
                    <span className="WpdDownloadTitle">{name}</span>
                    {/*<span className="WpdDownloadSemiTitle">{slug}</span>*/}
                </span>
            </span>
            <a
                href={slug ? `${downloadUrl + slug}.zip` : '#'}
                target="_blank"
                rel="noreferrer"
                className="WpdSmallButton WpdHoverInfo ml-auto"
                style={{ minWidth: 110 }}
            >
                <span className="WpdButtonInner">
                    <span className="WpdIcon">
                        <i className="wpd-icon wpd-download-2" />
                    </span>
                    <span className="WpdText">Download</span>
                </span>
            </a>
        </span>
    );
}

export default FreeDownloads;
