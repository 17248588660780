import React from 'react';

function Statistics() {
    return (
        <div className="WpdTabContent">
            <section className="WpdMyStatisticsSection">
                <div className="WpdSectionTitleWrap">
                    <h4 className="WpdSectionTitle mr-2">Statistics</h4>
                </div>
                <div>
                    <div className="WpdDataTableWrapper">
                        <table className="WpdDataTable">
                            <thead>
                                <tr>
                                    <th>Unpaid Referrals</th>
                                    <th>Paid Referrals</th>
                                    <th>Visits</th>
                                    <th>Conversion Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Unpaid Referrals:</label>
                                        <p>7</p>
                                    </td>
                                    <td>
                                        <label>Paid Referrals:</label>
                                        <p>456</p>
                                    </td>
                                    <td>
                                        <label>Visits:</label>
                                        <p>789</p>
                                    </td>
                                    <td>
                                        <label>Conversion Rate:</label>
                                        <p>30.6%</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="WpdSectionGap">
                    <div className="WpdDataTableWrapper">
                        <table className="WpdDataTable">
                            <thead>
                                <tr>
                                    <th>Unpaid Earnings</th>
                                    <th>Paid Earnings</th>
                                    <th>Commission Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Unpaid Earnings:</label>
                                        <p>$7</p>
                                    </td>
                                    <td>
                                        <label>Paid Earnings:</label>
                                        <p>$456</p>
                                    </td>
                                    <td>
                                        <label>Commission Rate:</label>
                                        <p>30.6%</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="WpdSectionGap">
                    <div className="WpdDataTableWrapper">
                        <table className="WpdDataTable">
                            <thead>
                                <tr>
                                    <th>Campaign</th>
                                    <th>Visits</th>
                                    <th>Unique Links</th>
                                    <th>Converted</th>
                                    <th>Conversion Rate</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div className="WpdSectionGap">
                    <div className="WpdDataTableWrapper">
                        <table className="WpdDataTable">
                            <thead>
                                <tr>
                                    <th>Unpaid Referrals</th>
                                    <th>Paid Referrals</th>
                                    <th>Visits</th>
                                    <th>Conversion Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Unpaid Referrals:</label>
                                        <p>7</p>
                                    </td>
                                    <td>
                                        <label>Paid Referrals:</label>
                                        <p>456</p>
                                    </td>
                                    <td>
                                        <label>Visits:</label>
                                        <p>756</p>
                                    </td>
                                    <td>
                                        <label>Conversion Rate:</label>
                                        <p>30.6%</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="WpdSectionGap">
                    <div className="WpdDataTableWrapper">
                        <table className="WpdDataTable">
                            <thead>
                                <tr>
                                    <th>Unpaid Earning</th>
                                    <th>Paid Earning</th>
                                    <th>Commission Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label>Unpaid Earning:</label>
                                        <p>$7</p>
                                    </td>
                                    <td>
                                        <label>Paid Earning:</label>
                                        <p>$456</p>
                                    </td>
                                    <td>
                                        <label>Commission Rate:</label>
                                        <p>30.6%</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Statistics;
