import { connect } from 'react-redux';
import ShopManager from '../../components/ShopManager/ShopManager';

import {
    getOrders,
    getUserList,
    switchTo,
} from '../../actions/ShopManagerActions';

const mapDispatchToProps = dispatch => {
    return {
        getUserList: params => dispatch(getUserList(params)),
        switchTo: data => dispatch(switchTo(data)),
        getOrders: search => dispatch(getOrders(search)),
    };
};

const mapStateToProps = state => {
    return {
        users: state.shopManager.users,
        profile: state.profile.profile,
        userType: state.auth?.signin?.user_type ?? 'CUSTOMER',
        loading: state.shopManager.loading,
        switching: state.shopManager.switching,
        orders: state.shopManager.orders,
    };
};

const ShopManagerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopManager);

export default ShopManagerContainer;
