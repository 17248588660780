import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    SEND_PAYMENT_INFO,
    SEND_PAYMENT_INFO_FAILED,
    SEND_PAYMENT_INFO_SUCCESS,
} from '../actions/PaymentSuccessActions';
import {
    GET_UPGRADES,
    GET_UPGRADES_FAILED,
    GET_UPGRADES_SUCCESS,
} from '../actions/UpgradeLicenseActions';

const initialState = {
    loading: false,
    upgrades: undefined,
};

export default function upgradeLicenseReducer(state = initialState, action) {
    switch (action.type) {
        case GET_UPGRADES:
            return {
                ...state,
                upgrades: undefined,
                loading: true,
            };
        case GET_UPGRADES_SUCCESS:
            return {
                ...state,
                upgrades: action.response,
                loading: false,
            };
        case GET_UPGRADES_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Unable to get upgrades data, please try again'
            );
            return {
                ...state,
                upgrades: undefined,
                loading: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
