import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem('access_token') ? (
                <>
                    <Component {...props} />
                </>
            ) : (
                <Redirect
                    to={{
                        pathname: '/auth/sign-in',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);
