import { connect } from 'react-redux';
import PaymentSuccess from '../components/PaymentSuccess/PaymentSuccess';
import {
    sendAffiliateInfo,
    sendPaymentInfo,
} from '../actions/PaymentSuccessActions';
import { getProfile } from '../actions/ProfileAction';
import { setAutoLoggedInUser } from '../actions/SigninActions';
const mapStateToProps = state => {
    return {
        paymentInfo: state.paymentSuccess.paymentInfo,
        loading: state.paymentSuccess.loading,
        signin: state.auth.signin,
        profile: state.profile.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        sendPaymentInfo: data => dispatch(sendPaymentInfo(data)),
        getProfile: () => dispatch(getProfile()),
        setAutoLoggedInUser: user => dispatch(setAutoLoggedInUser(user)),
        sendAffiliateInfo: info => dispatch(sendAffiliateInfo(info)),
    };
};
const PaymentSuccessContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentSuccess);

export default PaymentSuccessContainer;
