import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    GET_LATEST_NEWS_DATA,
    GET_LATEST_NEWS_DATA_FAILED,
    GET_LATEST_NEWS_DATA_SUCCESS,
} from '../actions/NewsActions';

const initialState = {
    latest: undefined,
    loading: false,
};

export default function newsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LATEST_NEWS_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_LATEST_NEWS_DATA_SUCCESS:
            return {
                ...state,
                latest: action.response,
                loading: false,
            };
        case GET_LATEST_NEWS_DATA_FAILED:
            toast.error('Get latest news failed');
            return {
                ...state,
                latest: undefined,
                loading: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
