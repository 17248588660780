import { connect } from 'react-redux';
import PaymentFailed from '../components/PaymentFailed/PaymentFailed';
import { sendFailedPaymentInfo } from '../actions/PaymentFailedActions';
const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        sendFailedPaymentInfo: data => dispatch(sendFailedPaymentInfo(data)),
    };
};
const PaymentSuccessContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentFailed);

export default PaymentSuccessContainer;
