import React from 'react';
import {
    formatDate,
    statusTextColor,
    twoDecimalFraction,
    ucFirst,
} from '../../utils/helper';
import { Link } from 'react-router-dom';

const HistoryItem = ({
    ID,
    number,
    subtotal,
    date,
    status,
    email,
    purchase_key,
    download,
    downloading,
    downloadItem,
}) => {
    const handleDownload = function (e) {
        e.preventDefault();
        !downloading &&
            download({
                download_name: `Invoice-${number}.pdf`,
                purchase_key,
                email,
                purchase_id: ID,
            });
    };

    const isDownloading = () => downloading && downloadItem.purchase_id === ID;

    return (
        <tr className={`${isDownloading() ? 'is-loading-tr' : ''}`}>
            <td>
                <label>ID: </label>
                <p>{number}</p>
            </td>
            <td>
                <label>Date: </label>
                <p className="text-nowrap">{formatDate(new Date(date))}</p>
            </td>
            <td>
                <label>Amount: </label>
                <p>${twoDecimalFraction(subtotal)}</p>
            </td>
            <td>
                <label>Status: </label>
                <p>
                    <span
                        className={`WpdLabelButton ${statusTextColor(status)}`}
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">{status}</span>
                        </span>
                    </span>
                </p>
            </td>
            <td>
                <label className="justify-content-center text-center">
                    Generate Invoice:{' '}
                </label>
                <p>
                    <Link
                        to={`generate-invoice/${ID}`}
                        className="WpdSmallButton WpdInfoButton mr-2"
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">Generate</span>
                        </span>
                    </Link>
                    {status.toLowerCase() !== 'refunded' && (
                        <button
                            className={`WpdSmallButton WpdSuccessButton ${
                                isDownloading() ? `WpdDisabled` : ''
                            }`}
                            onClick={handleDownload}
                            disabled={isDownloading()}
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdIcon">
                                    <i className="wpd-icon wpd-download-2"></i>
                                </span>
                                <span className="WpdText">
                                    {isDownloading()
                                        ? 'Downloading...'
                                        : 'Download'}
                                </span>
                            </span>
                        </button>
                    )}
                </p>
            </td>
            <td>
                <label>License Key: </label>
                <p>
                    <Link
                        to={`manage-license/${ID}`}
                        className="WpdSmallButton WpdDangerButton"
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">View License</span>
                        </span>
                    </Link>
                </p>
            </td>
        </tr>
    );
};

export default HistoryItem;
