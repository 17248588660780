import { connect } from 'react-redux';
import ProfileArea from '../components/Profile';
import {
    addCard,
    deleteCard,
    getProfile,
    removeCard,
    saveProfile,
    setDefaultCard,
} from '../actions/ProfileAction';
import { getCountries, getStates } from '../actions/AddressActions';

const mapStateToProps = state => {
    return {
        profile: state.profile.profile,
        updating: state.profile.updating,
        loadingCountries: state.address.loadingCountries,
        loadingStates: state.address.loadingStates,
        countries: state.address.countries,
        states: state.address.states,
        addCardLoading: state.profile.addCardLoading,
        cardLoading: state.profile.cardLoading,
        newDefault: state.profile.defaultCard,
        addCardUrl: state.profile.addCardUrl,
        removedCard: state.profile.removedCard,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getProfile: () => dispatch(getProfile()),
        saveProfile: value => dispatch(saveProfile(value)),
        getCountries: () => dispatch(getCountries()),
        getStates: country => dispatch(getStates(country)),
        addCard: () => dispatch(addCard()),
        setDefaultCard: paymentMethod =>
            dispatch(setDefaultCard(paymentMethod)),
        removeCard: paymentMethod => dispatch(removeCard(paymentMethod)),
    };
};

const ProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileArea);

export default ProfileContainer;
