import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    GET_PRODUCT_SITE_DATA,
    GET_PRODUCT_SITE_DATA_SUCCESS,
    GET_PRODUCT_SITE_DATA_FAILED,
    SET_SITE_DATA,
    MANAGE_SITE_SUCCESS,
    MANAGE_SITE_FAILED,
} from '../actions/ManageSiteActions';

const initialState = {
    siteData: undefined,
    loading: false,
};

export default function manageSiteReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_SITE_DATA:
            return {
                ...state,
                loading: true,
            };
        case GET_PRODUCT_SITE_DATA_SUCCESS:
        case MANAGE_SITE_SUCCESS:
            const data = action.response[0];
            return {
                ...state,
                siteData: { ...state.siteData, [data.payment_id]: data },
                loading: false,
            };
        case GET_PRODUCT_SITE_DATA_FAILED:
        case MANAGE_SITE_FAILED:
            toast.error('Something went wrong!');
            return {
                ...state,
                siteData: undefined,
                loading: false,
            };
        case SET_SITE_DATA:
            return {
                ...state,
                siteData: {
                    ...state.siteData,
                    [action.paymentId]: action.data,
                },
            };

        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
