export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED';

export const ADD_TO_CART_UPSELL = 'ADD_TO_CART_UPSELL';
export const ADD_TO_CART_UPSELL_SUCCESS = 'ADD_TO_CART_UPSELL_SUCCESS';
export const ADD_TO_CART_UPSELL_FAILED = 'ADD_TO_CART_UPSELL_FAILED';

export const GET_CART_DATA = 'GET_CART_DATA';
export const GET_CART_DATA_SUCCESS = 'GET_CART_DATA_SUCCESS';
export const GET_CART_DATA_FAILURE = 'GET_CART_DATA_FAILURE';

export const SEND_COUPON_DATA = 'SEND_COUPON_DATA';
export const SEND_COUPON_DATA_SUCCESS = 'SEND_COUPON_DATA_SUCCESS';
export const SEND_COUPON_DATA_FAILURE = 'SEND_COUPON_DATA_FAILURE';

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'REMOVE_FROM_CART_FAILURE';

export const PROCESS_CHECKOUT = 'PROCESS_CHECKOUT';
export const PROCESS_CHECKOUT_SUCCESS = 'PROCESS_CHECKOUT_SUCCESS';
export const PROCESS_CHECKOUT_FAILED = 'PROCESS_CHECKOUT_FAILED';

export const RECAPTURE_CART = 'RECAPTURE_CART';
export const RECAPTURE_CART_SUCCESS = 'RECAPTURE_CART_SUCCESS';
export const RECAPTURE_CART_FAILED = 'RECAPTURE_CART_FAILED';

export const RECAPTURE_TRACK = 'RECAPTURE_TRACK';
export const RECAPTURE_TRACK_SUCCESS = 'RECAPTURE_TRACK_SUCCESS';
export const RECAPTURE_TRACK_FAILED = 'RECAPTURE_TRACK_FAILED';

export const SEND_EMAIL = 'SEND_EMAIL';

export function removeFromCart(index) {
	return {
		type: REMOVE_FROM_CART,
		data: {
			index,
			type: 'remove',
		},
	};
}

export function addToCart(
	product,
	separateLoading = false,
	splitPayment = false
) {
	return {
		type: ADD_TO_CART,
		product,
		separateLoading,
		splitPayment,
	};
}

export function getCartData() {
	return {
		type: GET_CART_DATA,
	};
}

export function sendCouponData(coupon) {
	return {
		type: SEND_COUPON_DATA,
		coupon,
	};
}

export function processCheckout(data) {
	return {
		type: PROCESS_CHECKOUT,
		data,
	};
}

export function addToCartUpsell(products, coupon) {
	return {
		type: ADD_TO_CART_UPSELL,
		products,
		action: 'upsell',
		coupon,
	};
}

export function sendEmailData(email) {
	return {
		type: SEND_EMAIL,
		email,
	};
}

export function recaptureCart(data) {
	return {
		type: RECAPTURE_CART,
		data,
	};
}

export function recaptureTrack(data) {
	return {
		type: RECAPTURE_TRACK,
		data,
	};
}
