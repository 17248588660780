export const GET_CONVERSATION = 'GET_CONVERSATION';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_FAILED = 'GET_CONVERSATION_FAILED';

export const SEND_REPLY = 'SEND_REPLY';
export const SEND_REPLY_SUCCESS = 'SEND_REPLY_SUCCESS';
export const SEND_REPLY_FAILED = 'SEND_REPLY_FAILED';

export function getConversation(id) {
    return {
        id,
        type: GET_CONVERSATION,
    };
}

export function sendReply(id, data) {
    return {
        type: SEND_REPLY,
        id,
        data,
    };
}
