import { defaultApi } from '../utils/axiosApi';
import { all, takeLatest, put } from 'redux-saga/effects';
import {
    GENERATE_INVOICE,
    GENERATE_INVOICE_FAILED,
    GENERATE_INVOICE_SUCCESS,
    GET_INVOICE_DATA,
    GET_INVOICE_DATA_FAILED,
    GET_INVOICE_DATA_SUCCESS,
} from '../actions/InvoiceActions';

export function* getInvoiceData({ params }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/invoice`;
    const { response, error } = yield defaultApi(endpoint, 'GET', params, true);
    if (response) {
        yield put({
            type: GET_INVOICE_DATA_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_INVOICE_DATA_FAILED,
            error,
        });
    }
}

export function* invoiceData() {
    yield takeLatest(GET_INVOICE_DATA, getInvoiceData);
}

export function* generateInvoice({ data }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/invoice`;
    const { response, error } = yield defaultApi(
        endpoint,
        'POST',
        data,
        true,
        false,
        {
            responseType: 'blob',
        }
    );
    if (response) {
        yield put({
            type: GENERATE_INVOICE_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GENERATE_INVOICE_FAILED,
            error,
        });
    }
}

export function* generate() {
    yield takeLatest(GENERATE_INVOICE, generateInvoice);
}

export default function* rootSaga() {
    yield all([invoiceData(), generate()]);
}
