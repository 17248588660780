import { all, put, takeLatest } from 'redux-saga/effects';
import {
	ADD_TO_CART,
	ADD_TO_CART_FAILED,
	ADD_TO_CART_SUCCESS,
	ADD_TO_CART_UPSELL,
	ADD_TO_CART_UPSELL_FAILED,
	ADD_TO_CART_UPSELL_SUCCESS,
	GET_CART_DATA,
	GET_CART_DATA_FAILURE,
	GET_CART_DATA_SUCCESS,
	PROCESS_CHECKOUT,
	PROCESS_CHECKOUT_FAILED,
	PROCESS_CHECKOUT_SUCCESS,
	RECAPTURE_CART,
	RECAPTURE_CART_FAILED,
	RECAPTURE_CART_SUCCESS,
	RECAPTURE_TRACK,
	RECAPTURE_TRACK_FAILED,
	RECAPTURE_TRACK_SUCCESS,
	REMOVE_FROM_CART,
	REMOVE_FROM_CART_FAILURE,
	REMOVE_FROM_CART_SUCCESS,
	SEND_COUPON_DATA,
	SEND_COUPON_DATA_FAILURE,
	SEND_COUPON_DATA_SUCCESS,
	SEND_EMAIL,
} from '../actions/CheckoutActions';
import { defaultApi, recaptureApi } from '../utils/axiosApi';

export function* addProductToCart({ product, splitPayment }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/add-to-cart`;
	const { response, error } = yield defaultApi(
		endpoint,
		'POST',
		{ ...product, splitPayment },
		true
	);
	if (response) {
		yield put({
			type: ADD_TO_CART_SUCCESS,
			response,
			cartAction: product.action,
		});
	}
	if (error) {
		yield put({
			type: ADD_TO_CART_FAILED,
			error,
			cartAction: product.action,
		});
	}
}

export function* addUpsellProductToCart({ products, coupon, action }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/add-to-cart`;
	const { response, error } = yield defaultApi(
		endpoint,
		'POST',
		{ products, coupon, action },
		true
	);
	if (response) {
		yield put({
			type: ADD_TO_CART_UPSELL_SUCCESS,
			response,
		});
	}
	if (error) {
		yield put({
			type: ADD_TO_CART_UPSELL_FAILED,
			error,
		});
	}
}

export function* removeProductFromCart({ data }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/add-to-cart`;

	const { response, error } = yield defaultApi(endpoint, 'POST', data, true);
	if (response) {
		yield put({
			type: REMOVE_FROM_CART_SUCCESS,
			response,
		});
	}
	if (error) {
		yield put({
			type: REMOVE_FROM_CART_FAILURE,
			error,
		});
	}
}

export function* getCartData() {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/cart`;

	const { response, error } = yield defaultApi(
		endpoint,
		'GET',
		undefined,
		true
	);

	if (response) {
		yield put({
			type: GET_CART_DATA_SUCCESS,
			response,
		});
	} else {
		yield put({
			type: GET_CART_DATA_FAILURE,
			error,
		});
	}
}

export function* applyCoupon({ coupon }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/coupon`;

	const { response, error } = yield defaultApi(
		endpoint,
		'POST',
		coupon,
		true
	);
	if (response) {
		yield put({
			type: SEND_COUPON_DATA_SUCCESS,
			response,
		});
	} else {
		yield put({
			type: SEND_COUPON_DATA_FAILURE,
			error,
		});
	}
}

export function* checkoutProcess({ data }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/purchase`;
	const { response, error } = yield defaultApi(endpoint, 'POST', data, true);
	if (response) {
		yield put({
			type: PROCESS_CHECKOUT_SUCCESS,
			response,
		});
	} else {
		yield put({
			type: PROCESS_CHECKOUT_FAILED,
			error,
		});
	}
}

export function* recaptureCartData({ data }) {
	const endpoint = `${process.env.REACT_APP_API_BASE_URL}/recapture/regenerate-cart`;
	const { response, error } = yield defaultApi(endpoint, 'POST', data, true);

	if (response) {
		yield put({
			type: RECAPTURE_CART_SUCCESS,
			response,
		});
	} else {
		yield put({
			type: RECAPTURE_CART_FAILED,
			error,
		});
	}
}

export function* recaptureTrackData({ data }) {
	const { response, error } = yield recaptureApi(
		'https://app.recapture.io/beacon/cart',
		data
	);

	if (response) {
		yield put({
			type: RECAPTURE_TRACK_SUCCESS,
			response,
		});
	} else {
		yield put({
			type: RECAPTURE_TRACK_FAILED,
			error,
		});
	}
}

export function* addToCart() {
	yield takeLatest(ADD_TO_CART, addProductToCart);
}

export function* addToCartUpsell() {
	yield takeLatest(ADD_TO_CART_UPSELL, addUpsellProductToCart);
}

export function* removeFromCart() {
	yield takeLatest(REMOVE_FROM_CART, removeProductFromCart);
}

export function* getCart() {
	yield takeLatest(GET_CART_DATA, getCartData);
}

export function* sendCouponData() {
	yield takeLatest(SEND_COUPON_DATA, applyCoupon);
}

export function* checkout() {
	yield takeLatest(PROCESS_CHECKOUT, checkoutProcess);
}

export function* recapture() {
	yield takeLatest(RECAPTURE_CART, recaptureCartData);
}
export function* recaptureTrack() {
	yield takeLatest(RECAPTURE_TRACK, recaptureTrackData);
}

export default function* rootSaga() {
	yield all([
		addToCart(),
		removeFromCart(),
		getCart(),
		sendCouponData(),
		checkout(),
		addToCartUpsell(),
		recapture(),
		recaptureTrack(),
	]);
}
