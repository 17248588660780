import { all, put, takeLatest } from 'redux-saga/effects';
import {
    GET_PRODUCT_SITE_DATA,
    GET_PRODUCT_SITE_DATA_SUCCESS,
    GET_PRODUCT_SITE_DATA_FAILED,
    MANAGE_SITE,
    MANAGE_SITE_SUCCESS,
    MANAGE_SITE_FAILED,
} from '../actions/ManageSiteActions';
import { defaultApi } from '../utils/axiosApi';

export function* loadProductSiteData({ id }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/history`;

    const { response, error } = yield defaultApi(
        endpoint,
        'GET',
        { payment_id: id },
        true
    );
    if (response) {
        yield put({
            type: GET_PRODUCT_SITE_DATA_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: GET_PRODUCT_SITE_DATA_FAILED,
            error,
        });
    }
}

export function* manageSite({ action, payment_id, url, license_id }) {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/site`;

    const { response, error } = yield defaultApi(
        endpoint,
        'POST',
        { payment_id, type: action, url, license_id },
        true
    );
    if (response) {
        yield put({
            type: MANAGE_SITE_SUCCESS,
            response,
        });
    }
    if (error) {
        yield put({
            type: MANAGE_SITE_FAILED,
            error,
        });
    }
}

export function* loadProductSite() {
    yield takeLatest(GET_PRODUCT_SITE_DATA, loadProductSiteData);
}

export function* loadManageSite() {
    yield takeLatest(MANAGE_SITE, manageSite);
}

export default function* rootSaga() {
    yield all([loadProductSite(), loadManageSite()]);
}
