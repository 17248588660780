import React from 'react';
import { Link } from 'react-router-dom';
import {
    formatDate,
    getExpiration,
    handleCopyToClipBoard,
    isLifetime,
    statusTextColor,
    ucFirst,
} from '../../../utils/helper';
import ProductIcon from '../../../utils/ProductIcon';
import TooltipComponent from '../../../utils/TooltipComponent';
const Product = ({
    id,
    name,
    icon,
    price_name,
    price_id,
    license,
    paymentID,
    history,
    setSiteData,
    setShowModal,
    setDownloadBundleName,
    downloads,
    downloadProduct,
    downloadItem,
    downloading,
    has_upgrades,
    setModalProduct,
}) => {
    const handleClick = e => {
        e.preventDefault();
        setSiteData(paymentID, { ...license });
        history.push(`/user/manage-site/${paymentID}`);
    };

    const handleMultipleDownload = e => {
        e.preventDefault();
        setModalProduct({ name, icon, downloads, payment_id: paymentID });
        setShowModal(true);
        setDownloadBundleName(`${name} ${price_name ? '-' + price_name : ''}`);
    };

    const handleDownload = e => {
        e.preventDefault();
        downloadProduct({
            download_name: downloads.download_name,
            download_id: downloads.download_id,
            price_id: downloads.price_id,
            payment_id: paymentID,
            download_key: downloads.download_key,
            token: downloads.token,
        });
    };
    const isDownloading = () =>
        downloading &&
        downloadItem &&
        downloadItem.download_id === downloads.download_id &&
        downloadItem.payment_id === paymentID &&
        downloadItem.price_id === downloads.price_id;

    const DownloadButton = () => {
        if (Array.isArray(downloads)) {
            return (
                <button
                    className="WpdSmallButton WpdHoverInfo ml-auto"
                    onClick={handleMultipleDownload}
                    style={{ minWidth: '110px' }}
                >
                    <span className="WpdButtonInner">
                        <span className="WpdIcon">
                            <i className="wpd-icon wpd-download-2" />
                        </span>
                        <span className="WpdText">Download</span>
                    </span>
                </button>
            );
        }
        return (
            <button
                className="WpdSmallButton WpdHoverInfo ml-auto"
                onClick={handleDownload}
                style={{ minWidth: '110px' }}
                disabled={isDownloading()}
            >
                <span className="WpdButtonInner">
                    <span className="WpdIcon">
                        <i className="wpd-icon wpd-download-2" />
                    </span>
                    <span className="WpdText">
                        {isDownloading() ? 'Downloading...' : 'Download'}
                    </span>
                </span>
            </button>
        );
    };
    return (
        <tr className={`${isDownloading() ? `is-loading-tr` : ''}`}>
            <td className="WpdTableProductInfoColumn">
                <span className="WpdTableProductInfoWrapper">
                    <span className="WpdTableProductInfo">
                        <span className="WpdTableProductThumbnail">
                            <ProductIcon
                                icon={icon}
                                name={name}
                            />
                        </span>
                        <span className="WpdTableProductDetails">
                            <span className="WpdTableProductTitle">{name}</span>
                            <span className="WpdTableProductSemiTitle">
                                {price_name}
                            </span>
                        </span>
                    </span>
                    <DownloadButton />
                </span>
            </td>
            <td className="WpdProductMetaColumn">
                <span className="WpdProductMetaWrapper">
                    <span className="WpdProductMeta">
                        <span className="WpdProductMetaLabel">Status:</span>
                        <span className="WpdProductMetaInfo">
                            <span
                                className={`WpdLabelButton ${
                                    license && statusTextColor(license.status)
                                }`}
                            >
                                <span className="WpdButtonInner">
                                    <span className="WpdText">
                                        {ucFirst(license && license.status)}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                    {license &&
                        license.status &&
                        license.status.toString() !== 'expired' && (
                            <span className="WpdProductMeta">
                                <span className="WpdProductMetaLabel">
                                    Expires:
                                </span>
                                <span className="WpdProductMetaInfo">
                                    {getExpiration(license)}
                                </span>
                            </span>
                        )}

                    <span className="WpdProductMeta">
                        <span className="WpdProductMetaLabel">
                            Activations:
                        </span>
                        <span className="WpdProductMetaInfo">
                            {license
                                ? `${license.activation_count} / ${license.limit}`
                                : ''}
                        </span>
                    </span>
                </span>
            </td>
            <td className="WpdProductActionColumn">
                <div className="WpdProductActionWrapper">
                    <TooltipComponent>
                        <button
                            className="WpdSmallButton WpdInfoAlert WpdProductCode"
                            onClick={e =>
                                handleCopyToClipBoard(
                                    `copy-license-${license.license_id}`,
                                    'License key copied'
                                )
                            }
                        >
                            <span className="WpdButtonInner">
                                <span
                                    className="WpdText"
                                    id={`copy-license-${license.license_id}`}
                                >
                                    {license && license.license_key}
                                </span>
                                <span className="WpdIcon">
                                    <i className="wpd-icon wpd-copy" />
                                </span>
                            </span>
                        </button>
                    </TooltipComponent>
                    <button
                        className="WpdSmallButton WpdInfoButton WpdManageSite"
                        onClick={handleClick}
                    >
                        <span className="WpdButtonInner">
                            <span className="WpdText">Manage Sites</span>
                        </span>
                    </button>
                    {license &&
                    license.status &&
                    license.status.toString() === 'expired' ? (
                        <Link
                            to={`/checkout?action=renew&license=${
                                license.license_key
                            }&download_id=${id}&price_id=${price_id ?? 1}`}
                            className="WpdSmallButton WpdWarningButton WpdViewUpgrades"
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">Renew License</span>
                            </span>
                        </Link>
                    ) : has_upgrades ? (
                        <Link
                            to={`/user/upgrade-license?license_id=${license.license_id}`}
                            className="WpdSmallButton WpdDangerButton WpdViewUpgrades"
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">View Upgrades</span>
                            </span>
                        </Link>
                    ) : (
                        <button
                            disabled
                            className="WpdSmallButton WpdSecondaryButton WpdViewUpgrades"
                        >
                            <span className="WpdButtonInner">
                                <span className="WpdText">No Upgrades</span>
                            </span>
                        </button>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default Product;
