import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import WpiLogo from '../../Markup/assets/img/WpdLogoWithText.svg';
import Banner from '../../Markup/assets/img/Affiliate_Login.svg';
import { validateFormFields } from '../../utils/helper';
import ErrorMessage from '../../utils/ErrorMessage';
import Spinner from 'react-bootstrap/Spinner';
import Recaptcha from 'react-google-invisible-recaptcha';

const Registration = ({
    loading,
    registerNewUser,
    registrationSuccess,
    registrationErrors,
}) => {
    const [formFields, setFormFields] = useState({
        affwp_user_name: '',
        affwp_user_login: '',
        affwp_user_email: '',
        affwp_payment_email: '',
        affwp_user_url: '',
        affwp_promotion_method: '',
        privacy_policy: false,
    });
    const [errors, setErrors] = useState({
        affwp_user_name: '',
        affwp_user_login: '',
        affwp_user_email: '',
        affwp_payment_email: '',
        affwp_user_url: '',
        affwp_promotion_method: '',
        privacy_policy: '',
    });
    const [showThank, setShowThank] = useState(false);

    let recaptcha;

    useEffect(() => {
        if (registrationSuccess) {
            setFormFields({
                affwp_user_name: '',
                affwp_user_login: '',
                affwp_user_email: '',
                affwp_payment_email: '',
                affwp_user_url: '',
                affwp_promotion_method: '',
                privacy_policy: false,
            });
            setErrors({
                affwp_user_name: '',
                affwp_user_login: '',
                affwp_user_email: '',
                affwp_payment_email: '',
                affwp_user_url: '',
                affwp_promotion_method: '',
                privacy_policy: '',
            });
            setShowThank(true);
        }
    }, [registrationSuccess]);

    useEffect(() => {
        if (registrationErrors) {
            setErrors(registrationErrors);
        }
    }, [registrationErrors]);

    const handleSubmit = e => {
        e.preventDefault();
        const validated = validateFormFields(formFields, getValidationRules());
        if (!validated.valid) {
            setErrors(validated.errors);
            recaptcha.reset();
            return;
        }
        recaptcha.execute().then(r => {
            registerNewUser({
                ...formFields,
                token: r,
            });
        });
    };

    const getValidationRules = () => {
        return {
            affwp_user_name: {
                required: {
                    value: true,
                    message: 'Please insert your name',
                },
            },
            affwp_user_login: {
                required: {
                    value: true,
                    message: 'Please insert your username',
                },
                username: {
                    value: true,
                    message: 'Username must not contain space',
                },
            },
            affwp_user_email: {
                required: {
                    value: true,
                    message: 'Please insert your primary email',
                },
                email: {
                    value: true,
                    message: 'Primary email must be a valid email',
                },
            },
            affwp_payment_email: {
                email: {
                    value: true,
                    message: 'Payment email must be a valid email',
                },
            },
            affwp_user_url: {
                required: {
                    value: true,
                    message: 'Please insert your website url',
                },
                url: {
                    value: true,
                    message: 'Website url must be a valid URL',
                },
            },
            affwp_promotion_method: {
                required: {
                    value: true,
                    message: 'Please tell how you will promote us',
                },
                minLength: {
                    value: 20,
                    message: 'The field must be at least 20 characters long',
                },
            },
            privacy_policy: {
                required: {
                    value: true,
                    message:
                        'Please agree to Terms and conditions & Privacy policy',
                },
            },
        };
    };

    return (
        <div className="WpdPageBody WpdLoginBody">
            <div className="WpdLoginHeader">
                <Link
                    to={'/auth/sign-in'}
                    className="WpdSiteLogo"
                >
                    <img
                        src={WpiLogo}
                        alt=""
                    />
                </Link>
            </div>
            <div className="WpdLoginWrapper">
                <div className="WpdLoginContent">
                    {!showThank ? (
                        <div className="WpdLoginForm">
                            <form onSubmit={handleSubmit}>
                                <h2>Affiliate Registration</h2>
                                <div className="WpdFormGroup mb-3">
                                    <label className="WpdFormLabel">Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            errors.affwp_user_name &&
                                            errors.affwp_user_name.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_user_name: e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors.affwp_user_name}
                                    />
                                </div>
                                <div className="WpdFormGroup mb-3">
                                    <label className="WpdFormLabel">
                                        Username
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            errors.affwp_user_login &&
                                            errors.affwp_user_login.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_user_login:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors.affwp_user_login}
                                    />
                                </div>
                                <div className="WpdFormGroup mb-3">
                                    <label className="WpdFormLabel">
                                        Account Email
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${
                                            errors.affwp_user_email &&
                                            errors.affwp_user_email.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_user_email:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors.affwp_user_email}
                                    />
                                </div>
                                <div className="WpdFormGroup mb-3">
                                    <label className="WpdFormLabel">
                                        Payment Email
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${
                                            errors.affwp_payment_email &&
                                            errors.affwp_payment_email.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_payment_email:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors.affwp_payment_email}
                                    />
                                </div>
                                <div className="WpdFormGroup mb-3">
                                    <label className="WpdFormLabel">
                                        Website url
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            errors.affwp_user_url &&
                                            errors.affwp_user_url.length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_user_url: e.target.value,
                                            })
                                        }
                                    />
                                    <ErrorMessage
                                        error={errors.affwp_user_url}
                                    />
                                </div>
                                <div className="WpdFormGroup mb-3">
                                    <label className="WpdFormLabel">
                                        How will you promote us
                                    </label>
                                    <textarea
                                        className={`form-control ${
                                            errors.affwp_promotion_method &&
                                            errors.affwp_promotion_method
                                                .length &&
                                            'WpdHasError'
                                        }`}
                                        onChange={e =>
                                            setFormFields({
                                                ...formFields,
                                                affwp_promotion_method:
                                                    e.target.value,
                                            })
                                        }
                                    ></textarea>
                                    <ErrorMessage
                                        error={errors.affwp_promotion_method}
                                    />
                                </div>
                                <div className="WpdFormGroup mb-3">
                                    <label className="WpdCheckbox">
                                        <input
                                            type="checkbox"
                                            onChange={e =>
                                                setFormFields({
                                                    ...formFields,
                                                    privacy_policy:
                                                        e.target.checked,
                                                })
                                            }
                                        />
                                        <span className="WpdText">
                                            I agree with the
                                            <a
                                                href={`${process.env.REACT_APP_SITE_URL}/terms-and-conditions`}
                                                target="_blank"
                                            >
                                                Terms & Conditions
                                            </a>
                                            and
                                            <a
                                                href={`${process.env.REACT_APP_SITE_URL}/privacy-policy`}
                                                target="_blank"
                                            >
                                                Privacy Policy
                                            </a>
                                        </span>
                                    </label>
                                    <ErrorMessage
                                        error={errors.privacy_policy}
                                    />
                                </div>
                                <Recaptcha
                                    ref={ref => (recaptcha = ref)}
                                    sitekey={
                                        '6LdM7HsdAAAAAC57NyScJPF9wl2ep4GNMrX3A3Bq'
                                    }
                                />
                                <button
                                    type="submit"
                                    className="WpdButton WpdInfoButton WpdFilled w-100 mt-2"
                                >
                                    <span className="WpdButtonInner">
                                        <span className="WpdText">
                                            {loading ? (
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                    style={{
                                                        height: 26,
                                                        width: 26,
                                                    }}
                                                />
                                            ) : (
                                                'Register'
                                            )}
                                        </span>
                                    </span>
                                </button>
                            </form>
                        </div>
                    ) : (
                        <div className="p-4 bg-white">
                            <p className="WpdText text-success">
                                Thanks for registration, please check your email
                                for details.
                            </p>
                        </div>
                    )}
                </div>
                <div className="WpdLoginImage">
                    <img
                        src={Banner}
                        alt=""
                        className="w-100 img-fluid"
                    />
                </div>
            </div>
        </div>
    );
};

export default Registration;
