import AffiliateContainer from './containers/AffiliateContainer';
import DashboardContainer from './containers/DashboardContainer';
import DownloadContainer from './containers/DownloadContainer';
import ForgetPasswordContainer from './containers/ForgetPasswordContainer.js';
import ManageSiteContainer from './containers/ManageSiteContainer';
import MyProductContainer from './containers/MyProductContainer';
import MyTicketsContainer from './containers/MyTicketsContainer';
import ProfileContainer from './containers/ProfileContainer';
import PurchaseHistoryContainer from './containers/PurchaseHistoryContainer';
import Signin from './containers/SignIn';
import SubscriptionContainer from './containers/SubscriptionContainer';
import SuperAdminDashboardContainer from './containers/SuperAdmin/DashboardContainer';
import UpgradeLicenseContainer from './containers/UpgradeLicenseContainer';
import ManageLicenseContainer from './containers/ManageLicenseContainer';
import GenerateInvoiceContainer from './containers/GenerateInvoiceContainer';
import ConversationsContainer from './containers/ConversationsContainer';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import AffiliateRegistrationContainer from './containers/AffiliateRegistrationContainer';
import ShopManagerContainer from './containers/ShopManager/ShopManagerContainer';

const routes = [
    {
        collapse: false,
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-chart',
        is_admin_route: false,
        views: [],
    },
    {
        collapse: false,
        path: '/download',
        name: 'Downloads',
        component: DownloadContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-download',
        is_admin_route: false,
        views: [],
        customer_only: true,
    },
    {
        collapse: false,
        path: '/my-products',
        name: 'My Products',
        component: MyProductContainer,
        layout: '/user',
        sidebar: false,
        icon: 'wpd-icon wpd-message',
        is_admin_route: false,
        views: [],
        customer_only: true,
    },
    {
        collapse: false,
        path: '/subscription',
        name: 'Subscriptions',
        component: SubscriptionContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-message',
        is_admin_route: false,
        views: [],
        customer_only: true,
    },
    {
        collapse: false,
        path: '/purchase-history',
        name: 'Purchase History',
        component: PurchaseHistoryContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-shopping-bag',
        is_admin_route: false,
        views: [],
        customer_only: true,
    },
    {
        collapse: false,
        path: '/my-tickets',
        name: 'My Tickets',
        component: MyTicketsContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-ticket',
        is_admin_route: false,
        views: [],
    },
    {
        collapse: false,
        path: '/affiliate',
        name: 'Affiliate Area',
        component: AffiliateContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-referral',
        is_admin_route: false,
        views: [],
    },
    {
        collapse: false,
        path: '/profile',
        name: 'Profile',
        component: ProfileContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-user',
        is_admin_route: false,
        views: [],
    },
    {
        collapse: false,
        path: '/manage-site/:id',
        name: 'Manage Sites',
        component: ManageSiteContainer,
        layout: '/user',
        sidebar: false,
        is_admin_route: false,
        isDynamic: true,
        views: [],
        customer_only: true,
    },
    /*{
    collapse: false,
    path: '/payment-success',
    name: 'Payment Success',
    component: PaymentSuccessContainer,
    layout: '/user',
    sidebar: false,
    is_admin_route: false,
    views: [],
  },*/
    {
        collapse: false,
        path: '/upgrade-license',
        name: 'Upgrade License',
        component: UpgradeLicenseContainer,
        layout: '/user',
        sidebar: false,
        is_admin_route: false,
        views: [],
        customer_only: true,
    },
    {
        collapse: false,
        path: '/sign-in',
        name: 'Sign In',
        component: Signin,
        layout: '/auth',
        sidebar: false,
        icon: '',
        views: [],
    },
    {
        collapse: false,
        path: '/forget-pass',
        name: 'Send Forget Password Link',
        component: ForgetPasswordContainer,
        layout: '/auth',
        sidebar: false,
        icon: '',
        views: [],
    },
    {
        collapse: false,
        path: '/reset-password',
        name: 'Reset Password',
        component: ResetPasswordContainer,
        layout: '/auth',
        sidebar: false,
        icon: '',
        views: [],
    },
    /*{
    collapse: false,
    path: '/admin_user/dashboard',
    name: 'Dashboard',
    component: SuperAdminDashboardContainer,
    layout: '/user',
    sidebar: true,
    icon: 'wpd-icon wpd-chart',
    is_admin_route: true,
    views: [],
  },*/
    {
        collapse: false,
        path: '/manage-license/:paymentId',
        name: 'Manage License',
        component: ManageLicenseContainer,
        layout: '/user',
        sidebar: false,
        is_admin_route: false,
        icon: '',
        views: [],
        isDynamic: true,
        customer_only: true,
    },
    {
        collapse: false,
        path: '/generate-invoice/:paymentId',
        name: 'Generate Invoice',
        component: GenerateInvoiceContainer,
        layout: '/user',
        sidebar: false,
        is_admin_route: false,
        icon: '',
        views: [],
        isDynamic: true,
        customer_only: true,
    },
    {
        collapse: false,
        path: '/conversations/:id',
        name: 'Threads',
        component: ConversationsContainer,
        layout: '/user',
        sidebar: false,
        icon: '',
        is_admin_route: false,
        views: [],
        isDynamic: true,
        customer_only: true,
    },
    {
        collapse: false,
        path: '/affiliate-registration',
        name: 'Affiliate registration',
        component: AffiliateRegistrationContainer,
        layout: '/auth',
        sidebar: false,
        icon: 'wpd-icon wpd-referral',
        views: [],
    },
    {
        collapse: false,
        path: '/manage',
        name: 'Shop Manager',
        component: ShopManagerContainer,
        layout: '/user',
        sidebar: true,
        icon: 'wpd-icon wpd-management',
        is_admin_route: false,
        is_manager_route: true,
        views: [],
    },
];

export default routes;
