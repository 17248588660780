import { connect } from 'react-redux';
import Affiliate from '../components/Affiliate';
import { getProfile } from '../actions/ProfileAction';
import {
    getCreatives,
    getPayouts,
    getReferrals,
    getVisits,
    registerNewUser,
} from '../actions/AffiliateActions';

const mapStateToProps = state => {
    return {
        profile: state.profile.profile,
        loading: state.affiliate.loading,
        visits: state.affiliate.visits,
        referrals: state.affiliate.referrals,
        payouts: state.affiliate.payouts,
        registrationSuccess: state.affiliate.registrationSuccess,
        creatives: state.affiliate.creatives,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProfile: () => dispatch(getProfile()),
        getVisits: params => dispatch(getVisits(params)),
        getReferrals: params => dispatch(getReferrals(params)),
        getPayouts: params => dispatch(getPayouts(params)),
        registerNewUser: data => dispatch(registerNewUser(data)),
        getCreatives: affiliate_id => dispatch(getCreatives(affiliate_id)),
    };
};

const AffiliateContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Affiliate);

export default AffiliateContainer;
