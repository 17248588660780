import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import {
    configure2fa,
    downloadBackupCodes,
    getBackupCodes,
    getQrCode,
    verify2fa,
} from '../../actions/SigninActions';
import use2FAViews from './2FA/use2FAViews';

const Modal2FA = ({ loading }) => {
    const { views: formViews } = use2FAViews();

    return (
        <Modal
            className="Wpd2FAModal"
            show={true}
            centered
            size="lg"
            onHide={() => {}}
        >
            <div className={loading ? 'is-loading' : ''}>{formViews}</div>
        </Modal>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.auth.signInLoader,
    };
};
export default connect(mapStateToProps)(Modal2FA);
