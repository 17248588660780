import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../utils/Loader';
import EmptyMessage from '../../utils/EmptyMessage';
import SiteItem from './SiteItem';

const ManageSites = ({
    getProductSiteData,
    siteData,
    match,
    loading,
    manageSite,
}) => {
    const linkHistory = useHistory();
    const goToPreviousPath = () => {
        linkHistory.goBack();
    };

    const [paymentId, setPaymentId] = useState(undefined);
    const [licenseId, setLicenseID] = useState(undefined);
    const [sitesInfo, setSitesInfo] = useState(undefined);
    const [addURL, setAddURL] = useState('http://');
    useEffect(() => {
        setPaymentId(match.params.id);
        if (!siteData) {
            getProductSiteData(match.params.id);
        }
    }, []);

    useEffect(() => {
        siteData && setSitesInfo(siteData[match.params.id]);
        siteData && setLicenseID(siteData[match.params.id]?.license_id);
    }, [siteData]);

    const deactivateSite = url => {
        manageSite({
            action: 'remove',
            payment_id: +paymentId,
            url: `http://${url}`,
            license_id: +licenseId,
        });
    };

    const addSite = e => {
        e.preventDefault();
        manageSite({
            action: 'add',
            payment_id: +paymentId,
            url: addURL,
            license_id: +licenseId,
        });
        setAddURL('http://');
    };

    return (
        <div>
            <div className="WpdSectionTitleWrap">
                <button
                    onClick={goToPreviousPath}
                    className="WpdBackButton"
                >
                    <span className="WpdIcon">
                        <i className="wpd-icon wpd-angle-left"></i>
                    </span>
                </button>
                <h4 className="WpdSectionTitle mr-2">Manage Sites</h4>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <section className="WpdSectionPanel WpdMessageSection WpdKnowledgebaseMessage flex-column WpdSimpleInfoWrapper">
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <h4 className="WpdSectionSemiTitle WpdColorDark mb-2">
                                    Licence Key:{' '}
                                    {sitesInfo && sitesInfo.license_key}
                                </h4>
                                <h4 className="WpdSectionSemiTitle WpdColorSecondary mb-2">
                                    Product Name: {sitesInfo && sitesInfo.name}
                                </h4>
                                <p className="WpdColorSecondary mt-4 mb-2">
                                    Use this form to authorize a new site URL
                                    for this licence. Enter the full site URL
                                </p>
                                <div className="WpdSiteUrlInputWrapper">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={addURL}
                                        onChange={e =>
                                            setAddURL(e.target.value)
                                        }
                                    />
                                    <button
                                        onClick={addSite}
                                        className="WpdButton WpdInfoButton WpdFilled ml-3 text-nowrap"
                                    >
                                        <span className="WpdButtonInner">
                                            <span className="WpdText">
                                                Add Site
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                    {sitesInfo &&
                    sitesInfo.sites &&
                    sitesInfo.sites.length > 0 ? (
                        <section className="WpdMyProductSection WpdSectionGap">
                            <div className="WpdDataTableWrapper">
                                <table className="WpdDataTable">
                                    <thead>
                                        <tr>
                                            <th>Site URL</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sitesInfo.sites.map((site, index) => (
                                            <SiteItem
                                                key={`site-${index}`}
                                                // {...site}
                                                url={site}
                                                deactivateSite={deactivateSite}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    ) : (
                        <EmptyMessage>No active sites found</EmptyMessage>
                    )}
                </>
            )}
        </div>
    );
};

export default ManageSites;
