import { connect } from 'react-redux';
import ManageLicence from '../components/ManageLicense/ManageLicence';
import { getLicenses } from '../actions/ManageLicenseActions';
import { setSiteData } from '../actions/ManageSiteActions';

const mapStateToProps = state => {
    return {
        licenses: state.manageLicense.licenses,
        loading: state.manageLicense.loading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getLicenses: paymentId => dispatch(getLicenses(paymentId)),
        setSiteData: (paymentId, data) =>
            dispatch(setSiteData(paymentId, data)),
    };
};

const ManageLicenseContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageLicence);

export default ManageLicenseContainer;
