import { toast } from 'react-toastify';
import { SIGNED_OUT } from '../actions/index';
import {
    DOWNLOAD_PRODUCT,
    DOWNLOAD_PRODUCT_FAILED,
    DOWNLOAD_PRODUCT_SUCCESS,
    GET_DOWNLOADS,
    GET_DOWNLOADS_FAILED,
    GET_DOWNLOADS_SUCCESS,
} from '../actions/DownloadActions';
import { getErrorMessage } from '../utils/helper';

const initialState = {
    all: undefined,
    loading: false,
    downloading: false,
    downloadItem: null,
};

export default function downloadsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DOWNLOADS:
            return {
                ...state,
                downloading: false,
                loading: true,
            };
        case GET_DOWNLOADS_SUCCESS:
            return {
                ...state,
                all: action.response,
                loading: false,
            };
        case GET_DOWNLOADS_FAILED:
            toast.error(
                action.error?.data?.message
                    ? action.error.data.message
                    : 'Failed to get downloads'
            );
            return {
                ...state,
                all: undefined,
                loading: false,
            };
        case DOWNLOAD_PRODUCT:
            return {
                ...state,
                downloadItem: action.data,
                downloading: true,
            };
        case DOWNLOAD_PRODUCT_SUCCESS:
            return {
                ...state,
                downloading: false,
            };
        case DOWNLOAD_PRODUCT_FAILED:
            action.error.data
                .text()
                .then(r => JSON.parse(r))
                .then(data => toast.error(data.message));
            return {
                ...state,
                downloading: false,
            };
        case SIGNED_OUT:
            return { ...initialState };
        default:
            return state;
    }
}
